export const REPORTED_ISSUE_VALUES: Map<string, string> = new Map([
    ['1','AED is shown at wrong location on map'],
    ['2','Incorrect name or location description'],
    ['3','Business is closed / doesn\'t exist'],
    ['4','AED is missing / not here'],
    ['5','Duplicate of another AED'],
    ['6','Spam, fake, or offensive'],
    ['7','AED image is poor or inappropriate'],
])

export type REPORTED_ISSUE = {
    UserID: string
    UserEmail: string
    Comment: string
    AEDID: string
    ReportedDateTime: string
    ID: string
    Value: string
}

export type REPORTED_ISSUE_RESPONSE = Array<REPORTED_ISSUE>