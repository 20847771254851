import React from 'react'
import styled from '@emotion/styled'

import colors from '@workday/canvas-colors-web'
import Button, {
  ButtonSize,
  ButtonVariant,
} from '@workday/canvas-kit-react-button'
import TextInput from '@workday/canvas-kit-react-text-input'
import FormField from '@workday/canvas-kit-react-form-field'
import { LoadingDots } from '@workday/canvas-kit-react-loading-animation'

import { LOGIN_RESPONSE } from 'api/models/loginResponse'
import { StyledCard } from 'common/styles'
import { login } from 'api/requests'

const Background = styled.div`
  position: fixed;
  display: flex;
  background: ${colors.sourLemon400};
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  width: 400px;
  margin: auto;

  @media (max-width: 600px) {
    width: 320px;
  }
`

const Logo = styled.div`
  text-align: center;
  img {
    height: 70px;
    padding: 20px;
  }

  @media (max-width: 600px) {
    img {
      height: 50px;
    }
  }
`

const StyledFormField = styled(FormField)`
  margin-bottom: 20px;
  min-width: 205px;
`

const StyledLoadingDots = styled(LoadingDots)`
  div {
    width: 10px;
    height: 10px;
    background: ${colors.blackPepper500};
  }
`

const StyledButton = styled(Button)`
  color: ${colors.blackPepper500};

  &:focus {
    color: ${colors.blackPepper500} !important;
  }
`

const attemptLogin = (
  email: string,
  pass: string,
  onSuccess: (data: LOGIN_RESPONSE) => void,
  onNotAdmin: () => void,
  onFailure: () => void,
) => {
  login(email, pass, (data) => {
    if (data.AuthToken === undefined) {
      onFailure()
    } else if (data.AgencyAdmin === '') {
      onNotAdmin()
    } else {
      onSuccess(data)
    }
  })
}

export interface LoginProps {
  onSuccess: (data: LOGIN_RESPONSE) => void
}

const Login = ({ ...props }: LoginProps) => {
  const [email, setEmail] = React.useState<string>()
  const [pass, setPass] = React.useState<string>()

  const [validEmail, setValidEmail] = React.useState<boolean>(true)
  const [validPass, setValidPass] = React.useState<boolean>(true)
  const [inValidLogin, setInValidLogin] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [adminAccessNeeded, setAdminAccessNeeded] = React.useState<boolean>(
    false,
  )

  const validateLogin = (email?: string, pass?: string) => {
    setInValidLogin(false)

    if (email === undefined || email === '') {
      setValidEmail(false)
    } else if (pass === undefined || pass === '') {
      setValidPass(false)
    } else {
      setIsLoading(true)
      attemptLogin(
        email,
        pass,
        (data: LOGIN_RESPONSE) => {
          setIsLoading(false)
          props.onSuccess(data)
          setAdminAccessNeeded(false)
        },
        () => {
          setInValidLogin(true)
          setIsLoading(false)
          setAdminAccessNeeded(true)
        },
        () => {
          setInValidLogin(true)
          setIsLoading(false)
          setAdminAccessNeeded(false)
        },
      )
    }
  }

  return (
    <Background>
      <Container>
        <StyledCard>
          <Logo>
            <img src="/logo.png" alt="PulsePoint Logo" />
          </Logo>
          <StyledFormField
            hintText={validEmail ? undefined : 'Email is required.'}
            error={validEmail ? undefined : FormField.ErrorType.Error}
            grow
          >
            <TextInput
              placeholder="Email"
              onChange={(e) => {
                if (inValidLogin) {
                  setInValidLogin(false)
                }
                setValidEmail(true)
                setEmail(e.target.value)
              }}
            />
          </StyledFormField>
          <StyledFormField
            hintText={
              inValidLogin
                ? adminAccessNeeded
                  ? 'Administrator Access Required.'
                  : 'Email & Password combination is incorrect.'
                : validPass
                ? undefined
                : 'Password is required.'
            }
            error={
              inValidLogin || !validPass ? FormField.ErrorType.Error : undefined
            }
            grow
          >
            <TextInput
              placeholder="Password"
              grow
              type="password"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !isLoading) {
                  validateLogin(email, pass)
                }
              }}
              onChange={(e) => {
                if (inValidLogin) {
                  setInValidLogin(false)
                }

                setValidPass(true)
                setPass(e.target.value)
              }}
            />
          </StyledFormField>
          <StyledButton
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
            onClick={() => {
              if (isLoading) return

              validateLogin(email, pass)
            }}
          >
            {isLoading ? <StyledLoadingDots /> : 'Login'}
          </StyledButton>
        </StyledCard>
      </Container>
    </Background>
  )
}

export default Login
