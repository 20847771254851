import AED from '../models/aed'

import { getAEDIcon } from 'common/utils'

export const centerMap = ({
  mapDef,
  aeds = [],
}: {
  mapDef: { map: any; maps: any }
  aeds?: any[]
}): number => {
  mapDef.map.fitBounds(getMapBounds(mapDef.maps, aeds))
  return mapDef.map.getZoom()
}

const getMapBounds = (maps: any, aeds: AED[]) => {
  const bounds = new maps.LatLngBounds()

  aeds.forEach((aed) => {
    if (aed.latitude === 0 || aed.longitude === 0) return
    bounds.extend(new maps.LatLng(aed.latitude, aed.longitude))
  })
  return bounds
}

export const getMapMarker = (map: any, maps: any, aed: AED) => {
  return new maps.Marker({
    position: { lat: aed.latitude, lng: aed.longitude },
    map,
    title: aed.name,
    icon: getAEDIcon(
      aed.status === 'Approved',
      aed.accessibility === 'Private',
      aed.hasBleedingControlKit || aed.hasEpinephrine || aed.hasNaloxone,
      aed.hasReportedIssues,
      true,
    ),
  })
}
