import sha1 from 'sha1'

const PASS_HASH_P3 = '7a3444b1'

export const API_KEY = `apikey=${process.env.REACT_APP_API_KEY}`

export const client = (
  endpoint: string,
  body?: {},
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT',
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${process.env.REACT_APP_API_BASIC_AUTH}`,
  }
  const config: RequestInit = {
    method: method ? method : body ? 'POST' : 'GET',
    headers: headers,
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  return window
    .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
    .then((response) => response.json())
}

export const hashString = (input: string): string =>
  sha1(
    `${input}${process.env.REACT_APP_PASS_HASH_P1}547201f50ae${PASS_HASH_P3}`,
  )
