import React from 'react'

import { Modal, ModalWidth } from '@workday/canvas-kit-react-modal'
import { ButtonSize, ButtonVariant } from '@workday/canvas-kit-react-button'

import { deleteAED } from 'api/requests'
import { StyledButton } from 'common/styles'
import AED from 'models/aed'
import USER from 'models/user'

export interface AEDDeleteConfirmationModalProps {
  aed: AED
  user: USER
  open: boolean
  onClose: () => void
  onSuccess: (aedID: string) => void
}

const AEDDeleteConfirmationModal = ({
  aed,
  user,
  open,
  onClose,
  onSuccess,
}: AEDDeleteConfirmationModalProps) => {
  return (
    <Modal
      width={ModalWidth.s}
      heading="Delete AED?"
      open={open}
      handleClose={onClose}
    >
      <div>
        <p>Permanently delete this AED record for {aed.name}?</p>
      </div>
      <StyledButton
        variant={ButtonVariant.Primary}
        size={ButtonSize.Medium}
        onClick={() =>
          deleteAED(user, aed, (success) => {
            if (success) {
              onSuccess(aed.ID)
            } else {
              onClose()
            }
          })
        }
      >
        Confirm
      </StyledButton>
      <StyledButton
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Medium}
        onClick={onClose}
      >
        Cancel
      </StyledButton>
    </Modal>
  )
}

export default AEDDeleteConfirmationModal
