import React from 'react'
import styled from '@emotion/styled'

import colors from '@workday/canvas-colors-web'
import { Radio } from '@workday/canvas-kit-react-radio'

import { FiltersProps } from '../filters'

import { PLACE_TYPES_OPTIONS } from 'models/placeType'
import {
  StyledCard,
  CardHeading,
  ClearButton,
  StyledRadioGroup,
} from 'common/styles'

const ListContainer = styled.div<{ showAll: boolean }>`
  overflow: hidden;
  max-height: ${(props) => (props.showAll ? 'unset' : '160px')};
`

const Link = styled.a`
  color: ${colors.blueberry500};
  cursor: pointer;
  margin-top: 5px;
  display: inline-block;
`

const AEDPlaceTypeFilter = ({
  aeds,
  filterQuery,
  updateQuery,
}: FiltersProps) => {
  const [showMore, setShowMore] = React.useState<boolean>(false)

  const filteredPlaceTypes = PLACE_TYPES_OPTIONS.filter((type) => {
    return aeds.filter((aed) => aed.type === type).length > 0
  })

  const sortedAndFilteredPlaceTypes = filteredPlaceTypes.sort((a, b) => {
    const aCount =
      filterQuery.type === undefined || filterQuery.type === a
        ? aeds.filter((aed) => aed.type === a).length
        : 0

    const bCount =
      filterQuery.type === undefined || filterQuery.type === b
        ? aeds.filter((aed) => aed.type === b).length
        : 0

    if (aCount > bCount) return -1
    if (bCount > aCount) return 1

    return 0
  })

  return sortedAndFilteredPlaceTypes.length > 0 ? (
    <StyledCard>
      <CardHeading>Place Type</CardHeading>
      {filterQuery.type && (
        <ClearButton
          onClick={() => {
            delete filterQuery.type
            updateQuery({
              ...filterQuery,
            })
          }}
        >
          Clear
        </ClearButton>
      )}
      <ListContainer showAll={showMore}>
        <StyledRadioGroup
          name="placeType"
          value={filterQuery.type || ''}
          onChange={(value) =>
            updateQuery({
              ...filterQuery,
              type: value as string,
            })
          }
        >
          {sortedAndFilteredPlaceTypes.map((type: string, index: number) => {
            const count =
              filterQuery.type === undefined || filterQuery.type === type
                ? aeds.filter((aed) => aed.type === type).length
                : 0
            return (
              <Radio
                id={`PlaceType-${index}`}
                key={`PlaceType-${index}`}
                value={type}
                label={`${type} (${count})`}
              />
            )
          })}
        </StyledRadioGroup>
      </ListContainer>
      {sortedAndFilteredPlaceTypes.length > 5 ? (
        <Link onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Less...' : 'More...'}
        </Link>
      ) : (
        <></>
      )}
    </StyledCard>
  ) : (
    <></>
  )
}

export default AEDPlaceTypeFilter
