import React from 'react'
import styled from '@emotion/styled'

import { ButtonSize, ButtonVariant } from '@workday/canvas-kit-react-button'
import colors from '@workday/canvas-colors-web'
import { LoadingDots } from '@workday/canvas-kit-react-loading-animation'

import { StyledCard, StyledButton } from 'common/styles'
import AED from 'models/aed'

const ActionButtonStyledCard = styled(StyledCard)`
  position: fixed;
  width: calc(100% - 25px);
  bottom: 0;
  background-color: rgb(100, 100, 100, 0.95);
  border: 1px solid rgb(100, 100, 100);
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.4);
  z-index: 3;

  > div {
    display: flex;
  }
`
const ButtonSpacer = styled.div`
  flex: 1;
`

const StyledLoadingDots = styled(LoadingDots)`
  div {
    width: 10px;
    height: 10px;
    background: ${colors.blackPepper500};
  }
`

const CancelButton = styled(StyledButton)`
  @media (max-width: 600px) {
    display: none;
  }
`

export interface AEDEditActionBarProps {
  aed: AED
  showMarkAsReviewed: boolean
  isUpdating: boolean
  onCancel: () => void
  onMarkAsReviewed: () => void
  onSave: () => void
}

const AEDEditActionBar = ({
  aed,
  showMarkAsReviewed,
  isUpdating,
  onCancel,
  onMarkAsReviewed,
  onSave,
}: AEDEditActionBarProps) => {
  return (
    <ActionButtonStyledCard>
      <CancelButton
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Medium}
        onClick={() => onCancel()}
      >
        Cancel
      </CancelButton>
      <ButtonSpacer />
      {aed.ID !== '' && (
        <>
          <StyledButton
            disabled={!showMarkAsReviewed}
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Medium}
            onClick={() => onMarkAsReviewed()}
          >
            Mark As Reviewed
          </StyledButton>
          <ButtonSpacer />
        </>
      )}
      <StyledButton
        variant={ButtonVariant.Primary}
        size={ButtonSize.Medium}
        onClick={() => onSave()}
      >
        {isUpdating ? <StyledLoadingDots /> : 'Save'}
      </StyledButton>
    </ActionButtonStyledCard>
  )
}

export default AEDEditActionBar
