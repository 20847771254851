import React from 'react'
import styled from '@emotion/styled'

import { Modal, ModalWidth } from '@workday/canvas-kit-react-modal'
import { ButtonSize, ButtonVariant } from '@workday/canvas-kit-react-button'
import Table, { TableRow } from '@workday/canvas-kit-react-table'
import colors from '@workday/canvas-colors-web'
import Checkbox from '@workday/canvas-kit-react-checkbox'
import { SystemIcon } from '@workday/canvas-kit-react-icon'
import { arrowLeftIcon } from '@workday/canvas-system-icons-web'

import AED, { AED_ATTRIBUTE_LABELS } from 'models/aed'
import {
  GooglePlace,
  GooglePlace_Address,
  GOOGLE_PLACE_TYPE_MAP,
} from 'models/placeType'
import { StyledButton } from 'common/styles'

const ModalContainer = styled(Modal)`
  div[role='dialog'] {
    width: 1000px !important;
  }
`
const LookupContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.soap500};
  min-width: 750px;
  margin-top: 10px;
`
const PlacesContainer = styled.div`
  width: 200px;
  padding: 10px;
  min-height: 300px;
  background: ${colors.soap100};
`
const PlaceItemContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 28px);
`
const SpacerColumn = styled.div`
  flex: 1;
`
const NearbyItem = styled.div<{ selected: boolean }>`
  border-top: 1px solid ${colors.soap500};
  padding: 10px;

  background: ${(props) => (props.selected ? `${colors.soap300}` : 'unset')};

  &:hover {
    cursor: pointer;
    background: ${colors.soap300};
  }
`
const FindMorePlacesItem = styled(NearbyItem)`
  color: ${colors.blueberry500};
  font-weight: bold;
`
const FindLessPlacesItem = styled(NearbyItem)`
  font-weight: bold;
  display: flex;
`
const TopBorder = styled.div`
  border-top: 1px solid ${colors.soap500};
  height: 1px;
  width: 100%;
`
const PreviewTable = styled(Table)`
  margin-top: 20px;
`
const TableRowHeader = styled(TableRow)`
  th {
    background: ${colors.soap300};
  }
`
const PlaceData = styled.td<{ showAsNewData: boolean }>`
  color: ${(props) => (props.showAsNewData ? colors.greenApple500 : 'unset')};
`
const DataContainer = styled.div`
  flex: 1;
  padding: 10px;
`
const ButtonBar = styled.div`
  display: flex;
  margin-top: 20px;
`
const ButtonSpacer = styled.div`
  flex: 1;
`

type PlaceLookupCheckboxState = {
  initialized: boolean
  valueName: string
  valueAddress: string
  valueCity: string
  valueState: string
  valuePostal: string
  valueCounty: string
  valueCountry: string
  valuePhone: string
  valueType: string
  disableName: boolean
  disableAddress: boolean
  disableCity: boolean
  disableState: boolean
  disablePostal: boolean
  disableCounty: boolean
  disableCountry: boolean
  disablePhone: boolean
  disableType: boolean
  replaceName: boolean
  replaceAddress: boolean
  replaceCity: boolean
  replaceState: boolean
  replacePostal: boolean
  replaceCounty: boolean
  replaceCountry: boolean
  replacePhone: boolean
  replaceType: boolean
}

const initialCheckboxState = {
  replaceName: undefined,
  replaceAddress: undefined,
  replaceCity: undefined,
  replaceState: undefined,
  replacePostal: undefined,
  replaceCounty: undefined,
  replaceCountry: undefined,
  replacePhone: undefined,
  replaceType: undefined,
}

const initCheckboxStateBasedOnPlaceData = (
  aed: AED,
  place?: GooglePlace,
): PlaceLookupCheckboxState => {
  if (place === undefined || place.address_components === undefined) {
    return {
      initialized: false,
      valueName: '',
      valueAddress: '',
      valueCity: '',
      valueState: '',
      valuePostal: '',
      valueCounty: '',
      valueCountry: '',
      valuePhone: '',
      valueType: 'Unknown',
      disableName: true,
      disableAddress: true,
      disableCity: true,
      disableState: true,
      disablePostal: true,
      disableCounty: true,
      disableCountry: true,
      disablePhone: true,
      disableType: true,
      replaceName: false,
      replaceAddress: false,
      replaceCity: false,
      replaceState: false,
      replacePostal: false,
      replaceCounty: false,
      replaceCountry: false,
      replacePhone: false,
      replaceType: false,
    }
  } else {
    const selectedPlaceName = place.name || ''
    const selectedPlaceAddress = `${
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('street_number'),
      )?.long_name || ''
    } ${
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('route'),
      )?.long_name || ''
    }`
    const selectedPlaceCity =
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('locality'),
      )?.long_name || ''
    const selectedPlaceState =
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('administrative_area_level_1'),
      )?.long_name || ''
    const selectedPlacePostal =
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('postal_code'),
      )?.long_name || ''
    const selectedPlaceCounty =
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('administrative_area_level_2'),
      )?.long_name || ''
    const selectedPlaceCountry =
      place.address_components.find((component: GooglePlace_Address) =>
        component.types.includes('country'),
      )?.long_name || ''
    const selectedPlacePhone = place.formatted_phone_number || ''
    const selectedPlaceType =
      place.types
        .slice(0)
        .reduce(
          (prevValue: string, curValue: string, i: number, arr: string[]) => {
            const type = GOOGLE_PLACE_TYPE_MAP.get(curValue)
            if (type !== undefined) {
              arr.splice(1)
              return type
            } else {
              return ''
            }
          },
          '',
        ) || 'Unknown'

    const shouldDisableName =
      selectedPlaceName === '' || selectedPlaceName === aed.name
    const shouldDisableAddress =
      selectedPlaceAddress === '' || selectedPlaceAddress === aed.address
    const shouldDisableCity =
      selectedPlaceCity === '' || selectedPlaceCity === aed.city
    const shouldDisableState =
      selectedPlaceState === '' || selectedPlaceState === aed.state
    const shouldDisablePostal =
      selectedPlacePostal === '' || selectedPlacePostal === aed.postal
    const shouldDisableCounty =
      selectedPlaceCounty === '' || selectedPlaceCounty === aed.county
    const shouldDisableCountry =
      selectedPlaceCountry === '' || selectedPlaceCountry === aed.country
    const shouldDisablePhone =
      selectedPlacePhone === '' || selectedPlacePhone === aed.businessPhone
    const shouldDisableType =
      selectedPlaceType === '' ||
      selectedPlaceType === 'Unknown' ||
      selectedPlaceType === aed.type

    return {
      initialized: true,
      valueName: selectedPlaceName,
      valueAddress: selectedPlaceAddress,
      valueCity: selectedPlaceCity,
      valueState: selectedPlaceState,
      valuePostal: selectedPlacePostal,
      valueCounty: selectedPlaceCounty,
      valueCountry: selectedPlaceCountry,
      valuePhone: selectedPlacePhone,
      valueType: selectedPlaceType,
      disableName: shouldDisableName,
      disableAddress: shouldDisableAddress,
      disableCity: shouldDisableCity,
      disableState: shouldDisableState,
      disablePostal: shouldDisablePostal,
      disableCounty: shouldDisableCounty,
      disableCountry: shouldDisableCountry,
      disablePhone: shouldDisablePhone,
      disableType: shouldDisableType,
      replaceName: !shouldDisableName,
      replaceAddress: !shouldDisableAddress,
      replaceCity: !shouldDisableCity,
      replaceState: !shouldDisableState,
      replacePostal: !shouldDisablePostal,
      replaceCounty: !shouldDisableCounty,
      replaceCountry: !shouldDisableCountry,
      replacePhone: !shouldDisablePhone,
      replaceType: !shouldDisableType,
    }
  }
}

export interface AEDGooglePlaceLookupModalProps {
  aed: AED
  open: boolean
  googleMap: { maps?: any; map?: any }
  onClose: () => void
  onReplace: (placeInfo: Partial<AED>) => void
}

const AEDGooglePlaceLookupModal = ({
  aed,
  open,
  googleMap,
  onClose,
  onReplace,
}: AEDGooglePlaceLookupModalProps) => {
  const [nearbyPlaces, setNearbyPlaces] = React.useState<GooglePlace[]>()
  const [selectedPlace, setSelectedPlace] = React.useState<GooglePlace>()
  const [lookupWithKeyword, setLookupWithKeyword] = React.useState<boolean>(
    true,
  )
  const [checkboxState, setCheckboxState] = React.useState<{
    replaceName: boolean | undefined
    replaceAddress: boolean | undefined
    replaceCity: boolean | undefined
    replaceState: boolean | undefined
    replacePostal: boolean | undefined
    replaceCounty: boolean | undefined
    replaceCountry: boolean | undefined
    replacePhone: boolean | undefined
    replaceType: boolean | undefined
  }>(initialCheckboxState)

  if (googleMap.map !== undefined && googleMap.maps !== undefined) {
    // Loaded nearby place list
    if (nearbyPlaces === undefined) {
      const placesService = new (googleMap.maps as any).places.PlacesService(
        googleMap.map,
      )

      const nearbySearchQuery = lookupWithKeyword
        ? {
            location: new googleMap.maps.LatLng(aed.latitude, aed.longitude),
            keyword: aed.name,
            radius: 40,
            types: ['establishment'],
          }
        : {
            location: new googleMap.maps.LatLng(aed.latitude, aed.longitude),
            radius: 40,
            types: ['establishment'],
          }

      placesService.nearbySearch(
        nearbySearchQuery,
        (nearbyPlaceList: GooglePlace[], status: any) => {
          if (status === 'OK') {
            setNearbyPlaces(nearbyPlaceList.slice(0, 6))
          }
        },
      )
    } else if (
      // If no Place Type is selected, select first one.
      selectedPlace === undefined &&
      nearbyPlaces !== undefined &&
      nearbyPlaces.length > 0
    ) {
      setSelectedPlace(nearbyPlaces[0])
    } else if (
      // If selected a place and it doesn't have address info, get info.
      selectedPlace !== undefined &&
      selectedPlace.address_components === undefined
    ) {
      const placesService = new (googleMap.maps as any).places.PlacesService(
        googleMap.map,
      )

      placesService.getDetails(
        {
          placeId: selectedPlace.place_id,
          fields: [
            'name',
            'formatted_phone_number',
            'address_components',
            'place_id',
          ],
        },
        (nearbyPlaceWithAddress: GooglePlace, status: any) => {
          if (status === 'OK') {
            setSelectedPlace({
              ...selectedPlace,
              formatted_phone_number:
                nearbyPlaceWithAddress.formatted_phone_number,
              address_components: nearbyPlaceWithAddress.address_components,
            })
          }
        },
      )
    }
  }

  const modalState = initCheckboxStateBasedOnPlaceData(aed, selectedPlace)

  return (
    <ModalContainer
      width={ModalWidth.m}
      heading={''}
      open={open}
      handleClose={() => {
        setCheckboxState(initialCheckboxState)
        onClose()
      }}
    >
      <LookupContainer>
        <PlacesContainer>
          <b>Nearby Places</b>
          <PlaceItemContainer>
            {nearbyPlaces?.map((place: GooglePlace) => (
              <NearbyItem
                key={place.place_id}
                selected={selectedPlace?.place_id === place.place_id}
                onClick={() => {
                  setCheckboxState(initialCheckboxState)
                  setSelectedPlace(place)
                }}
              >
                {place.name}
              </NearbyItem>
            ))}
            {lookupWithKeyword ? (
              <>
                <SpacerColumn />
                <FindMorePlacesItem
                  selected={false}
                  onClick={() => {
                    setLookupWithKeyword(false)
                    setNearbyPlaces(undefined)
                  }}
                >
                  + Find More Places Nearby
                </FindMorePlacesItem>
              </>
            ) : (
              <>
                <SpacerColumn />
                <FindLessPlacesItem
                  selected={false}
                  onClick={() => {
                    setLookupWithKeyword(true)
                    setNearbyPlaces(undefined)
                  }}
                >
                  <SystemIcon icon={arrowLeftIcon} size={18} />
                  {` Back`}
                </FindLessPlacesItem>
              </>
            )}
            <TopBorder />
          </PlaceItemContainer>
        </PlacesContainer>
        <DataContainer>
          <b>Data Preview</b>
          <PreviewTable>
            <thead>
              <TableRowHeader>
                <th></th>
                <th>Current Data</th>
                <th>Place Data</th>
                <th></th>
              </TableRowHeader>
            </thead>
            <tbody>
              <TableRow>
                <td>Name</td>
                <td>{aed.name}</td>
                <PlaceData showAsNewData={aed.name !== modalState.valueName}>
                  {modalState.valueName}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableName}
                    checked={
                      checkboxState.replaceName === undefined
                        ? modalState.replaceName
                        : checkboxState.replaceName
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceName: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('address')}</td>
                <td>{aed.address}</td>
                <PlaceData
                  showAsNewData={aed.address !== modalState.valueAddress}
                >
                  {modalState.valueAddress}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableAddress}
                    checked={
                      checkboxState.replaceAddress === undefined
                        ? modalState.replaceAddress
                        : checkboxState.replaceAddress
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceAddress: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('city')}</td>
                <td>{aed.city}</td>
                <PlaceData showAsNewData={aed.city !== modalState.valueCity}>
                  {modalState.valueCity}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableCity}
                    checked={
                      checkboxState.replaceCity === undefined
                        ? modalState.replaceCity
                        : checkboxState.replaceCity
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceCity: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('state')}</td>
                <td>{aed.state}</td>
                <PlaceData showAsNewData={!modalState.disableState}>
                  {modalState.valueState}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableState}
                    checked={
                      checkboxState.replaceState === undefined
                        ? modalState.replaceState
                        : checkboxState.replaceState
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceState: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('postal')}</td>
                <td>{aed.postal}</td>
                <PlaceData showAsNewData={!modalState.disablePostal}>
                  {modalState.valuePostal}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disablePostal}
                    checked={
                      checkboxState.replacePostal === undefined
                        ? modalState.replacePostal
                        : checkboxState.replacePostal
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replacePostal: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('county')}</td>
                <td>{aed.county}</td>
                <PlaceData showAsNewData={!modalState.disableCounty}>
                  {modalState.valueCounty}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableCounty}
                    checked={
                      checkboxState.replaceCounty === undefined
                        ? modalState.replaceCounty
                        : checkboxState.replaceCounty
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceCounty: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('country')}</td>
                <td>{aed.country}</td>
                <PlaceData showAsNewData={!modalState.disableCountry}>
                  {modalState.valueCountry}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableCountry}
                    checked={
                      checkboxState.replaceCountry === undefined
                        ? modalState.replaceCountry
                        : checkboxState.replaceCountry
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceCountry: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('businessPhone')}</td>
                <td>{aed.businessPhone}</td>
                <PlaceData showAsNewData={!modalState.disablePhone}>
                  {modalState.valuePhone}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disablePhone}
                    checked={
                      checkboxState.replacePhone === undefined
                        ? modalState.replacePhone
                        : checkboxState.replacePhone
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replacePhone: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
              <TableRow>
                <td>{AED_ATTRIBUTE_LABELS.get('type')}</td>
                <td>{aed.type}</td>
                <PlaceData showAsNewData={!modalState.disableType}>
                  {modalState.valueType}
                </PlaceData>
                <td>
                  <Checkbox
                    disabled={modalState.disableType}
                    checked={
                      checkboxState.replaceType === undefined
                        ? modalState.replaceType
                        : checkboxState.replaceType
                    }
                    onChange={(e) =>
                      setCheckboxState({
                        ...checkboxState,
                        replaceType: e.target.checked,
                      })
                    }
                  />
                </td>
              </TableRow>
            </tbody>
          </PreviewTable>
        </DataContainer>
      </LookupContainer>
      <ButtonBar>
        <StyledButton
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Medium}
          onClick={() => {
            setCheckboxState(initialCheckboxState)
            onClose()
          }}
        >
          Cancel
        </StyledButton>
        <ButtonSpacer />
        <StyledButton
          variant={ButtonVariant.Primary}
          size={ButtonSize.Medium}
          onClick={() => {
            const placeInfo: Partial<AED> = {
              googlePlaceID: selectedPlace?.place_id,
            }

            if (
              checkboxState.replaceName === undefined
                ? modalState.replaceName
                : checkboxState.replaceName
            ) {
              placeInfo.name = modalState.valueName
            }
            if (
              checkboxState.replaceAddress === undefined
                ? modalState.replaceAddress
                : checkboxState.replaceAddress
            ) {
              placeInfo.address = modalState.valueAddress
            }
            if (
              checkboxState.replaceCity === undefined
                ? modalState.replaceCity
                : checkboxState.replaceCity
            ) {
              placeInfo.city = modalState.valueCity
            }
            if (
              checkboxState.replaceState === undefined
                ? modalState.replaceState
                : checkboxState.replaceState
            ) {
              placeInfo.state = modalState.valueState
            }
            if (
              checkboxState.replacePostal === undefined
                ? modalState.replacePostal
                : checkboxState.replacePostal
            ) {
              placeInfo.postal = modalState.valuePostal
            }
            if (
              checkboxState.replaceCounty === undefined
                ? modalState.replaceCounty
                : checkboxState.replaceCounty
            ) {
              placeInfo.county = modalState.valueCounty
            }
            if (
              checkboxState.replaceCountry === undefined
                ? modalState.replaceCountry
                : checkboxState.replaceCountry
            ) {
              placeInfo.country = modalState.valueCountry
            }
            if (
              checkboxState.replacePhone === undefined
                ? modalState.replacePhone
                : checkboxState.replacePhone
            ) {
              placeInfo.businessPhone = modalState.valuePhone
            }
            if (
              checkboxState.replaceType === undefined
                ? modalState.replaceType
                : checkboxState.replaceType
            ) {
              placeInfo.type = modalState.valueType
            }
            onReplace(placeInfo)
            setCheckboxState(initialCheckboxState)
          }}
        >
          Replace
        </StyledButton>
      </ButtonBar>
    </ModalContainer>
  )
}

export default AEDGooglePlaceLookupModal
