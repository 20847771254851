import AED from 'models/aed'
import USER from 'models/user'
import AGENCY from 'models/agency'

import { LOGIN_RESPONSE } from 'api/models/loginResponse'
import { AGENCY_RESPONSE } from 'api/models/agencyResponse'
import { AED_DTO, convertDTOToAED, convertAEDToDTO, convertCreateEditDTOToAED } from 'api/models/aedDTO'
import { GENERIC_API_RESPONSE } from 'api/models/logoutResponse'
import { AUTH_CHECK_RESPONSE } from 'api/models/authCheckResponse'
import {
  REPORTED_ISSUE_RESPONSE,
  REPORTED_ISSUE_VALUES,
} from 'api/models/issueReported'

import { API_KEY, client, hashString } from 'api/client'
import AED_ISSUE from 'models/aedIssue'
import { imagePathIsLocal } from 'common/utils'

export const getAgencyIdList = (user: USER): string => {
  if (user.isPulsePointAdmin) {
    if (user.agencyIDs === '') {
      return `${user.admin.addedAgencies.toString()}${user.admin.includeNoAgency ? ',' : ''
        }`
    } else if (user.admin.addedAgencies.length === 0) {
      return `${user.agencyIDs}${user.admin.includeNoAgency ? ',' : ''}`
    } else {
      return `${user.agencyIDs},${user.admin.addedAgencies.toString()}${user.admin.includeNoAgency ? ',' : ''
        }`
    }
  } else {
    return user.agencyIDs
  }
}

export const loadAllAEDs = async (
  user: USER,
  callback: ({
    agencyAEDs,
    loadedAllAgencies,
  }: {
    agencyAEDs: AED[]
    loadedAllAgencies: boolean
  }) => void,
) => {
  const agencies =
    getAgencyIdList(user) === ',' ? [''] : getAgencyIdList(user).split(',')
  const promises = agencies.map((agencyId) => {
    if (agencyId === '') {
      agencyId = ','
    }

    return client(
      `v2/aed?agencyid=${agencyId}&includepending=1&showhidden=1&extended=1&${API_KEY}&userid=${user.userID}&token=${user.token}`,
    ).then((data) => {
      if (data.aeds !== undefined) {
        const agencyAEDs: AED[] = []

          ; (data.aeds as AED_DTO[]).map((aed) => {
            return agencyAEDs.push(convertDTOToAED(aed))
          })

        callback({ agencyAEDs, loadedAllAgencies: false })
        return Promise.resolve(data)
      } else {
        callback({ agencyAEDs: [], loadedAllAgencies: false })
        return Promise.resolve(data)
      }
    })
  })

  await Promise.all(promises)
  callback({ agencyAEDs: [], loadedAllAgencies: true })
}

export const getAEDs = (user: USER, callback: (AEDs: AED[]) => void) => {
  client(
    `v2/aed?agencyid=${getAgencyIdList(
      user,
    )}&includepending=1&showhidden=1&extended=1&${API_KEY}&userid=${user.userID
    }&token=${user.token}`,
  ).then(
    (data) => {
      if (data.aeds !== undefined) {
        const AEDs: AED[] = []

          ; (data.aeds as AED_DTO[]).map((aed) => {
            return AEDs.push(convertDTOToAED(aed))
          })

        callback(AEDs)
      }
    },
    (error) => {
      console.error('oh no, an error happened', error)
    },
  )
}

export const checkValidToken = (
  user: USER,
  callback: (success: boolean) => void,
) => {
  client(
    `aed/auth_check?userid=${user.userID}&token=${user.token}`,
  ).then((response: AUTH_CHECK_RESPONSE) => callback(response.token_valid))
}

export const getAgencyByIds = (
  user: USER,
  callback: (agencies: AGENCY[]) => void,
) => {
  client(
    `v1/agencies?${API_KEY}&agencyid=${getAgencyIdList(user)}&userid=${user.userID
    }&token=${user.token}`,
  ).then((response: AGENCY_RESPONSE) => callback(response.agencies))
}

export const getAllAgencies = (
  user: USER,
  callback: (agencies: AGENCY[]) => void,
) => {
  client(
    `v1/agencies?staged=1&${API_KEY}&userid=${user.userID}&token=${user.token}`,
  ).then((response: AGENCY_RESPONSE) => callback(response.agencies))
}

export const getAgencyFromLatLng = (
  lat: number,
  lng: number,
  callback: (agency: AGENCY) => void,
) => {
  client(
    `v1/agencies?coordinate=${lat},${lng}&list=1&${API_KEY}`,
  ).then((agency: AGENCY) => callback(agency))
}

export const login = (
  email: string,
  pass: string,
  callback: (response: LOGIN_RESPONSE) => void,
) => {
  client(`aed/rest_crowdlogin?${API_KEY}`, {
    UserName: email,
    UserEmail: email,
    Password: hashString(pass).toUpperCase(),
    SignOnService: 'PulsePoint',
    command: 'login',
  }).then((data) => {
    callback(data)
  })
}

export const logout = (user: USER, callback: (success: boolean) => void) => {
  client(`aed/rest_crowdlogin?${API_KEY}`, {
    AuthToken: user.token,
    SignOnService: 'PulsePoint',
    command: 'logout',
  }).then((data: GENERIC_API_RESPONSE) => {
    callback(data.StatusCode === '201')
  })
}

export const getReportedIssues = (
  user: USER,
  aed: AED,
  callback: (reportedIssues: AED_ISSUE[]) => void,
) => {
  client(`aed/aed_issues/${aed.ID}?authtoken=${user.token}&${API_KEY}`).then(
    (response: REPORTED_ISSUE_RESPONSE) => {
      const issues: AED_ISSUE[] = []
      response.forEach((reported_issue) => {
        issues.push({
          issueID: reported_issue.ID,
          issue:
            REPORTED_ISSUE_VALUES.get(reported_issue.Value) ||
            'AED has been reported as invalid.',
          comment: reported_issue.Comment,
          reportedBy: reported_issue.UserEmail,
          reportedDate: reported_issue.ReportedDateTime,
          aedID: reported_issue.AEDID,
        })
      })
      callback(issues)
    },
  )
}

export const clearReportedIssue = (
  user: USER,
  issue: AED_ISSUE,
  callback: (success: boolean) => void,
) => {
  client(
    `aed/aed_issues/${issue.aedID}?authtoken=${user.token}&clearIssue=${issue.issueID}&${API_KEY}`,
  ).then((data: string) => callback(data.indexOf('cleared.') > -1))
}

export const markAEDAsReviewed = (
  user: USER,
  aed: AED,
  callback: (updatedAED: AED) => void,
) => {
  const reviewedAED = convertAEDToDTO(aed)
  reviewedAED.UpdateReviewed = true

  putAED(user, reviewedAED, callback)
}

export const saveAED = (
  user: USER,
  aed: AED,
  callback: (updatedAED: AED) => void,
) => {
  putAED(user, convertAEDToDTO(aed), callback)
}

const putAED = (
  user: USER,
  aed: Partial<AED_DTO>,
  callback: (updatedAED: AED) => void,
) => {
  client(
    `aed/aed_create_modify/${aed.ID}?authtoken=${user.token}&${API_KEY}`,
    aed,
  ).then((updatedAED: AED_DTO) => {
    callback(
      convertDTOToAED({
        ...updatedAED,
        CoordinatorPhoneTextEnabled: updatedAED.CoordinatorPhoneTextEnabled
          ? '1'
          : '0',
        CoordinatorAltPhoneTextEnabled: updatedAED.CoordinatorAltPhoneTextEnabled
          ? '1'
          : '0',
        HasNaloxone: updatedAED.HasNaloxone ? '1' : '0',
        HasEpinephrine: updatedAED.HasEpinephrine ? '1' : '0',
        HasBleedingControlKit: updatedAED.HasBleedingControlKit ? '1' : '0',
        NaloxoneExpDate:
          updatedAED.NaloxoneExpDate === ''
            ? '0000-00-00'
            : updatedAED.NaloxoneExpDate,
        EpinephrineExpDate:
          updatedAED.EpinephrineExpDate === ''
            ? '0000-00-00'
            : updatedAED.EpinephrineExpDate,
        BleedingControlKitExpDate:
          updatedAED.BleedingControlKitExpDate === ''
            ? '0000-00-00'
            : updatedAED.BleedingControlKitExpDate,
        GooglePlaceID: updatedAED.AEDGooglePlaceID || '',
      }),
    )
  })
}

export const deleteAED = (
  user: USER,
  aed: AED,
  callback: (success: boolean) => void,
) => {
  client(
    `aed/aed_create_modify/${aed.ID}?authtoken=${user.token}&${API_KEY}`,
    undefined,
    'DELETE',
  ).then((data: GENERIC_API_RESPONSE) => {
    callback(data.StatusCode === '200')
  })
}

export const getAEDasPPTAdmin = (
  user: USER,
  aedID: String,
  callback: (aed: AED) => void,
) => {
  client(
    `aed/aed_create_modify/${aedID}?authtoken=${user.token}&${API_KEY}`,
    undefined,
    'GET',
  ).then((aed: AED_DTO) => {
    callback(convertCreateEditDTOToAED(aed))
  })
}

export const getAEDImageURL = (user: USER, path: string): string => {
  if (path === undefined || path === '' || imagePathIsLocal(path)) return path

  return `${process.env.REACT_APP_API_URL}/aed/${path}?userid=${user.userID}&token=${user.token}`
}
