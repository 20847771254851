import { Button, ButtonVariant } from '@workday/canvas-kit-react-button'
import { CardHeading, ClearButton, StyledCard } from 'common/styles'
import { Checkbox } from '@workday/canvas-kit-react-checkbox'
import { colors } from '@workday/canvas-colors-web'
import { getAllAgencies } from 'api/requests'
import { Select, SelectOption } from '@workday/canvas-kit-react-select'
import AGENCY from 'models/agency'
import React from 'react'
import styled from '@emotion/styled'
import TextInput from '@workday/canvas-kit-react-text-input'
import uniqBy from 'lodash/uniqBy'

import { FiltersProps } from '../filters'

const StyledTextInput = styled(TextInput)`
  margin: 10px 0px;
  min-width: 205px;
`
const StyledSelect = styled(Select)`
  margin-bottom: 10px;
  min-width: 205px;
  max-width: 205px;
`
const StyledButton = styled(Button)`
  color: ${colors.blackPepper500};

  &:focus {
    color: ${colors.blackPepper500} !important;
  }
`
const Divider = styled.div`
  height: 1px;
  margin: 10px 0px;
  background: ${colors.soap500};
`

const getAgencyName = (agency: AGENCY): string => {
  return agency.short_agencyname !== undefined
    ? `${agency.short_agencyname} [${agency.state}]`
    : `${agency.agencyname} [${agency.state}]`
}

const PPTAdminFilter = ({ user, onPPTAdminAgencyAdd }: FiltersProps) => {
  const [agencies, setAgencies] = React.useState<AGENCY[]>()
  const [selectedAgencyId, setSelectedAgencyId] = React.useState<string>()

  if (agencies === undefined) {
    getAllAgencies(user, (agencies: AGENCY[]) => {
      setAgencies(uniqBy(agencies, 'agencyid'))
    })
    return <></>
  }

  return (
    <StyledCard>
      <CardHeading>PulsePoint Admin</CardHeading>
      {(user.admin.includeNoAgency || user.admin.addedAgencies.length > 0) && (
        <ClearButton
          onClick={() => {
            onPPTAdminAgencyAdd([], false, false)
            setSelectedAgencyId('')
          }}
        >
          Clear
        </ClearButton>
      )}
      <Checkbox
        id={`NoAgency`}
        checked={user.admin.includeNoAgency}
        label={`Include AEDs with No Agency`}
        onChange={(e) => {
          if (e.target.checked) {
            onPPTAdminAgencyAdd(
              user.admin.addedAgencies,
              true,
              user.admin.includePlaceSearch,
            )
          } else {
            onPPTAdminAgencyAdd(
              user.admin.addedAgencies,
              false,
              user.admin.includePlaceSearch,
            )
          }
        }}
      />
      <Checkbox
        id={`NoPlaceID`}
        checked={user.admin.includePlaceSearch}
        label={`Show Place Search Button`}
        onChange={(e) => {
          if (e.target.checked) {
            onPPTAdminAgencyAdd(
              user.admin.addedAgencies,
              user.admin.includeNoAgency,
              true,
            )
          } else {
            onPPTAdminAgencyAdd(
              user.admin.addedAgencies,
              user.admin.includeNoAgency,
              false,
            )
          }
        }}
      />
      <StyledTextInput
        placeholder="AED ID..."
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            window.location.hash = `#edit/${e.currentTarget.value}`
          }
        }}
      />
      <Divider />
      <StyledSelect
        name="colocatedValue"
        value={selectedAgencyId}
        onChange={(e) => setSelectedAgencyId(e.currentTarget.value)}
      >
        {agencies.map((agency: AGENCY, index: number) => {
          return index === 0 ? (
            <React.Fragment key="Initial Option">
              <SelectOption
                value=""
                id="AddAgency"
                key="AddAgency"
                label="Add an Agency"
              />
              <SelectOption
                id={`Agency-${agency.agencyid}`}
                key={`Agency-${agency.agencyid}`}
                value={agency.agencyid}
                label={getAgencyName(agency)}
              />
            </React.Fragment>
          ) : (
            <SelectOption
              id={`Agency-${agency.agencyid}`}
              key={`Agency-${agency.agencyid}`}
              value={agency.agencyid}
              label={getAgencyName(agency)}
            />
          )
        })}
      </StyledSelect>
      <StyledButton
        variant={ButtonVariant.Primary}
        onClick={() => {
          if (selectedAgencyId !== undefined && selectedAgencyId !== '') {
            onPPTAdminAgencyAdd(
              selectedAgencyId === undefined
                ? user.admin.addedAgencies
                : [...user.admin.addedAgencies, selectedAgencyId],
              user.admin.includeNoAgency,
              user.admin.includePlaceSearch,
            )
            setSelectedAgencyId('')
          }
        }}
      >
        Add Agency
      </StyledButton>
    </StyledCard>
  )
}

export default PPTAdminFilter
