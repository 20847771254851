import React from 'react'

import { Radio } from '@workday/canvas-kit-react-radio'

import { FiltersProps } from '../filters'

import { AED_STATUS_OPTIONS } from 'models/aedStatus'
import {
  StyledCard,
  CardHeading,
  ClearButton,
  StyledRadioGroup,
} from 'common/styles'

const AEDStatusFilter = ({ aeds, filterQuery, updateQuery }: FiltersProps) => {
  return (
    <StyledCard>
      <CardHeading>Status</CardHeading>
      {filterQuery.status && (
        <ClearButton
          onClick={() => {
            delete filterQuery.status
            updateQuery({
              ...filterQuery,
            })
          }}
        >
          Clear
        </ClearButton>
      )}
      <StyledRadioGroup
        name="aedStatus"
        value={filterQuery.status || ''}
        onChange={(value) =>
          updateQuery({
            ...filterQuery,
            status: value as string,
          })
        }
      >
        {AED_STATUS_OPTIONS.map((status: string, index: number) => {
          const count =
            filterQuery.status === undefined || filterQuery.status === status
              ? `(${aeds.filter((aed) => aed.status === status).length})`
              : ''
          return (
            <Radio
              id={`AEDStatus-${index}`}
              key={`AEDStatus-${index}`}
              value={status}
              label={`${status} ${count}`}
            />
          )
        })}
      </StyledRadioGroup>
    </StyledCard>
  )
}

export default AEDStatusFilter
