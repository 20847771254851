import React from 'react'
import styled from '@emotion/styled'

import { IconButton } from '@workday/canvas-kit-react-button'
import Table, { TableRow } from '@workday/canvas-kit-react-table'
import { trashIcon } from '@workday/canvas-system-icons-web'
import { Tooltip } from '@workday/canvas-kit-react-tooltip'

import { clearReportedIssue } from 'api/requests'
import { getDisplayDate } from 'common/utils'
import { CardHeading, StyledCard } from 'common/styles'
import AED_ISSUE from 'models/aedIssue'
import USER from 'models/user'

const IssueValue = styled.div`
  font-weight: 700;
  min-height: 20px;
`
const IssueComment = styled.div`
  min-height: 20px;
`

export interface ReportedIssuesProps {
  user: USER
  reportedIssues: AED_ISSUE[]
  onIssueClear: (issue: AED_ISSUE) => void
}

const ReportedIssues = ({
  user,
  reportedIssues,
  onIssueClear,
}: ReportedIssuesProps) => {
  return (
    <StyledCard>
      <CardHeading>Reported Issues</CardHeading>
      <Table>
        <tbody>
          {reportedIssues.map((issue: AED_ISSUE) => {
            return (
              <TableRow key={issue.issueID} state={TableRow.State.Alert}>
                <td>
                  <div>
                    <IssueValue>{issue.issue}</IssueValue>
                    <IssueComment>{issue.comment}</IssueComment>
                  </div>
                </td>
                <td>{issue.reportedBy}</td>
                <td>{getDisplayDate(issue.reportedDate)}</td>
                <td style={{ width: '50px' }}>
                  <Tooltip title="Clear Reported Issue">
                    <IconButton
                      aria-label="Clear Reported Issue"
                      icon={trashIcon}
                      onClick={() =>
                        clearReportedIssue(user, issue, (success) => {
                          if (success) {
                            onIssueClear(issue)
                          }
                        })
                      }
                    />
                  </Tooltip>
                </td>
              </TableRow>
            )
          })}
        </tbody>
      </Table>
    </StyledCard>
  )
}

export default ReportedIssues
