import React from 'react'
import styled from '@emotion/styled'

import MarkerClusterer from '@googlemaps/markerclustererplus'
import GoogleMapReact from 'google-map-react'

import colors from '@workday/canvas-colors-web'

import AED from 'models/aed'
import USER from 'models/user'
import { getAEDImageURL } from 'api/requests'
import { centerMap, getMapMarker } from 'common/mapUtils'

const StyledCardContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
`
const MapContainer = styled.div`
  height: 670px;
  width: 100%;

  @media (max-width: 600px) {
    height: 560px;
  }
`

export interface LocationPinProps {
  lat: string
  lng: string
  aed: AED
}

const markerClick = (
  aed: AED,
  marker: any,
  map: any,
  infowindow: any,
  user: USER,
  onSelectedAED: (aed: AED) => void,
) => {
  //@ts-ignore
  window.editMapAED = function () {
    onSelectedAED(aed)
  }

  const html = `<div style='display:flex;${
    window.innerWidth < 700 ? 'margin-top:15px;' : 'margin-right:40px;'
  }'>
    <div style='width:120px;height:80px;background: no-repeat center center url(${
      aed.hasImage ? getAEDImageURL(user, aed.imagePath) : '/no-aed-image.png'
    });background-size: ${aed.hasImage ? 'cover' : 'contain'};opacity: ${
    aed.hasImage ? 'unset' : '0.5'
  };margin: 0px auto;'></div><div style='padding:5px 10px;'><div style='font-weight: 700;min-height: 20px;'>${
    aed.name
  }</div><div style='overflow: hidden;max-width: 300px;min-height: 20px;'> ${
    aed.description.length > 90
      ? aed.description.substring(0, 87) + '...'
      : aed.description
  }</div><div style='${
    window.innerWidth < 700 ? '' : 'display:flex;'
  }min-height:20px'>${
    aed.hasBleedingControlKit
      ? '<div style="background-color: ' +
        colors.blackPepper100 +
        ';padding: 2px 5px;font-weight:500;text-align: center;border-radius: 10px;margin: 5px 5px 0px 0px;color: ' +
        colors.frenchVanilla100 +
        ';">Bleeding Control Kit</div>'
      : ''
  }${
    aed.hasEpinephrine
      ? '<div style="background-color: ' +
        colors.blackPepper100 +
        ';padding: 2px 5px;font-weight:500;text-align: center;border-radius: 10px;margin: 5px 5px 0px 0px;color: ' +
        colors.frenchVanilla100 +
        ';">Epinephrine</div>'
      : ''
  }${
    aed.hasNaloxone
      ? '<div style="background-color: ' +
        colors.blackPepper100 +
        ';padding: 2px 5px;font-weight:500;text-align: center;border-radius: 10px;margin: 5px 5px 0px 0px;color: ' +
        colors.frenchVanilla100 +
        ';">Naloxone</div>'
      : ''
  }${
    aed.hasImage && !aed.hasApprovedImage
      ? '<div style="background-color: ' +
        colors.plum600 +
        ';padding: 2px 5px;font-weight:500;text-align: center;border-radius: 10px;margin: 5px 5px 0px 0px;color: ' +
        colors.frenchVanilla100 +
        ';">Image Pending</div>'
      : ''
  }</div></div>
  </div><div style='cursor:pointer;position:absolute;right:30px;top:8px'>
  <div aria-label='Edit' onClick='window.editMapAED(${aed.ID})'>
    <span class='css-9qs7ma'>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="wd-icon-edit wd-icon" focusable="false" role="presentation" viewBox="0 0 24 24">
        <g fill-rule="evenodd" class="wd-icon-container"><path d="M4.016 16.14c.002-.271.16-.65.35-.839L16.522 3.146a.5.5 0 0 1 .7-.006l3.548 3.548a.497.497 0 0 1-.006.7L8.61 19.544a1.382 1.382 0 0 1-.839.35l-3.267.016A.502.502 0 0 1 4 19.407l.016-3.267zm11.445-9.105l1.414 1.415 1.414-1.415-1.414-1.414-1.414 1.414z" class="wd-icon-background"></path>
          <path fill-rule="nonzero" d="M14.046 8.45L5.93 16.567 6 17.91l1.344.07 8.118-8.117-1.415-1.414zm1.415-1.415l1.414 1.415 1.414-1.415-1.414-1.414-1.414 1.414zM4.016 16.14c.002-.271.16-.65.35-.839L16.522 3.146a.5.5 0 0 1 .7-.006l3.548 3.548a.497.497 0 0 1-.006.7L8.61 19.544a1.382 1.382 0 0 1-.839.35l-3.267.016A.502.502 0 0 1 4 19.407l.016-3.267z" class="wd-icon-fill"></path>
        </g>
      </svg>
    </span>
  </div></div>`

  infowindow.close()
  infowindow.setContent(html)
  infowindow.open(map, marker)
}

export interface ResultsMapProps {
  user: USER
  aeds: AED[]
  onSelectedAED: (aed: AED) => void
  filteredAgency?: string
}

const ResultsMap = ({
  user,
  aeds,
  filteredAgency,
  onSelectedAED,
}: ResultsMapProps) => {
  const [mapDef, setMapDef] = React.useState<{
    map: any
    maps: any
  }>()
  const markers = React.useRef<any[]>([])
  const markerCluster = React.useRef<any>()
  const zoomLevel = React.useRef<number>(-1)
  const agencyFiltered = React.useRef<string>()

  if (mapDef !== undefined) {
    const infowindow = new mapDef.maps.InfoWindow()

    while (markers.current.length) {
      markers.current.pop().setMap(null)
    }

    if (markerCluster.current !== undefined)
      markerCluster.current.clearMarkers()

    const newSet: any[] = []
    aeds.forEach((aed) => {
      const marker = getMapMarker(mapDef.map, mapDef.maps, aed)
      marker.addListener('click', () => {
        markerClick(aed, marker, mapDef.map, infowindow, user, onSelectedAED)
      })
      newSet.push(marker)
    })
    markers.current = newSet
    markerCluster.current = new MarkerClusterer(mapDef.map, markers.current, {
      styles: [
        MarkerClusterer.withDefaultStyle({
          width: 48,
          height: 48,
          url: '/aed_map_cluster.png',
          textColor: '#333',
          textSize: 14,
        }),
      ],
    })

    if (
      (zoomLevel.current > -1 &&
        mapDef.map.getZoom() === zoomLevel.current &&
        aeds.length > 0) ||
      filteredAgency !== agencyFiltered.current
    ) {
      zoomLevel.current = centerMap({ mapDef, aeds })
      setTimeout(() => {
        markerCluster.current.repaint()
      }, 100)
    }
  }

  return (
    <StyledCardContainer>
      <MapContainer>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCxDiJ-c0tzCGpCH29aVbVubkm6Y5OZeTQ' }}
          defaultCenter={{
            lat: 40.312109,
            lng: -100.122439,
          }}
          defaultZoom={11}
          options={(map) => ({
            streetViewControl: true,
            fullscreenControlOptions: {
              position: map.ControlPosition.TOP_LEFT,
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: map.ControlPosition.TOP_RIGHT,
            },
            styles: [
              {
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: 'on',
                  },
                ],
              },
            ],
          })}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            zoomLevel.current = centerMap({ mapDef: { map, maps }, aeds })
            agencyFiltered.current = filteredAgency
            setMapDef({ map, maps })
          }}
        />
      </MapContainer>
    </StyledCardContainer>
  )
}

export default ResultsMap
