import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import uniq from 'lodash/uniq'

import colors from '@workday/canvas-colors-web'
import { Radio, RadioGroup } from '@workday/canvas-kit-react-radio'

import { FiltersProps } from '../filters'

import { StyledCard, CardHeading, ClearButton } from 'common/styles'

const NO_AGENCY_TEMP_ID = '999999'

const ListContainer = styled.div<{ showAll: boolean }>`
  overflow: hidden;
  max-height: ${(props) => (props.showAll ? 'unset' : '160px')};
`

const StyledRadioGroup = styled(RadioGroup)`
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 190px;
  }
`
const Link = styled.a`
  color: ${colors.blueberry500};
  cursor: pointer;
  margin-top: 5px;
  display: inline-block;
`

const AEDAgencyFilter = ({
  aeds,
  agencies,
  filterQuery,
  updateQuery,
}: FiltersProps) => {
  const [showMore, setShowMore] = React.useState<boolean>(false)

  if (agencies.size <= 1 || aeds.length <= 0) return null

  const sortedNames: string[] = []
  const sortedIDs: string[] = []

  agencies.forEach((name: string, id: string) => {
    if (sortedNames.length < 1) {
      sortedNames.push(name)
      sortedIDs.push(id)
    } else {
      sortedNames.forEach((sortedName: string, index: number) => {
        if (name < sortedName) {
          sortedNames.splice(index, 0, name)
          sortedIDs.splice(index, 0, id)
          return
        } else if (index === sortedNames.length - 1) {
          sortedNames.push(name)
          sortedIDs.push(id)
        }
      })
    }
  })

  const entries: ReactElement[] = []
  uniq(sortedIDs).forEach((id: string) => {
    const count =
      filterQuery.agencyID === undefined || filterQuery.agencyID === id
        ? `(${aeds.filter((aed) => aed.agencyID.indexOf(id) > -1).length})`
        : ''

    entries.push(
      <Radio
        id={`Agency-${id}`}
        key={`Agency-${id}`}
        value={id === '' ? NO_AGENCY_TEMP_ID : id}
        label={`${agencies.get(id)} ${count}`}
      />,
    )
  })

  return (
    <StyledCard>
      <CardHeading>Agency</CardHeading>
      {filterQuery.agencyID && (
        <ClearButton
          onClick={() => {
            delete filterQuery.agencyID
            updateQuery({
              ...filterQuery,
            })
          }}
        >
          Clear
        </ClearButton>
      )}
      <ListContainer showAll={showMore}>
        <StyledRadioGroup
          name="agencies"
          value={
            filterQuery.agencyID === ''
              ? NO_AGENCY_TEMP_ID
              : filterQuery.agencyID || ''
          }
          onChange={(value) => {
            updateQuery({
              ...filterQuery,
              agencyID:
                (value as string) === NO_AGENCY_TEMP_ID
                  ? ''
                  : (value as string),
            })
          }}
        >
          {entries}
        </StyledRadioGroup>
      </ListContainer>
      {entries.length > 5 ? (
        <Link onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Less...' : 'More...'}
        </Link>
      ) : (
        <></>
      )}
    </StyledCard>
  )
}

export default AEDAgencyFilter
