import React from 'react'
import styled from '@emotion/styled'
import GoogleMapReact from 'google-map-react'

import { Layout } from '@workday/canvas-kit-react-layout'
import FormField from '@workday/canvas-kit-react-form-field'
import Select from '@workday/canvas-kit-react-select'
import TextInput from '@workday/canvas-kit-react-text-input'
import Checkbox from '@workday/canvas-kit-react-checkbox'
import { ButtonSize, ButtonVariant } from '@workday/canvas-kit-react-button'

import AED, { AED_Attribute, AED_ATTRIBUTE_LABELS } from 'models/aed'
import { getAEDIcon, getAEDIconWidth } from 'common/utils'
import {
  CardHeading,
  StyledCard,
  StyledButton,
  LayoutContainer,
} from 'common/styles'

import { EditValidationState } from './editAED'
import { getSelectOptions } from './editUtils'
import { PLACE_TYPE } from 'models/placeType'

const MapsContainter = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    height: 400px;
    margin-top: 20px;
  }
`
const GooglePlaceIDContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`
const LookupNearbyPlaceButton = styled(StyledButton)`
  margin: 10px;
`
const EditPin = styled.div<{ aed: AED }>`
  background: ${(props) =>
    `url(${getAEDIcon(
      props.aed.status === 'Approved',
      props.aed.accessibility === 'Private',
      props.aed.hasBleedingControlKit ||
        props.aed.hasEpinephrine ||
        props.aed.hasNaloxone,
      props.aed.hasReportedIssues,
    )})`};
  height: 45px;
  width: ${(props) => `${getAEDIconWidth(props.aed)}`};
  position: absolute;
  background-size: ${(props) => `${getAEDIconWidth(props.aed)}`} 45px;
  z-index: 2;
  top: calc(50% - 45px);
  left: ${(props) =>
    props.aed.hasBleedingControlKit ||
    props.aed.hasEpinephrine ||
    props.aed.hasNaloxone
      ? 'calc(50% - 20px)'
      : 'calc(50% - 17px)'};
`
const Instructions = styled.div`
  position: absolute;
  top: 20px;
  left: calc(50% - 100px);
  width: 200px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 2;
  text-align: center;
  font-weight: 500;

  @media (max-width: 600px) {
    display: none;
  }
`

export interface AEDLocationInformationProps {
  aed: AED
  isPulsePointAdmin: boolean
  validationState: EditValidationState
  resetValidationState: (key: keyof EditValidationState) => void
  onFieldChange: (
    key: AED_Attribute,
    value: string | boolean,
    refreshState?: boolean,
  ) => void
  onMapLoaded: (map: any, maps: any) => void
  onPlaceLookup: () => void
}

const AEDLocationInformation = ({
  aed,
  isPulsePointAdmin,
  validationState,
  resetValidationState,
  onFieldChange,
  onMapLoaded,
  onPlaceLookup,
}: AEDLocationInformationProps) => {
  const [aedValues, setAEDValues] = React.useState<{
    addressValue: string
    cityValue: string
    stateValue: string
    postalValue: string
    countyValue: string
    countryValue: string
    phoneValue: string
    typeValue: PLACE_TYPE
    alwaysAvailable: boolean
    isMobile: boolean
  }>({
    addressValue: aed.address,
    cityValue: aed.city,
    stateValue: aed.state,
    postalValue: aed.postal,
    countyValue: aed.county,
    countryValue: aed.country,
    phoneValue: aed.businessPhone,
    typeValue: aed.type,
    alwaysAvailable: aed.alwaysAvailable,
    isMobile: aed.isMobile,
  })

  if (
    aedValues.addressValue !== aed.address ||
    aedValues.cityValue !== aed.city ||
    aedValues.stateValue !== aed.state ||
    aedValues.postalValue !== aed.postal ||
    aedValues.countyValue !== aed.county ||
    aedValues.countryValue !== aed.country ||
    aedValues.phoneValue !== aed.businessPhone ||
    aedValues.typeValue !== aed.type ||
    aedValues.alwaysAvailable !== aed.alwaysAvailable ||
    aedValues.isMobile !== aed.isMobile
  ) {
    setAEDValues({
      addressValue: aed.address,
      cityValue: aed.city,
      stateValue: aed.state,
      postalValue: aed.postal,
      countyValue: aed.county,
      countryValue: aed.country,
      phoneValue: aed.businessPhone,
      typeValue: aed.type,
      alwaysAvailable: aed.alwaysAvailable,
      isMobile: aed.isMobile,
    })
  }

  return (
    <StyledCard>
      <CardHeading>AED Location</CardHeading>
      <LayoutContainer>
        <Layout.Column>
          <MapsContainter>
            <Instructions>Drag map to position pin</Instructions>
            <EditPin aed={aed} />
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCxDiJ-c0tzCGpCH29aVbVubkm6Y5OZeTQ',
              }}
              defaultCenter={{
                lat: aed.latitude,
                lng: aed.longitude,
              }}
              defaultZoom={18}
              yesIWantToUseGoogleMapApiInternals
              options={(map) => ({
                mapTypeId: map.MapTypeId.HYBRID,
                tilt: 0,
                styles: [
                  {
                    featureType: 'poi.business',
                    elementType: 'labels',
                    stylers: [
                      {
                        visibility: 'on',
                      },
                    ],
                  },
                ],
                rotateControl: false,
                fullscreenControl: false,
                mapTypeControl: true,
                streetViewControl: true,
                mapTypeControlOptions: {
                  position: map.ControlPosition.TOP_RIGHT,
                },
              })}
              onGoogleApiLoaded={({ map, maps }) => {
                onMapLoaded(map, maps)
              }}
            />
          </MapsContainter>
        </Layout.Column>
        <Layout.Column>
          <GooglePlaceIDContainer>
            <LookupNearbyPlaceButton
              variant={ButtonVariant.Primary}
              size={ButtonSize.Small}
              onClick={() => onPlaceLookup()}
            >
              Add Place Details
            </LookupNearbyPlaceButton>
          </GooglePlaceIDContainer>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('address')}
            grow
            className={`EditInput-address`}
          >
            <TextInput
              value={aedValues.addressValue}
              onChange={(e) => {
                onFieldChange('address', e.target.value)
                setAEDValues({ ...aedValues, addressValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('city')}
            grow
            className={`EditInput-city`}
          >
            <TextInput
              value={aedValues.cityValue}
              onChange={(e) => {
                onFieldChange('city', e.target.value)
                setAEDValues({ ...aedValues, cityValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('state')}
            grow
            className={`EditInput-state`}
          >
            <TextInput
              value={aedValues.stateValue}
              onChange={(e) => {
                onFieldChange('state', e.target.value)
                setAEDValues({ ...aedValues, stateValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('postal')}
            grow
            className={`EditInput-postal`}
          >
            <TextInput
              value={aedValues.postalValue}
              onChange={(e) => {
                onFieldChange('postal', e.target.value)
                setAEDValues({ ...aedValues, postalValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('county')}
            grow
            className={`EditInput-county`}
          >
            <TextInput
              value={aedValues.countyValue}
              onChange={(e) => {
                onFieldChange('county', e.target.value)
                setAEDValues({ ...aedValues, countyValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('country')}
            grow
            className={`EditInput-country`}
          >
            <TextInput
              value={aedValues.countryValue}
              onChange={(e) => {
                onFieldChange('country', e.target.value)
                setAEDValues({ ...aedValues, countryValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('businessPhone')}
            grow
            className={`EditInput-businessPhone`}
            error={
              validationState.invalidBusinessPhone
                ? FormField.ErrorType.Error
                : undefined
            }
            hintText={
              validationState.invalidBusinessPhone
                ? 'Phone Number is Invalid. eg. (000) 000-0000'
                : ''
            }
          >
            <TextInput
              value={aedValues.phoneValue}
              type="tel"
              placeholder="(000) 000-0000"
              onChange={(e) => {
                if (validationState.invalidBusinessPhone) {
                  resetValidationState('invalidBusinessPhone')
                }

                onFieldChange('businessPhone', e.target.value)
                setAEDValues({ ...aedValues, phoneValue: e.target.value })
              }}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('type')}
            grow
            className={`EditInput-type`}
          >
            <Select
              value={aedValues.typeValue}
              onChange={(e) => {
                onFieldChange('type', e.target.value)
                setAEDValues({ ...aedValues, typeValue: e.target.value })
              }}
            >
              {getSelectOptions('type')}
            </Select>
          </FormField>
          <FormField className={`EditInput-isMobile`}>
            <Checkbox
              label="AED is not in a fixed location (vehicle, cart, etc.)"
              checked={aedValues.isMobile}
              onChange={(e) => {
                onFieldChange('isMobile', e.target.checked)
                setAEDValues({
                  ...aedValues,
                  isMobile: e.target.checked,
                })
              }}
            />
          </FormField>
          <FormField className={`EditInput-isAlwaysAvailable`}>
            <Checkbox
              label="AED available 24/7"
              checked={aedValues.alwaysAvailable}
              onChange={(e) => {
                onFieldChange('alwaysAvailable', e.target.checked)
                setAEDValues({
                  ...aedValues,
                  alwaysAvailable: e.target.checked,
                })
              }}
            />
          </FormField>
        </Layout.Column>
      </LayoutContainer>
    </StyledCard>
  )
}

export default AEDLocationInformation
