import React from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import Select from '@workday/canvas-kit-react-select'
import { Layout } from '@workday/canvas-kit-react-layout'
import FormField from '@workday/canvas-kit-react-form-field'
import TextInput from '@workday/canvas-kit-react-text-input'
import TextArea from '@workday/canvas-kit-react-text-area'

import AED, { AED_Attribute, AED_ATTRIBUTE_LABELS } from 'models/aed'
import {
  CardHeading,
  StyledCard,
  DatePickerInput,
  LayoutContainer,
} from 'common/styles'
import { convertToDateInputValue, isExpiring } from 'common/utils'

import { TempAEDDatesState } from './editAED'
import { getSelectOptions } from './editUtils'

export interface AEDDetailsInformationProps {
  aed: AED
  aedDatesState: TempAEDDatesState
  onDateChange: (key: AED_Attribute, value: string) => void
  onFieldChange: (key: AED_Attribute, value: string | boolean) => void
}

const AEDDetailsInformation = ({
  aed,
  aedDatesState,
  onDateChange,
  onFieldChange,
}: AEDDetailsInformationProps) => {
  return (
    <StyledCard>
      <CardHeading>AED Details</CardHeading>
      <LayoutContainer>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('installDate')}
            className={'EditInput-installDate'}
          >
            <DatePickerInput
              autoOk
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.installDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'installDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('batteryExpDate')}
            className={`EditInput-batteryExpDate${
              isExpiring(aedDatesState.batteryExpDate) ? ' warning' : ''
            }`}
            error={
              isExpiring(aedDatesState.batteryExpDate)
                ? FormField.ErrorType.Alert
                : undefined
            }
            hintText={
              isExpiring(aedDatesState.batteryExpDate)
                ? 'Expired (or Expiring Soon)'
                : undefined
            }
          >
            <DatePickerInput
              autoOk
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.batteryExpDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'batteryExpDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('electrodeExpDate')}
            className={`EditInput-electrodeExpDate${
              isExpiring(aedDatesState.electrodeExpDate) ? ' warning' : ''
            }`}
            error={
              isExpiring(aedDatesState.electrodeExpDate)
                ? FormField.ErrorType.Alert
                : undefined
            }
            hintText={
              isExpiring(aedDatesState.electrodeExpDate)
                ? 'Expired (or Expiring Soon)'
                : undefined
            }
          >
            <DatePickerInput
              autoOk
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.electrodeExpDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'electrodeExpDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('pedElectrodeExpDate')}
            className={`EditInput-pedElectrodeExpDate${
              isExpiring(aedDatesState.pedElectrodeExpDate) ? ' warning' : ''
            }`}
            error={
              isExpiring(aedDatesState.pedElectrodeExpDate)
                ? FormField.ErrorType.Alert
                : undefined
            }
            hintText={
              isExpiring(aedDatesState.pedElectrodeExpDate)
                ? 'Expired (or Expiring Soon)'
                : undefined
            }
          >
            <DatePickerInput
              autoOk
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.pedElectrodeExpDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'pedElectrodeExpDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('lastInspectedDate')}
            className={'EditInput-lastInspectedDate'}
          >
            <DatePickerInput
              autoOk
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.lastInspectedDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'lastInspectedDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
        </Layout.Column>
      </LayoutContainer>
      <LayoutContainer>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('manufacturer')}
            grow
            className={`EditInput-manufacturer`}
          >
            <Select
              defaultValue={aed.manufacturer}
              onChange={(e) => onFieldChange('manufacturer', e.target.value)}
            >
              {getSelectOptions('manufacturer')}
            </Select>
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('model')}
            grow
            className={`EditInput-model`}
          >
            <TextInput
              defaultValue={aed.model}
              onChange={(e) => onFieldChange('model', e.target.value)}
              grow
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('serialNumber')}
            grow
            className={`EditInput-serialNumber`}
          >
            <TextInput
              defaultValue={aed.serialNumber}
              onChange={(e) => onFieldChange('serialNumber', e.target.value)}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('medicalDirection')}
            grow
            className={`EditInput-medicalDirection`}
          >
            <TextInput
              defaultValue={aed.medicalDirection}
              onChange={(e) =>
                onFieldChange('medicalDirection', e.target.value)
              }
              grow
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('assetID')}
            grow
            className={`EditInput-assetID`}
          >
            <TextInput
              defaultValue={aed.assetID}
              onChange={(e) => onFieldChange('assetID', e.target.value)}
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('accessCode')}
            grow
            className={`EditInput-accessCode`}
          >
            <TextInput
              defaultValue={aed.accessCode}
              onChange={(e) => onFieldChange('accessCode', e.target.value)}
              grow
            />
          </FormField>
        </Layout.Column>
      </LayoutContainer>

      <Layout>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('comment')}
            grow
            className={`EditInput-comment`}
          >
            <TextArea
              defaultValue={aed.comment}
              onChange={(e) => onFieldChange('comment', e.target.value)}
              grow
            />
          </FormField>
        </Layout.Column>
      </Layout>
    </StyledCard>
  )
}

export default AEDDetailsInformation
