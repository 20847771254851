import { arrowLeftIcon, trashIcon } from '@workday/canvas-system-icons-web'
import { createTheme } from '@material-ui/core/styles'
import { formatToDTODateString } from 'common/utils'
import { getReportedIssues, saveAED } from 'api/requests'
import { IconButton, TextButton } from '@workday/canvas-kit-react-button'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { Toast } from '@workday/canvas-kit-react-toast'
import { Tooltip } from '@workday/canvas-kit-react-tooltip'
import AED, { AED_Attribute } from 'models/aed'
import AED_ISSUE from 'models/aedIssue'
import colors from '@workday/canvas-colors-web'
import DateFnsUtils from '@date-io/date-fns'
import React from 'react'
import styled from '@emotion/styled'
import USER from 'models/user'

import { emailIsInvalid, phoneIsInvalid } from './editUtils'
import AEDColocatedItemsInformation from './editAED_5_Colocated'
import AEDDeleteConfirmationModal from './modals/deleteConfirmationModal'
import AEDDetailsInformation from './editAED_4_Details'
import AEDEditActionBar from './editAED_7_ActionBar'
import AEDGooglePlaceLookupModal from './modals/placeLookupModal'
import AEDLargeImageModal from './modals/largeImageModal'
import AEDLocationInformation from './editAED_2_Location'
import AEDRecordInformation from './editAED_1_Record'
import AEDSiteCoordinatorInformation from './editAED_3_SiteCoordinator'
import AEDSubscribersInformation from './editAED_6_Subscribers'
import ReportedIssues from './editAED_0_ReportedIssues'
import ReviewedConfirmationModal from './modals/reviewedConfirmationModal'

import './edit.css'

const PPTTheme = createTheme({
  palette: {
    primary: {
      main: colors.sourLemon400,
      dark: colors.sourLemon600,
    },
  },
})

const Toolbar = styled.div`
  margin: 10px;
  display: flex;
`
const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  padding-bottom: 1px;
`
const ToolbarIconButton = styled(IconButton)`
  .wd-icon-fill {
    fill: ${colors.blackPepper500} !important;
  }
`
const ToolbarTextButton = styled(TextButton)`
  color: ${colors.blackPepper500};

  &:hover {
    color: ${colors.blackPepper500};
  }
  &:focus {
    color: ${colors.blackPepper500} !important;
  }
  .wd-icon-fill {
    fill: ${colors.blackPepper500} !important;
  }
`
const BottomSpacer = styled.div`
  height: 100px;
`
const ButtonSpacer = styled.div`
  flex: 1;
`
const ToastContainer = styled.div`
  position: absolute;
  top: -75px;
  left: calc(50% - 180px);
  z-index: 3;
`

const editingANewAED = (aed: AED): boolean => {
  return aed.ID === ''
}
const VALIDATION_DEFAULTS: EditValidationState = {
  invalidName: false,
  invalidDescription: false,
  invalidBusinessPhone: false,
  invalidSiteCoordinatorPhone: false,
  invalidSiteCoordinatorAltPhone: false,
  invalidSiteCoordinatorEmail: false,
}

//TODO: 1. move google map into separate component

export interface EditValidationState {
  invalidName: boolean
  invalidDescription: boolean
  invalidBusinessPhone: boolean
  invalidSiteCoordinatorPhone: boolean
  invalidSiteCoordinatorAltPhone: boolean
  invalidSiteCoordinatorEmail: boolean
}
// This is needed to store invalid dates
export interface TempAEDDatesState {
  installDate: string
  lastInspectedDate: string
  batteryExpDate: string
  electrodeExpDate: string
  pedElectrodeExpDate: string
  bleedingControlKitExpDate: string
  epinephrineExpDate: string
  naloxoneExpDate: string
}

export interface EditAEDProps {
  user: USER
  aedToEdit: AED
  agencyMap?: Map<string, string>
  onClose: (aed: AED) => void
  onSave: (aed: AED) => void
  onDelete: (aedID: string) => void
  onBack: () => void
}

const EditAED = ({
  user,
  aedToEdit,
  agencyMap = new Map(),
  onClose,
  onSave,
  onDelete,
  onBack,
}: EditAEDProps) => {
  const [googleMap, setGoogleMap] = React.useState<{ map?: any; maps?: any }>(
    {},
  )
  const [reportedIssues, setReportedIssues] = React.useState<AED_ISSUE[]>()
  const [validationState, updateValidationState] = React.useState<
    EditValidationState
  >(VALIDATION_DEFAULTS)
  const [tempDateState, setTempDateState] = React.useState<TempAEDDatesState>({
    installDate: aedToEdit.installDate,
    lastInspectedDate: aedToEdit.lastInspectedDate,
    batteryExpDate: aedToEdit.batteryExpDate,
    electrodeExpDate: aedToEdit.electrodeExpDate,
    pedElectrodeExpDate: aedToEdit.pedElectrodeExpDate,
    bleedingControlKitExpDate: aedToEdit.bleedingControlKitExpDate,
    epinephrineExpDate: aedToEdit.epinephrineExpDate,
    naloxoneExpDate: aedToEdit.naloxoneExpDate,
  })
  const [viewAEDState, setViewAEDState] = React.useState<{
    showLargeImage: boolean
    isUpdating: boolean
    showReviewedConfirmationModal: boolean
    confirmed: boolean
    showToast: boolean
    showDeleteModal: boolean
    showPlaceLookupModal: boolean
  }>({
    showLargeImage: false,
    isUpdating: false,
    showReviewedConfirmationModal: false,
    showToast: false,
    confirmed: false,
    showDeleteModal: false,
    showPlaceLookupModal: false,
  })

  const [aed, updateAED] = React.useState<AED>({
    ...aedToEdit,
  })

  if (aed.hasReportedIssues && reportedIssues === undefined) {
    getReportedIssues(user, aed, (issues) => {
      setReportedIssues(issues)
    })
  }

  const onFieldChange = (
    key: AED_Attribute,
    value: string | boolean,
    refreshState?: boolean,
  ) => {
    ; (aed as any)[key] = value
    if (refreshState) updateAED({ ...aed })
  }
  const onDateChange = (key: AED_Attribute, value: string) => {
    setTempDateState({
      ...tempDateState,
      [key]: value,
    })
  }

  React.useEffect(() => window.scrollTo(0, 0), [])

  return (
    <ThemeProvider theme={PPTTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ContentContainer>
          {viewAEDState.showToast && (
            <ToastContainer>
              <Toast>AED was successfully Mark as Reviewed</Toast>
            </ToastContainer>
          )}
          <Toolbar>
            <ToolbarTextButton
              icon={arrowLeftIcon}
              aria-label="Back"
              onClick={() => (editingANewAED(aed) ? onBack() : onClose(aed))}
            >
              Back
            </ToolbarTextButton>
            <ButtonSpacer />
            <Tooltip title="Delete AED">
              <ToolbarIconButton
                icon={trashIcon}
                aria-label="Delete"
                onClick={() =>
                  setViewAEDState({ ...viewAEDState, showDeleteModal: true })
                }
              ></ToolbarIconButton>
            </Tooltip>
          </Toolbar>
          {reportedIssues !== undefined && reportedIssues.length > 0 && (
            <ReportedIssues
              user={user}
              reportedIssues={reportedIssues}
              onIssueClear={(issue: AED_ISSUE) => {
                const remainingIssues = reportedIssues.filter(
                  (curIssue) => curIssue.issueID !== issue.issueID,
                )
                setReportedIssues(remainingIssues)
              }}
            />
          )}
          <AEDRecordInformation
            aed={aed}
            user={user}
            googleMap={googleMap}
            aedAgency={agencyMap.get(aed.agencyID) || aed.agencyID || 'Unknown'}
            validationState={validationState}
            resetValidationState={(key) => {
              updateValidationState({
                ...validationState,
                [key]: false,
              })
            }}
            showLargeImage={() =>
              setViewAEDState({
                ...viewAEDState,
                showLargeImage: true,
              })
            }
            onApproveImage={() =>
              updateAED({
                ...aed,
                hasApprovedImage: true,
              })
            }
            onRemoveImage={() =>
              updateAED({
                ...aed,
                hasImage: false,
                hasApprovedImage: false,
                imagePath: '',
              })
            }
            onAddImage={(base64: string) =>
              updateAED({
                ...aed,
                hasImage: true,
                hasApprovedImage: true,
                imagePath: base64,
              })
            }
            onFieldChange={onFieldChange}
          />
          <AEDLocationInformation
            aed={aed}
            isPulsePointAdmin={user.isPulsePointAdmin}
            validationState={validationState}
            resetValidationState={(key) => {
              updateValidationState({
                ...validationState,
                [key]: false,
              })
            }}
            onFieldChange={onFieldChange}
            onMapLoaded={(map, maps) =>
              setGoogleMap({
                map,
                maps,
              })
            }
            onPlaceLookup={() =>
              setViewAEDState({
                ...viewAEDState,
                showPlaceLookupModal: true,
              })
            }
          />
          <AEDSiteCoordinatorInformation
            aed={aed}
            validationState={validationState}
            resetValidationState={(key) => {
              updateValidationState({
                ...validationState,
                [key]: false,
              })
            }}
            onFieldChange={onFieldChange}
          />
          <AEDDetailsInformation
            aed={aed}
            aedDatesState={tempDateState}
            onDateChange={onDateChange}
            onFieldChange={onFieldChange}
          />
          <AEDColocatedItemsInformation
            aed={aed}
            aedDatesState={tempDateState}
            onDateChange={onDateChange}
            onFieldChange={onFieldChange}
          />
          {
            aed.activeSubscribers?.length > 0
            && <AEDSubscribersInformation aed={aed} />
          }
          <BottomSpacer />
          <AEDEditActionBar
            aed={aed}
            showMarkAsReviewed={!viewAEDState.confirmed}
            isUpdating={viewAEDState.isUpdating}
            onCancel={() => onClose(aed)}
            onMarkAsReviewed={() =>
              setViewAEDState({
                ...viewAEDState,
                showReviewedConfirmationModal: true,
              })
            }
            onSave={() => {
              if (viewAEDState.isUpdating) return
              aed.latitude = googleMap.map.getCenter().lat()
              aed.longitude = googleMap.map.getCenter().lng()

              aed.installDate = formatToDTODateString(tempDateState.installDate)
              aed.lastInspectedDate = formatToDTODateString(
                tempDateState.lastInspectedDate,
              )
              aed.batteryExpDate = formatToDTODateString(
                tempDateState.batteryExpDate,
              )
              aed.electrodeExpDate = formatToDTODateString(
                tempDateState.electrodeExpDate,
              )
              aed.pedElectrodeExpDate = formatToDTODateString(
                tempDateState.pedElectrodeExpDate,
              )
              aed.epinephrineExpDate = formatToDTODateString(
                tempDateState.epinephrineExpDate,
              )
              aed.naloxoneExpDate = formatToDTODateString(
                tempDateState.naloxoneExpDate,
              )
              aed.bleedingControlKitExpDate = formatToDTODateString(
                tempDateState.bleedingControlKitExpDate,
              )
              let errors = VALIDATION_DEFAULTS

              if (phoneIsInvalid(aed.businessPhone)) {
                errors = {
                  ...errors,
                  invalidBusinessPhone: true,
                }
              }
              if (phoneIsInvalid(aed.coordinatorPhone)) {
                errors = {
                  ...errors,
                  invalidSiteCoordinatorPhone: true,
                }
              }
              if (phoneIsInvalid(aed.coordinatorAltPhone)) {
                errors = {
                  ...errors,
                  invalidSiteCoordinatorAltPhone: true,
                }
              }
              if (emailIsInvalid(aed.siteCoordinatorEmail)) {
                errors = {
                  ...errors,
                  invalidSiteCoordinatorEmail: true,
                }
              }
              if (aed.name.trim() === '') {
                errors = {
                  ...errors,
                  invalidName: true,
                }
              }
              if (aed.description.trim() === '') {
                errors = {
                  ...errors,
                  invalidDescription: true,
                }
              }
              if (Object.values(errors).some((value) => value)) {
                updateValidationState(errors)
              } else {
                if (
                  aedToEdit.status === 'Pending' &&
                  aed.status === 'Approved' &&
                  aed.hasImage
                ) {
                  aed.hasApprovedImage = true
                }
                setViewAEDState({ ...viewAEDState, isUpdating: true })
                saveAED(user, aed, (updatedAED: AED) => {
                  onSave(updatedAED)
                })
              }
            }}
          />
        </ContentContainer>
        <AEDLargeImageModal
          aed={aed}
          user={user}
          open={viewAEDState.showLargeImage}
          onClose={() =>
            setViewAEDState({ ...viewAEDState, showLargeImage: false })
          }
        />
        <ReviewedConfirmationModal
          aed={aed}
          user={user}
          open={viewAEDState.showReviewedConfirmationModal}
          onClose={() =>
            setViewAEDState({
              ...viewAEDState,
              showReviewedConfirmationModal: false,
            })
          }
          onSuccess={(lastReviewed: string) => {
            aed.lastReviewed = lastReviewed
            setViewAEDState({
              ...viewAEDState,
              showReviewedConfirmationModal: false,
              showToast: true,
              confirmed: true,
            })
            setTimeout(
              () =>
                setViewAEDState({
                  ...viewAEDState,
                  showReviewedConfirmationModal: false,
                  showToast: false,
                  confirmed: true,
                }),
              2000,
            )
          }}
        />
        <AEDGooglePlaceLookupModal
          aed={aed}
          open={viewAEDState.showPlaceLookupModal}
          googleMap={googleMap}
          onClose={() =>
            setViewAEDState({ ...viewAEDState, showPlaceLookupModal: false })
          }
          onReplace={(placeInfo) => {
            Object.keys(placeInfo).forEach((key) => {
              onFieldChange(
                key as AED_Attribute,
                placeInfo[key as AED_Attribute] as string,
                true,
              )
            })
            setViewAEDState({ ...viewAEDState, showPlaceLookupModal: false })
          }}
        />
        <AEDDeleteConfirmationModal
          aed={aed}
          user={user}
          open={viewAEDState.showDeleteModal}
          onClose={() =>
            setViewAEDState({ ...viewAEDState, showDeleteModal: false })
          }
          onSuccess={(aedID) => onDelete(aedID)}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default EditAED
