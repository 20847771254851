import React from 'react'

import { Layout } from '@workday/canvas-kit-react-layout'

import AED from 'models/aed'
import {
  CardHeading,
  StyledCard,
  LayoutContainer,
} from 'common/styles'

export interface AEDSubscribersInformationProps {
  aed: AED
}

const AEDSubscribersInformation = ({
  aed,
}: AEDSubscribersInformationProps) => {
  return (
    <StyledCard>
      <CardHeading>Subscribers</CardHeading>
      <LayoutContainer>
        <Layout.Column>
          {
            aed.activeSubscribers.map((subscriber) => {
              return (
                <div>
                  {subscriber}
                </div>
              )
            })
          }
        </Layout.Column>
      </LayoutContainer>
    </StyledCard>
  )
}

export default AEDSubscribersInformation
