import React from 'react'

import { Layout } from '@workday/canvas-kit-react-layout'
import FormField from '@workday/canvas-kit-react-form-field'
import TextInput from '@workday/canvas-kit-react-text-input'
import Checkbox from '@workday/canvas-kit-react-checkbox'

import AED, { AED_Attribute, AED_ATTRIBUTE_LABELS } from 'models/aed'
import { CardHeading, StyledCard, LayoutContainer } from 'common/styles'
import { EditValidationState } from './editAED'

export interface AEDSiteCoordinatorInformationProps {
  aed: AED
  validationState: EditValidationState
  resetValidationState: (key: keyof EditValidationState) => void
  onFieldChange: (
    key: AED_Attribute,
    value: string | boolean,
    refreshState?: boolean,
  ) => void
}

const AEDSiteCoordinatorInformation = ({
  aed,
  validationState,
  resetValidationState,
  onFieldChange,
}: AEDSiteCoordinatorInformationProps) => {
  const [aedValues, setAEDValues] = React.useState<{
    coordinatorPhoneTextEnabled: boolean
    coordinatorAltPhoneTextEnabled: boolean
  }>({
    coordinatorPhoneTextEnabled: aed.coordinatorPhoneTextEnabled,
    coordinatorAltPhoneTextEnabled: aed.coordinatorAltPhoneTextEnabled,
  })

  return (
    <StyledCard>
      <CardHeading>Responsible Party</CardHeading>
      <LayoutContainer>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('siteCoordinatorName')}
            grow
            className={`EditInput-siteCoordinatorName`}
          >
            <TextInput
              defaultValue={aed.siteCoordinatorName}
              onChange={(e) =>
                onFieldChange('siteCoordinatorName', e.target.value)
              }
              grow
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('siteCoordinatorEmail')}
            grow
            className={`EditInput-siteCoordinatorEmail`}
            error={
              validationState.invalidSiteCoordinatorEmail
                ? FormField.ErrorType.Error
                : undefined
            }
            hintText={
              validationState.invalidSiteCoordinatorEmail
                ? 'Email address is Invalid.'
                : ''
            }
          >
            <TextInput
              defaultValue={aed.siteCoordinatorEmail}
              type="email"
              onChange={(e) => {
                if (validationState.invalidSiteCoordinatorEmail) {
                  resetValidationState('invalidSiteCoordinatorEmail')
                }

                onFieldChange('siteCoordinatorEmail', e.target.value)
              }}
              grow
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('coordinatorPhone')}
            grow
            className={`EditInput-coordinatorPhone`}
            error={
              validationState.invalidSiteCoordinatorPhone
                ? FormField.ErrorType.Error
                : undefined
            }
            hintText={
              validationState.invalidSiteCoordinatorPhone
                ? 'Phone Number is Invalid. eg. (000) 000-0000'
                : ''
            }
          >
            <TextInput
              defaultValue={aed.coordinatorPhone}
              type="tel"
              placeholder="(000) 000-0000"
              onChange={(e) => {
                if (validationState.invalidSiteCoordinatorPhone) {
                  resetValidationState('invalidSiteCoordinatorPhone')
                }

                onFieldChange('coordinatorPhone', e.target.value)
              }}
              grow
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('coordinatorAltPhone')}
            grow
            className={`EditInput-coordinatorAltPhone`}
            error={
              validationState.invalidSiteCoordinatorAltPhone
                ? FormField.ErrorType.Error
                : undefined
            }
            hintText={
              validationState.invalidSiteCoordinatorAltPhone
                ? 'Phone Number is Invalid. eg. (000) 000-0000'
                : ''
            }
          >
            <TextInput
              defaultValue={aed.coordinatorAltPhone}
              type="tel"
              placeholder="(000) 000-0000"
              onChange={(e) => {
                if (validationState.invalidSiteCoordinatorAltPhone) {
                  resetValidationState('invalidSiteCoordinatorAltPhone')
                }

                onFieldChange('coordinatorAltPhone', e.target.value)
              }}
              grow
            />
          </FormField>
        </Layout.Column>
      </LayoutContainer>
    </StyledCard >
  )
}

export default AEDSiteCoordinatorInformation
