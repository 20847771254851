import React from 'react'
import styled from '@emotion/styled'
import GoogleMapReact from 'google-map-react'

import { Modal, ModalWidth } from '@workday/canvas-kit-react-modal'
import { arrowLeftIcon } from '@workday/canvas-system-icons-web'
import colors from '@workday/canvas-colors-web'
import Button, {
  TextButton,
  ButtonVariant,
  ButtonSize,
} from '@workday/canvas-kit-react-button'
import { LoadingDots } from '@workday/canvas-kit-react-loading-animation'

import AED, { NEW_AED_TEMPLATE } from 'models/aed'
import USER from 'models/user'
import { CardHeading, StyledCard } from 'common/styles'
import { centerMap, getMapMarker } from 'common/mapUtils'
import AGENCY from 'models/agency'
import {
  GOOGLE_PLACE_TYPE_MAP,
  GooglePlace,
  GooglePlace_Address,
} from 'models/placeType'
import { getAgencyFromLatLng } from 'api/requests'

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  padding-bottom: 1px;
`
const Toolbar = styled.div`
  margin: 10px;
  display: flex;
`
const ToolbarTextButton = styled(TextButton)`
  color: ${colors.blackPepper500};

  &:hover {
    color: ${colors.blackPepper500};
  }
  &:focus {
    color: ${colors.blackPepper500} !important;
  }
  .wd-icon-fill {
    fill: ${colors.blackPepper500} !important;
  }
`
const MapContainer = styled.div`
  position: relative;
  height: calc(100vh - 315px);
  width: 100%;

  @media (max-width: 600px) {
    height: 450px;
    margin-bottom: 50px;
  }
`
const ActionButtonStyledCard = styled(StyledCard)`
  background-color: rgb(100, 100, 100, 0.95);
  border: 1px solid rgb(100, 100, 100);
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.4);

  > div {
    display: flex;
  }

  @media (max-width: 600px) {
    position: fixed;
    width: calc(100% - 25px);
    bottom: 0px;
  }
`
const ButtonSpacer = styled.div`
  flex: 1;
`
const StyledButton = styled(Button)`
  color: ${colors.blackPepper500};
  margin-right: 10px;

  &:focus {
    color: ${colors.blackPepper500} !important;
  }
  &:disabled {
    color: ${colors.blackPepper300} !important;
  }
`
const StyledLoadingDots = styled(LoadingDots)`
  div {
    width: 10px;
    height: 10px;
    background: ${colors.blackPepper500};
  }
`
const SearchContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;

  @media (max-width: 600px) {
    position: relative;
    margin-bottom: 20px;
    top: 0px;
    left: 0px;
  }
`
const SearchField = styled.input`
  padding: 5px 10px;
  width: 300px;
  font-size: 14px;

  @media (max-width: 600px) {
    width: calc(100% - 25px);
  }
`
const NearbyItemContainer = styled.div`
  margin-top: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.95);

  max-height: 300px;
  overflow-y: scroll;
`
const Heading = styled.div`
  color: ${colors.blackPepper500};
  font-size: 16px;
  font-weight: 500;
`
const Link = styled.div`
  margin-top: 10px;
  color: ${colors.blueberry500};
  padding: 10px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background: ${colors.soap300};
  }
`
const NearbyItem = styled.div`
  border-top: 1px solid ${colors.soap500};
  padding: 10px;

  &:hover {
    cursor: pointer;
    background: ${colors.soap300};
  }
`
const SearchPlaceAddressTitle = styled.div`
  margin-top: 5px;
  font-size: 13px;
`
const SearchPlaceAddressSubtitle = styled.div`
  font-size: 13px;
  color: ${colors.blackPepper100};
`
const PinIcon = styled.div`
  background: url('public_aed.png');
  height: 45px;
  width: 34px;
  position: absolute;
  background-size: 34px;
  z-index: 2;
  top: calc(50% - 45px);
  left: calc(50% - 17px); ;
`
const Instructions = styled.div`
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 100px);
  width: 200px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 2;
  text-align: center;
  font-weight: 500;
`
const NextButton = styled(StyledButton) <{ showAsDisabled: boolean }>`
  background: ${(props) =>
    props.showAsDisabled ? colors.sourLemon300 : colors.sourLemon400};
  color: ${(props) =>
    props.showAsDisabled ? colors.blackPepper400 : colors.blackPepper500};

  &:hover {
    background: ${(props) =>
    props.showAsDisabled ? colors.sourLemon300 : colors.sourLemon500};
    color: ${(props) =>
    props.showAsDisabled ? colors.blackPepper400 : colors.blackPepper500};
  }
  &:focus {
    background: ${(props) =>
    props.showAsDisabled
      ? colors.sourLemon300
      : colors.sourLemon600} !important;
    color: ${(props) =>
    props.showAsDisabled
      ? colors.blackPepper400
      : colors.blackPepper500} !important;
  }
  &:active {
    background: ${(props) =>
    props.showAsDisabled ? colors.sourLemon300 : colors.sourLemon500};
    color: ${(props) =>
    props.showAsDisabled ? colors.blackPepper400 : colors.blackPepper500};
  }
`

type AEDPlaceData = {
  placeID: string
  name: string
  address: string
  city: string
  state: string
  county: string
  country: string
  postal: string
  phone: string
  types: string[]
}

export interface AddAEDProps {
  user: USER
  aeds: AED[] | undefined
  onNext: (aed: AED) => void
  onCancel: () => void
}

const AddAED = ({ user, aeds, onNext, onCancel }: AddAEDProps) => {
  const [googleMapDef, setGoogleMapDef] = React.useState<{
    map: any
    maps: any
    searchBox: any
    mapDragged: boolean
  }>()
  const [viewState, setViewState] = React.useState<{
    showPin: boolean
    searchedPlace?: GooglePlace
    places: GooglePlace[]
    aedPlaceWithAddress?: AEDPlaceData
  }>({
    showPin: false,
    places: [],
  })
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  const inputSearchBox = React.useRef(null)

  const setAEDPlaceAndShowPin = (aedPlaceData: AEDPlaceData) => {
    setViewState({
      ...viewState,
      showPin: true,
      places: [],
      aedPlaceWithAddress: aedPlaceData,
    })

    if (googleMapDef) {
      setGoogleMapDef({
        ...googleMapDef,
        mapDragged: false,
      })
    }
  }

  const setPlaceAsAEDPlace = (place: GooglePlace) => {
    if (place) {
      if (place.address_components) {
        const streetNumberComponent =
          place.address_components.find(
            (component: any) => component.types.indexOf('street_number') > -1,
          )?.long_name || ''
        const streetNameComponent =
          place.address_components.find(
            (component: any) => component.types.indexOf('route') > -1,
          )?.long_name || ''

        setAEDPlaceAndShowPin({
          name:
            place.types && place.types.includes('establishment')
              ? place.name
              : '',
          address: streetNumberComponent + ' ' + streetNameComponent,
          city:
            place.address_components.find(
              (component: any) => component.types.indexOf('locality') > -1,
            )?.long_name || '',
          state:
            place.address_components.find(
              (component: any) =>
                component.types.indexOf('administrative_area_level_1') > -1,
            )?.long_name || '',
          county:
            place.address_components.find(
              (component: any) =>
                component.types.indexOf('administrative_area_level_2') > -1,
            )?.long_name || '',
          country:
            place.address_components.find(
              (component: any) => component.types.indexOf('country') > -1,
            )?.long_name || '',
          postal:
            place.address_components.find(
              (component: any) => component.types.indexOf('postal_code') > -1,
            )?.long_name || '',
          placeID: place.place_id,
          phone: place.formatted_phone_number || '',
          types: place.types || [],
        })
      } else {
        setAEDPlaceAndShowPin({
          name: place.types && place.types.includes('establishment')
            ? place.name
            : '',
          address: '',
          city: '',
          state: '',
          county: '',
          country: '',
          postal: '',
          placeID: place.place_id,
          phone: place.formatted_phone_number || '',
          types: place.types || [],
        })
      }
    }
  }

  const getPlaceAddress = (
    place: GooglePlace,
    cb: (placeWithAddress?: GooglePlace) => void,
  ) => {
    const placesService = new (googleMapDef?.maps as any).places.PlacesService(
      googleMapDef?.map,
    )

    placesService.getDetails(
      {
        placeId: place.place_id,
        fields: [
          'name',
          'formatted_phone_number',
          'address_components',
          'place_id',
        ],
      },
      (placeWithDetails: GooglePlace, status: any) => {
        if (status === 'OK') {
          if (placeWithDetails.address_components) {
            cb(placeWithDetails)
          } else {
            cb()
          }
        } else {
          cb()
        }
      },
    )
  }

  React.useEffect(() => {
    // whenever a Google Place has been selected, set location to show pin
    if (viewState.places.length === 1) {
      if (viewState.places[0].address_components === undefined) {
        // if selected and doesn't have an address -> get address
        getPlaceAddress(
          viewState.places[0],
          (selectedPlaceWithAddress?: GooglePlace) => {
            if (selectedPlaceWithAddress) {
              setPlaceAsAEDPlace(selectedPlaceWithAddress)
            }
          },
        )
      } else {
        setPlaceAsAEDPlace(viewState.places[0])
      }
    } else if (viewState.places.length > 1) {
      viewState.places.some((place: GooglePlace) => {
        if (!place.address_components) {
          getPlaceAddress(place, (placeWithAddress?: GooglePlace) => {
            if (placeWithAddress && placeWithAddress.address_components) {
              const places = viewState.places

              places.forEach((placeWithoutAddress) => {
                if (
                  placeWithoutAddress.place_id === placeWithAddress.place_id
                ) {
                  placeWithoutAddress.address_components =
                    placeWithAddress.address_components
                }
              })
              setViewState({
                ...viewState,
                places,
              })
            } else {
              // remove place from places
            }
          })
          return true
        }
        return true
      })
    }

    /*
     * Search
     *
     * 1. if multiple results -> grab first one.
     * 2. if no results -> Do Nothing (reset)
     * 3. if result is an establishment -> set selected
     * 4. if result is not an establishment -> get nearby places
     *
     * 5. if at least one nearby place -> get addresses and add to list
     * 6. if no nearby places -> set searched as selected
     */
    const onPlacesChanged = (e: any) => {
      // 1. if multiple results -> grab first one.
      const place: GooglePlace = googleMapDef?.searchBox.getPlaces()[0]

      if (!place) {
        // 2. if no results -> Do Nothing, reset.
        setViewState({
          showPin: false,
          places: [],
        })
        return
      } else if (
        viewState.searchedPlace &&
        viewState.searchedPlace.place_id !== place.place_id
      ) {
        // new place searched, reset.
        setViewState({
          showPin: false,
          places: [],
          searchedPlace: place,
        })
      }

      // move map to place
      googleMapDef?.map.setZoom(19)
      googleMapDef?.map.setCenter(
        new googleMapDef.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ),
      )

      // 3. if result is an establishment -> set selected
      if (place.types.includes('establishment')) {
        setViewState({
          ...viewState,
          places: [place],
          searchedPlace: place,
        })
      } else {
        // 4. if result is not an establishment -> get nearby places
        const placesService = new (googleMapDef?.maps as any).places.PlacesService(
          googleMapDef?.map,
        )

        placesService.nearbySearch(
          {
            location: place.geometry.location,
            radius: 40,
            types: ['establishment'],
          },
          (nearbyPlaceList: GooglePlace[], status: any) => {
            // 5. if at least one nearby place -> get addresses and add to list
            if (status === 'OK' && nearbyPlaceList.length > 0) {
              setViewState({
                ...viewState,
                showPin: false,
                places: [place, ...nearbyPlaceList.slice(0, 6)],
                searchedPlace: place,
              })
            } else if (status === 'ZERO_RESULTS') {
              // 6. if no nearby places -> set searched as selected
              setViewState({
                ...viewState,
                places: [place],
                searchedPlace: place,
              })
            }
          },
        )
      }
    }

    if (googleMapDef?.searchBox !== undefined) {
      googleMapDef?.searchBox.addListener('places_changed', onPlacesChanged)
    }

    return () => {
      if (googleMapDef?.searchBox !== undefined) {
        googleMapDef?.maps.event.clearInstanceListeners(googleMapDef?.searchBox)
      }
    }
  })

  // show aeds if search has been preformed
  if (viewState.searchedPlace !== undefined && aeds && googleMapDef) {
    aeds.forEach((aed) => {
      getMapMarker(googleMapDef.map, googleMapDef.maps, aed)
    })
  }

  return (
    <ContentContainer>
      <Toolbar>
        <ToolbarTextButton
          icon={arrowLeftIcon}
          aria-label="Cancel"
          onClick={() => onCancel()}
        >
          Back
        </ToolbarTextButton>
      </Toolbar>
      <StyledCard>
        <CardHeading>AED Location</CardHeading>
        <MapContainer>
          {viewState.showPin && (
            <>
              {!googleMapDef?.mapDragged && (
                <Instructions>Drag map to position pin</Instructions>
              )}
              <PinIcon />
            </>
          )}
          <SearchContainer>
            <SearchField
              ref={inputSearchBox}
              placeholder="Search business name or address"
              type="text"
            />
            {viewState.places.length > 1 && (
              <NearbyItemContainer>
                <Heading>Please select location from list below:</Heading>
                <Link
                  onClick={() => {
                    if (viewState.searchedPlace) {
                      googleMapDef?.map.setCenter(
                        new googleMapDef.maps.LatLng(
                          viewState.searchedPlace.geometry.location.lat(),
                          viewState.searchedPlace.geometry.location.lng(),
                        ),
                      )

                      setAEDPlaceAndShowPin({
                        name: '',
                        address: '',
                        city: '',
                        state: '',
                        county: '',
                        country: '',
                        postal: '',
                        placeID: viewState.searchedPlace.place_id,
                        phone: '',
                        types: [],
                      })
                    } else {
                      //should never happen
                    }
                  }}
                >
                  If location is not listed below, click here.
                </Link>
                {viewState.places.map((place: GooglePlace) => (
                  <NearbyItem
                    key={place.place_id}
                    onClick={() => {
                      googleMapDef?.map.setCenter(
                        new googleMapDef.maps.LatLng(
                          place.geometry.location.lat(),
                          place.geometry.location.lng(),
                        ),
                      )

                      setPlaceAsAEDPlace(place)
                    }}
                  >
                    <b>
                      {place.place_id !== viewState.searchedPlace?.place_id &&
                        place.name}
                    </b>
                    {place.address_components && (
                      <>
                        <SearchPlaceAddressTitle>
                          {`${place.address_components.find(
                            (component: GooglePlace_Address) =>
                              component.types.includes('street_number'),
                          )?.long_name || ''
                            } ${place.address_components.find(
                              (component: GooglePlace_Address) =>
                                component.types.includes('route'),
                            )?.long_name || ''
                            }`}
                        </SearchPlaceAddressTitle>
                        <SearchPlaceAddressSubtitle>
                          {`${place.address_components.find(
                            (component: GooglePlace_Address) =>
                              component.types.includes('locality'),
                          )?.long_name || ''
                            }, ${place.address_components.find(
                              (component: GooglePlace_Address) =>
                                component.types.includes(
                                  'administrative_area_level_1',
                                ),
                            )?.short_name || ''
                            } ${place.address_components.find(
                              (component: GooglePlace_Address) =>
                                component.types.includes('postal_code'),
                            )?.long_name || ''
                            }`}
                        </SearchPlaceAddressSubtitle>
                        <SearchPlaceAddressSubtitle>
                          {`${place.address_components.find(
                            (component: GooglePlace_Address) =>
                              component.types.includes(
                                'administrative_area_level_2',
                              ),
                          )?.long_name || ''
                            }, ${place.address_components.find(
                              (component: GooglePlace_Address) =>
                                component.types.includes('country'),
                            )?.short_name
                            }`}
                        </SearchPlaceAddressSubtitle>
                      </>
                    )}
                  </NearbyItem>
                ))}
              </NearbyItemContainer>
            )}
          </SearchContainer>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCxDiJ-c0tzCGpCH29aVbVubkm6Y5OZeTQ',
              libraries: 'places',
            }}
            defaultCenter={{
              lat: 40.312109,
              lng: -100.122439,
            }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              centerMap({ mapDef: { map, maps }, aeds })

              maps.event.addListener(map, 'dragend', () => {
                if (!!!googleMapDef?.mapDragged) {
                  setGoogleMapDef({
                    maps: maps,
                    map: map,
                    mapDragged: true,
                    searchBox:
                      googleMapDef?.searchBox === undefined
                        ? new maps.places.SearchBox(inputSearchBox.current)
                        : googleMapDef?.searchBox,
                  })
                }
              })

              setTimeout(() => {
                setGoogleMapDef({
                  maps: maps,
                  map: map,
                  mapDragged: false,
                  searchBox:
                    googleMapDef?.searchBox === undefined
                      ? new maps.places.SearchBox(inputSearchBox.current)
                      : googleMapDef?.searchBox,
                })
              }, 200)
            }}
            options={(map) => ({
              mapTypeId: map.MapTypeId.HYBRID,
              tilt: 0,
              rotateControl: false,
              fullscreenControl: false,
              mapTypeControl: true,
              mapTypeControlOptions: {
                position: map.ControlPosition.TOP_RIGHT,
              },
              styles: [
                {
                  featureType: 'poi.business',
                  elementType: 'labels',
                  stylers: [
                    {
                      visibility: 'on',
                    },
                  ],
                },
              ],
            })}
          />
        </MapContainer>
      </StyledCard>
      <ActionButtonStyledCard>
        <StyledButton
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Medium}
          onClick={() => onCancel()}
        >
          Cancel
        </StyledButton>
        <ButtonSpacer />
        <NextButton
          showAsDisabled={
            googleMapDef === undefined ||
            !viewState.showPin ||
            !googleMapDef?.mapDragged
          }
          variant={ButtonVariant.Primary}
          size={ButtonSize.Medium}
          onClick={() => {
            if (
              googleMapDef === undefined ||
              !viewState.showPin ||
              !googleMapDef?.mapDragged
            ) {
              setShowInfoModal(true)
            } else {
              const lat = googleMapDef?.map.getCenter().lat()
              const lng = googleMapDef?.map.getCenter().lng()

              setIsUpdating(true)
              getAgencyFromLatLng(lat, lng, (agency: AGENCY) => {
                onNext({
                  ...NEW_AED_TEMPLATE,
                  agencyID: agency === undefined ? '' : agency.agencyid,
                  latitude: lat,
                  longitude: lng,
                  name: viewState.aedPlaceWithAddress?.name || '',
                  address: viewState.aedPlaceWithAddress?.address || '',
                  city: viewState.aedPlaceWithAddress?.city || '',
                  state: viewState.aedPlaceWithAddress?.state || '',
                  county: viewState.aedPlaceWithAddress?.county || '',
                  country: viewState.aedPlaceWithAddress?.country || '',
                  postal: viewState.aedPlaceWithAddress?.postal || '',
                  businessPhone: viewState.aedPlaceWithAddress?.phone || '',
                  googlePlaceID: viewState.aedPlaceWithAddress?.placeID || '',
                  type: (viewState.aedPlaceWithAddress?.types || [])
                    .slice(0)
                    .reduce(
                      (
                        prevValue: string,
                        curValue: string,
                        i: number,
                        arr: string[],
                      ) => {
                        const type = GOOGLE_PLACE_TYPE_MAP.get(curValue)
                        if (type !== undefined) {
                          arr.splice(1)
                          return type
                        } else {
                          return ''
                        }
                      },
                      '',
                    ),
                })
              })
            }
          }}
        >
          {isUpdating ? <StyledLoadingDots /> : 'Next'}
        </NextButton>
      </ActionButtonStyledCard>
      <Modal
        width={ModalWidth.s}
        heading="Action Needed"
        open={showInfoModal}
        handleClose={() => setShowInfoModal(false)}
      >
        <div>
          <p>
            {viewState.searchedPlace === undefined
              ? 'Search for a location in the search box located in the upper left of the map.'
              : !viewState.showPin
                ? 'Please select a location from list below the search box.'
                : 'Please position AED pin to exact location by dragging the map.'}
          </p>
        </div>
      </Modal>
    </ContentContainer>
  )
}

export default AddAED
