import React from 'react'
import styled from '@emotion/styled'

import {
  ButtonSize,
  ButtonVariant,
  IconButton,
} from '@workday/canvas-kit-react-button'
import { Layout } from '@workday/canvas-kit-react-layout'
import colors from '@workday/canvas-colors-web'
import { trashIcon } from '@workday/canvas-system-icons-web'
import FormField from '@workday/canvas-kit-react-form-field'
import Select from '@workday/canvas-kit-react-select'
import TextInput from '@workday/canvas-kit-react-text-input'
import TextArea from '@workday/canvas-kit-react-text-area'
import Checkbox from '@workday/canvas-kit-react-checkbox'
import { Tooltip } from '@workday/canvas-kit-react-tooltip'

import AED, { AED_Attribute, AED_ATTRIBUTE_LABELS } from 'models/aed'
import USER from 'models/user'
import { GooglePlace } from 'models/placeType'
import {
  CardHeading,
  StyledCard,
  StyledButton,
  LayoutContainer,
} from 'common/styles'
import { getDisplayDate } from 'common/utils'
import { getAEDImageURL } from 'api/requests'

import {
  convertBase64,
  getSelectOptions,
  getTodaysHours,
  resizeImage,
} from './editUtils'

import { EditValidationState } from './editAED'

const SpacingColumn = styled(Layout.Column)`
  @media (max-width: 600px) {
    flex: 0;
    padding: 0px;
  }
`
const Image = styled.div<{ imgPath: string }>`
  margin: 0px auto;
  width: 30vw;
  height: calc(30vw * 0.66);
  max-height: 320px;
  max-width: 480px;
  cursor: zoom-in;
  background: no-repeat center center url('${(props) => props.imgPath}');
  background-size: cover;
  border: 1px solid ${colors.blackPepper500};
`
const NoImage = styled.div`
  margin: 0px auto;
  width: 30vw;
  height: calc(30vw * 0.66);
  max-height: 320px;
  max-width: 480px;
  background: no-repeat center center url('/no-aed-image.png');
  background-size: contain;
  opacity: 0.5;
  border: 1px solid ${colors.blackPepper500};
  border-radius: 5px;
  cursor: pointer;
`
const HiddenFileUpload = styled.input`
  visibility: hidden;
`
const ImageButtonBar = styled.div`
  display: flex;
  margin-top: 10px;

  @media (max-width: 600px) {
    display: block;
    text-align: center;
  }
`
const StyledImageButton = styled(StyledButton)`
  min-width: 150px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`
const ButtonSpacer = styled.div`
  flex: 1;
`

export interface AEDRecordInformationProps {
  aed: AED
  user: USER
  aedAgency: string
  showLargeImage: () => void
  onApproveImage: () => void
  onRemoveImage: () => void
  onAddImage: (base64: string) => void
  validationState: EditValidationState
  resetValidationState: (key: keyof EditValidationState) => void
  onFieldChange: (
    key: AED_Attribute,
    value: string | boolean,
    refreshState?: boolean,
  ) => void
  googleMap: any
}

const AEDRecordInformation = ({
  aed,
  user,
  aedAgency,
  showLargeImage,
  onApproveImage,
  onRemoveImage,
  onAddImage,
  validationState,
  resetValidationState,
  onFieldChange,
  googleMap,
}: AEDRecordInformationProps) => {
  const imageUploadRef = React.useRef<HTMLInputElement>(null)

  const [selectedNameValue, setPlaceNameValue] = React.useState<string>(
    aed.name,
  )
  const [placeHourInfo, setPlaceHourInfo] = React.useState<{
    initialized: boolean
    isOpen: boolean
    hours: string
  }>()

  if (aed.name !== selectedNameValue) {
    setPlaceNameValue(aed.name)
  }

  if (
    googleMap.maps !== undefined &&
    aed.googlePlaceID &&
    placeHourInfo === undefined
  ) {
    const placesService = new (googleMap.maps as any).places.PlacesService(
      googleMap.map,
    )

    placesService.getDetails(
      {
        placeId: aed.googlePlaceID,
        fields: ['opening_hours', 'place_id', 'utc_offset_minutes'],
      },
      (nearbyPlaceWithAddress: GooglePlace, status: any) => {
        if (status === 'OK') {
          if (nearbyPlaceWithAddress.opening_hours) {
            const todaysHours = getTodaysHours(
              nearbyPlaceWithAddress.opening_hours.weekday_text,
            )
            if (nearbyPlaceWithAddress.opening_hours.isOpen()) {
              setPlaceHourInfo({
                initialized: true,
                isOpen: true,
                hours: todaysHours,
              })
            } else {
              setPlaceHourInfo({
                initialized: true,
                isOpen: false,
                hours: todaysHours,
              })
            }
          } else {
            setPlaceHourInfo({ initialized: true, isOpen: false, hours: '' })
          }
        } else {
          setPlaceHourInfo({ initialized: true, isOpen: false, hours: '' })
        }
      },
    )
  }

  return (
    <StyledCard>
      <CardHeading>AED Record</CardHeading>
      <LayoutContainer>
        <Layout.Column>
          {aed.hasImage ? (
            <>
              <Image
                onClick={showLargeImage}
                imgPath={getAEDImageURL(user, aed.imagePath)}
              />
              <ImageButtonBar>
                {!aed.hasApprovedImage && (
                  <StyledImageButton
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.Medium}
                    onClick={onApproveImage}
                  >
                    Approve Image
                  </StyledImageButton>
                )}
                <StyledImageButton
                  variant={ButtonVariant.Secondary}
                  size={ButtonSize.Medium}
                  onClick={() =>
                    imageUploadRef.current !== null &&
                    imageUploadRef.current.click()
                  }
                >
                  Replace Image
                </StyledImageButton>
                <ButtonSpacer />
                <Tooltip title="Remove Image">
                  <IconButton
                    aria-label="Remove Image"
                    icon={trashIcon}
                    onClick={onRemoveImage}
                  />
                </Tooltip>
              </ImageButtonBar>
            </>
          ) : (
            <NoImage
              onClick={() =>
                imageUploadRef.current !== null &&
                imageUploadRef.current.click()
              }
            />
          )}
          <HiddenFileUpload
            type="file"
            ref={imageUploadRef}
            onChange={async (e) => {
              const file = e.target.files
              if (file !== null) {
                resizeImage(file[0], 1000, 1000).then(
                  (blob: Blob | null) => {
                    if (blob !== null) {
                      convertBase64(blob).then((base64) => {
                        if (base64 !== null) {
                          onAddImage(base64.toString())
                        }
                      })
                    }
                  },
                  (err) => {
                    console.error('Photo error', err)
                  },
                )
              }
            }}
          />
        </Layout.Column>
        <Layout.Column>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('status')}
            grow
            className={`EditInput-status`}
          >
            <Select
              defaultValue={aed.status}
              onChange={(e) => onFieldChange('status', e.target.value)}
            >
              {getSelectOptions('status')}
            </Select>
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('name')}
            grow
            required
            className={`EditInput-name`}
            error={
              validationState.invalidName
                ? FormField.ErrorType.Error
                : undefined
            }
            hintText={validationState.invalidName ? 'Name is required.' : ''}
          >
            <TextInput
              value={selectedNameValue}
              grow
              onChange={(e) => {
                if (validationState.invalidName) {
                  resetValidationState('invalidName')
                }
                onFieldChange('name', e.target.value)
                setPlaceNameValue(e.target.value)
              }}
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('description')}
            grow
            required
            className={`EditInput-description`}
            error={
              validationState.invalidDescription
                ? FormField.ErrorType.Error
                : undefined
            }
            hintText={
              validationState.invalidDescription
                ? 'Description is required.'
                : ''
            }
          >
            <TextArea
              defaultValue={aed.description}
              onChange={(e) => {
                if (validationState.invalidDescription) {
                  resetValidationState('invalidDescription')
                }
                onFieldChange('description', e.target.value)
              }}
              grow
            />
          </FormField>
          <FormField className={`EditInput-accessibility`}>
            <Checkbox
              label={AED_ATTRIBUTE_LABELS.get('accessibility')}
              checked={aed.accessibility === 'Private'}
              onChange={(e) =>
                onFieldChange(
                  'accessibility',
                  e.target.checked ? 'Private' : 'Public',
                  true,
                )
              }
            />
          </FormField>
          <FormField className={`EditInput-isHidden`}>
            <Checkbox
              label="Do not display this AED"
              checked={aed.isHidden}
              onChange={(e) =>
                onFieldChange('isHidden', e.target.checked, true)
              }
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <LayoutContainer>
            <SpacingColumn />
            <Layout.Column>
              <FormField
                label={AED_ATTRIBUTE_LABELS.get('agencyID')}
                grow
                className={`EditInput-agency`}
              >
                {user.isPulsePointAdmin ? (
                  <TextInput
                    defaultValue={aed.agencyID}
                    onChange={(e) => onFieldChange('agencyID', e.target.value)}
                    grow
                  />
                ) : (
                  `${aedAgency}`
                )}
              </FormField>
              {aed.ID !== '' && (
                <>
                  <FormField
                    label={AED_ATTRIBUTE_LABELS.get('created')}
                    grow
                    className={`EditInput-created`}
                  >
                    {getDisplayDate(aed.created)}
                  </FormField>
                  <FormField
                    label={AED_ATTRIBUTE_LABELS.get('userEmail')}
                    grow
                    className={`EditInput-userEmail`}
                  >
                    {aed.userEmail}
                    {aed.source && `\n(${aed.source})`}
                  </FormField>
                  <FormField
                    label={AED_ATTRIBUTE_LABELS.get('lastReviewed')}
                    grow
                    className={`EditInput-lastReviewed`}
                  >
                    {aed.lastReviewed === ''
                      ? 'AED has never been reviewed.'
                      : getDisplayDate(aed.lastReviewed)}
                  </FormField>
                  {!aed.alwaysAvailable &&
                    aed.googlePlaceID &&
                    placeHourInfo !== undefined && (
                      <FormField
                        label="Business Hours"
                        className={`EditInput-businessHours-${
                          placeHourInfo.isOpen ? 'open' : 'closed'
                        }`}
                      >
                        <span>{placeHourInfo.isOpen ? 'Open' : 'Closed'}</span>
                        {placeHourInfo.hours !== '' &&
                          ` (${placeHourInfo.hours})`}
                      </FormField>
                    )}
                  {aed.alwaysAvailable && (
                    <FormField
                      label="Business Hours"
                      className={`EditInput-businessHours-open`}
                    >
                      <span>Open</span> (24/7)
                    </FormField>
                  )}
                </>
              )}
            </Layout.Column>
          </LayoutContainer>
        </Layout.Column>
      </LayoutContainer>
    </StyledCard>
  )
}

export default AEDRecordInformation
