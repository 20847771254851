import React from 'react'
import styled from '@emotion/styled'

import colors from '@workday/canvas-colors-web'
import { Layout } from '@workday/canvas-kit-react-layout'
import LoadingDots from '@workday/canvas-kit-react-loading-animation'

import { StyledCard, CardHeading } from 'common/styles'

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`
const FilterColumn = styled(Layout.Column)`
  @media (max-width: 600px) {
    display: none;
  }
`
const Spacer = styled.div`
  margin-top: 50px;

  @media (max-width: 600px) {
    margin-top: 25px;
  }
`
const LoadingContainer = styled.div`
  color: ${colors.blackPepper100};
  min-height: 400px;
  text-align: center;
`
const LoadingIndicator = styled(LoadingDots)`
  margin: 100px 0px;
`
const Heading = styled.div`
  color: ${colors.blackPepper300};
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`
const SubHeading = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 18px;
  }
`

interface LoadingProps {
  totalAgencies: number
  loadedAgencies: number
}

const Loading = ({ totalAgencies, loadedAgencies }: LoadingProps) => {
  return (
    <ContentContainer>
      <Spacer />
      <Layout gutter={0}>
        <FilterColumn width={'325px'}></FilterColumn>
        <Layout.Column columns={12} spacing={0}>
          <StyledCard>
            <CardHeading>
              <LoadingContainer>
                <LoadingIndicator />
                <Heading>Loading AEDs...</Heading>
                <SubHeading>
                  (Loaded {loadedAgencies} of {totalAgencies} Agency AEDs)
                </SubHeading>
              </LoadingContainer>
            </CardHeading>
          </StyledCard>
        </Layout.Column>
      </Layout>
    </ContentContainer>
  )
}

export default Loading
