export type GooglePlace = {
  address_components: GooglePlace_Address[]
  name: string
  place_id: string
  types: string[]
  formatted_phone_number: string
  geometry: { location: { lat: () => string, lng: () => string } }
  opening_hours? : GooglePlace_OpenHours
}
export type GooglePlace_OpenHours = {
  isOpen: any
  weekday_text: string[]
  open_now: boolean
}
export type GooglePlace_Address = {
  long_name: string
  short_name: string
  types: string[]
}
export const PLACE_TYPES_OPTIONS: string[] = [
  'Unknown',
  'Airport',
  'Amusement Park',
  'Aquarium',
  'Bank',
  'Bar',
  'Bowling Alley',
  'Business',
  'Campground',
  'Casino',
  'Cemetery',
  'City Hall',
  'Community Center',
  'Convenience Store',
  'Convention Center',
  'Courthouse',
  'Dentist',
  'Detention/Correctional',
  'Doctor',
  'Embassy',
  'Factory/Industrial',
  'Fire Station',
  'Funeral Home',
  'Gas Station',
  'Golf Course',
  'Government Office',
  'Grocery or Supermarket',
  'Gym/Sports Club',
  'Hospital',
  'Library',
  'Lodging/Hotel',
  'Medical Care/Institutional',
  'Movie Theater',
  'Museum',
  'Night Club',
  'Office',
  'Park',
  'Parking',
  'Place of Worship',
  'Police',
  'Post Office',
  'Preschool',
  'Public Transit Station',
  'Residential/Home',
  'Restaurant',
  'RV Park',
  'School',
  'Shopping Mall',
  'Stadium',
  'Store',
  'Theatre',
  'University',
  'Veterinarian',
  'Warehouse',
  'Zoo',
]

export type PLACE_TYPE = typeof PLACE_TYPES_OPTIONS[number]

export const GOOGLE_PLACE_TYPE_MAP: Map<string, PLACE_TYPE> = new Map([
  ['accounting', 'Office'],
  ['airport', 'Airport'],
  ['amusement_park', 'Amusement Park'],
  ['aquarium', 'Aquarium'],
  ['art_gallery', 'Business'],
  ['bakery', 'Store'],
  ['bank', 'Bank'],
  ['bar', 'Bar'],
  ['beauty_salon', 'Business'],
  ['bicycle_store', 'Store'],
  ['book_store', 'Store'],
  ['bowling_alley', 'Bowling Alley'],
  ['bus_station', 'Public Transit Station'],
  ['cafe', 'Restaurant'],
  ['campground', 'Campground'],
  ['car_dealer', 'Business'],
  ['car_rental', 'Business'],
  ['car_repair', 'Business'],
  ['car_wash', 'Business'],
  ['casino', 'Casino'],
  ['cemetery', 'Cemetery'],
  ['church', 'Place of Worship'],
  ['city_hall', 'City Hall'],
  ['clothing_store', 'Store'],
  ['convenience_store', 'Convenience Store'],
  ['courthouse', 'Courthouse'],
  ['dentist', 'Dentist'],
  ['department_store', 'Store'],
  ['doctor', 'Doctor'],
  ['drugstore', 'Store'],
  ['electronics_store', 'Store'],
  ['embassy', 'Embassy'],
  ['fire_station', 'Fire Station'],
  ['florist', 'Store'],
  ['funeral_home', 'Funeral Home'],
  ['furniture_store', 'Store'],
  ['gas_station', 'Gas Station'],
  ['general_contractor', 'Business'],
  ['grocery_or_supermarket', 'Grocery or Supermarket'],
  ['gym', 'Gym/Sports Club'],
  ['hair_care', 'Business'],
  ['hardware_store', 'Store'],
  ['hindu_temple', 'Place of Worship'],
  ['home_goods_store', 'Store'],
  ['hospital', 'Hospital'],
  ['insurance_agency', 'Business'],
  ['jewelry_store', 'Store'],
  ['laundry', 'Business'],
  ['lawyer', 'Business'],
  ['library', 'Library'],
  ['light_rail_station', 'Public Transit Station'],
  ['liquor_store', 'Store'],
  ['local_government_office', 'Government Office'],
  ['locksmith', 'Business'],
  ['lodging', 'Lodging/Hotel'],
  ['meal_delivery', 'Business'],
  ['meal_takeaway', 'Restaurant'],
  ['mosque', 'Place of Worship'],
  ['movie_rental', 'Business'],
  ['movie_theater', 'Theatre'],
  ['moving_company', 'Business'],
  ['museum', 'Museum'],
  ['night_club', 'Night Club'],
  ['painter', 'Business'],
  ['park', 'Park'],
  ['parking', 'Parking'],
  ['pet_store', 'Store'],
  ['pharmacy', 'Store'],
  ['physiotherapist', 'Business'],
  ['plumber', 'Business'],
  ['police', 'Police'],
  ['post_office', 'Post Office'],
  ['primary_school', 'School'],
  ['real_estate_agency', 'Business'],
  ['restaurant', 'Restaurant'],
  ['roofing_contractor', 'Business'],
  ['rv_park', 'RV Park'],
  ['school', 'School'],
  ['secondary_school', 'School'],
  ['shoe_store', 'Store'],
  ['shopping_mall', 'Shopping Mall'],
  ['spa', 'Business'],
  ['stadium', 'Stadium'],
  ['storage', 'Business'],
  ['store', 'Store'],
  ['subway_station', 'Public Transit Station'],
  ['supermarket', 'Grocery or Supermarket'],
  ['synagogue', 'Place of Worship'],
  ['taxi_stand', 'Public Transit Station'],
  ['train_station', 'Public Transit Station'],
  ['transit_station', 'Public Transit Station'],
  ['travel_agency','Business'],
  ['university','University'],
  ['veterinary_care', 'Veterinarian'],
  ['zoo', 'Zoo'],
])
