import React from 'react'

import { SelectOption } from '@workday/canvas-kit-react-select'

import { AED_Attribute } from 'models/aed'
import { PLACE_TYPES_OPTIONS } from 'models/placeType'

const STATUS_OPTIONS = ['Pending', 'Approved']

const MANUFACTURER_OPTIONS = [
  'Agilent',
  'AMI Italia',
  'Advanced Instrumentations',
  'Avive',
  'Beijing M&B Electronic Instruments',
  'Bexen cardio',
  'BPL Medical Technologies',
  'Burdick',
  'CardiAid',
  'Cardiac Science',
  'Cintas',
  'CMOS DRAKE',
  'Corpuls',
  'CU Medical',
  'Defibtech',
  'EMTEL',
  'Fukuda',
  'General Electric',
  'HeartSine',
  'Instramed',
  'Life Plus Medical',
  'LifeBot',
  'Mediana',
  'Medical Econet',
  'Meditech',
  'Medtronic',
  'METsis Medikal',
  'Mindray',
  'MRL',
  'NANOOMTECH',
  'Nihon Kohden',
  'Orientmed International FZE',
  'Philips',
  'Physio-Control',
  'Prestan',
  'Primedic',
  'Radian',
  'Schiller',
  'Shenzhen XFT Electronics',
  'Shvabe-Zurich',
  'Stryker',
  'Survivalink',
  'Tecno-Gaz',
  'US DEFIB',
  'WEINMANN Emergency Medical Technology',
  'Welch Allyn',
  'YUAN Technology Limited',
  'ZOLL',
]

export const getSelectOptions = (
  key: AED_Attribute,
): React.ReactElement<SelectOption>[] => {
  const options: React.ReactElement<SelectOption>[] = []

  switch (key) {
    case 'status':
      STATUS_OPTIONS.forEach((status: string) => {
        options.push(
          <SelectOption
            key={`Status-${status}`}
            value={status}
            label={status}
          />,
        )
      })
      break

    case 'manufacturer':
      options.push(
        <SelectOption
          value=""
          key="Manufacturer-default"
          label="Select a Manufacturer"
        />,
      )
      MANUFACTURER_OPTIONS.forEach((manufacturer: string) => {
        options.push(
          <SelectOption
            key={`Manufacturer-${manufacturer}`}
            value={manufacturer}
            label={manufacturer}
          />,
        )
      })
      break
    case 'type':
      PLACE_TYPES_OPTIONS.forEach((type: string) => {
        options.push(
          <SelectOption key={`PlaceType-${type}`} value={type} label={type} />,
        )
      })
      break
    default:
      break
  }

  return options
}

export const convertBase64 = (
  blob: Blob,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    try {
      fileReader.readAsDataURL(blob)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    } catch {
      reject('Not a Blob')
    }
  })
}

export const getTodaysHours = (hours: string[]): string => {
  var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  var d = new Date()
  var dayName = days[d.getDay()]

  const hoursToday = hours.find((hour) => hour.includes(`${dayName}: `))

  return hoursToday === undefined || hoursToday.includes('Closed')
    ? ''
    : hoursToday.replaceAll(`${dayName}: `, '')
}

export const resizeImage = (file:File, maxWidth:number, maxHeight:number):Promise<Blob | null> => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width <= maxWidth && height <= maxHeight) {
        resolve(file);
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      let canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      let context = canvas.getContext('2d');

      if (context != null)  {
        context.drawImage(image, 0, 0, newWidth, newHeight);
        canvas.toBlob( resolve, file.type);
      } else {
        reject("Error during resize - context null");
      }
    };
    image.onerror = reject;
  });
}


export const phoneIsInvalid = (phone: string): boolean => {
  if (phone === undefined || phone === '') return false

  const regex = /^((\+\d{2})?(\+\d)?(\s|-|\.|\/)?\(?\d{3}\)?(\s|-|\.|\/)?\d{3}(\s|-|\.|\/)?((\d{4})|(\d{2}(\s|-|\.|\/)?\d{2})))|(\d{5}(\s|-|\.|\/)?\d{6})$/im
  return !regex.test(phone)
}
export const emailIsInvalid = (email: string): boolean => {
  if (email === undefined || email === '') return false

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/im
  return !regex.test(email)
}
