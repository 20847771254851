import React from 'react'

import AED from 'models/aed'

import CustomFilter from './filters/customFilter'
import AEDAgencyFilter from './filters/aedAgencyFilter'
import AEDPlaceTypeFilter from './filters/aedPlaceTypeFilter'
import AEDStatusFilter from './filters/aedStatusFilter'
import AEDImageFilter from './filters/aedImageFilter'
import AEDExpiredFilter from './filters/aedExpiredFilter'
import AEDAccessFilter from './filters/aedAccessFilter'
import PPTAdminFilter from './filters/pptAdminFilter'
import USER from 'models/user'

export interface FiltersProps {
  user: USER
  aeds: AED[]
  agencies: Map<string, string>
  filterQuery: Partial<AED>
  updateQuery: (updatedQuery: Partial<AED>) => void
  onPPTAdminAgencyAdd: (
    agencies: string[],
    includeNoAgency: boolean,
    includePlaceSearch: boolean,
  ) => void
}

const Filters = ({ ...props }: FiltersProps) => {
  return (
    <>
      <CustomFilter {...props} />
      <AEDAgencyFilter {...props} />
      <AEDStatusFilter {...props} />
      <AEDAccessFilter {...props} />
      <AEDImageFilter {...props} />
      <AEDExpiredFilter {...props} />
      <AEDPlaceTypeFilter {...props} />
      {props.user.isPulsePointAdmin && <PPTAdminFilter {...props} />}
    </>
  )
}

export default Filters
