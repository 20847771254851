import React from 'react'

import { ButtonSize, ButtonVariant } from '@workday/canvas-kit-react-button'
import { Modal, ModalWidth } from '@workday/canvas-kit-react-modal'

import { markAEDAsReviewed } from 'api/requests'
import { StyledButton } from 'common/styles'
import AED from 'models/aed'
import USER from 'models/user'

export interface ReviewedConfirmationModalProps {
  aed: AED
  user: USER
  open: boolean
  onClose: () => void
  onSuccess: (lastReviewed: string) => void
}

const ReviewedConfirmationModal = ({
  aed,
  user,
  open,
  onClose,
  onSuccess,
}: ReviewedConfirmationModalProps) => {
  return (
    <Modal
      width={ModalWidth.s}
      heading="Review AED"
      open={open}
      handleClose={onClose}
    >
      <div>
        <p>
          The Information for this AED record has been reviewed and is correct.
        </p>
      </div>
      <StyledButton
        variant={ButtonVariant.Primary}
        size={ButtonSize.Medium}
        onClick={() =>
          markAEDAsReviewed(user, aed, (updatedAED: AED) => {
            onSuccess(updatedAED.lastReviewed)
          })
        }
      >
        Confirm
      </StyledButton>
      <StyledButton
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Medium}
        onClick={onClose}
      >
        Cancel
      </StyledButton>
    </Modal>
  )
}

export default ReviewedConfirmationModal
