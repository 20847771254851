import {
  ButtonSize,
  ButtonVariant,
  IconButton,
} from '@workday/canvas-kit-react-button'
import { colors } from '@workday/canvas-colors-web'
import {
  editIcon,
  sortDownIcon,
  sortUpIcon,
} from '@workday/canvas-system-icons-web'
import { getAEDImageURL, saveAED } from 'api/requests'
import { getDisplayDate } from 'common/utils'
import { Modal, ModalWidth } from '@workday/canvas-kit-react-modal'
import { StyledButton } from 'common/styles'
import { SystemIcon } from '@workday/canvas-kit-react-icon'
import { Table, TableRow } from '@workday/canvas-kit-react-table'
import AED, { AED_Attribute, AED_ATTRIBUTE_LABELS } from 'models/aed'
import AEDGooglePlaceLookupModal from 'app/edit/modals/placeLookupModal'
import GoogleMapReact from 'google-map-react'
import React from 'react'
import styled from '@emotion/styled'
import USER from 'models/user'

import ResultsPager from './resultsPager'

export const ITEMS_TO_SHOW_PER_PAGE = 15

const StyledTable = styled(Table)`
  margin-top: 10px;

  td {
    min-width: 100px;
  }
`
const AEDTableHeader = styled.th`
  cursor: pointer;
  background-color: ${colors.soap100}!important;

  &:hover {
    background-color: ${colors.soap200}!important;
  }

  @media (max-width: 900px) {
    &.optional {
      display: none;
    }
  }
`
const SortIcon = styled(SystemIcon)`
  display: block;
  float: right;
`

const Image = styled.div<{ imgPath: string }>`
  margin: 0px auto;
  width: 120px;
  height: 80px;
  cursor: zoom-in;
  background: no-repeat center center url('${(props) => props.imgPath}');
  background-size: cover;

  @media (max-width: 900px) {
    width: 60px;
    height: 40px;
  }
`
const NoImage = styled.div`
  margin: 0px auto;
  width: 120px;
  height: 80px;
  background: no-repeat center center url('/no-aed-image.png');
  background-size: contain;
  opacity: 0.5;

  @media (max-width: 900px) {
    width: 60px;
    height: 40px;
  }
`
const LargeImage = styled.div<{ imgPath: string }>`
  margin: 0px auto;
  width: 750px;
  height: 500px;
  background: no-repeat center center url('${(props) => props.imgPath}');
  background-size: contain;
`
const ImageCell = styled.td`
  padding: 2px;
  width: 130px;

  @media (max-width: 900px) {
    width: 65px;
    min-width: unset !important;
  }
`
const LocationCell = styled.td`
  @media (max-width: 900px) {
    max-width: 240px;
    min-width: unset !important;
  }
`
const OptionalTableCell = styled.td`
  @media (max-width: 900px) {
    display: none;
  }
`
const AEDNameContainer = styled.div`
  font-weight: 700;
  min-height: 20px;
`
const AEDDescriptionContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 440px;
  min-height: 20px;
`
const TagTable = styled.div`
  display: flex;
  min-height: 20px;

  @media (max-width: 900px) {
    display: block;
  }
`
const Tag = styled.div<{ isPrimary: boolean }>`
  background-color: ${(props) =>
    props.isPrimary ? colors.plum600 : colors.blackPepper100};
  width: 115px;
  padding: 0px 5px;
  text-align: center;
  border-radius: 10px;
  margin: 5px 10px 0px 0px;
  color: ${colors.frenchVanilla100};
`
const LookupNearbyPlaceButton = styled(StyledButton)`
  margin: 10px;
`

const AED_COLUMNS = [
  {
    name: '',
    sortKey: 'hasImage',
  },
  {
    name: 'AED Location',
    sortKey: 'name',
  },
  {
    name: AED_ATTRIBUTE_LABELS.get('address'),
    sortKey: 'address',
  },
  {
    name: AED_ATTRIBUTE_LABELS.get('city'),
    sortKey: 'city',
  },
  {
    name: AED_ATTRIBUTE_LABELS.get('county'),
    sortKey: 'county',
  },
  {
    name: 'Responsible Party',
    sortKey: 'siteCoordinatorName',
  },
  {
    name: 'Last Review',
    sortKey: 'lastReviewed',
  },
  {
    name: AED_ATTRIBUTE_LABELS.get('type'),
    sortKey: 'type',
  },
  {
    name: '',
    sortKey: '',
  },
]

export interface ResultsTableProps {
  user: USER
  aeds: AED[]
  initialPageNumber: number
  onSelectedAED: (aed: AED, pageNumber: number) => void
}

const ResultsTable = ({
  user,
  aeds,
  initialPageNumber,
  onSelectedAED,
}: ResultsTableProps) => {
  const [viewAEDTableState, setViewAEDTableState] = React.useState<{
    sortBy: AED_Attribute
    sortAsc: boolean
    pageNumber: number
    showPlaceLookupModal: boolean
    selectedAED: AED
    googleMap: { map?: any; maps?: any }
  }>({
    sortBy: 'name',
    sortAsc: true,
    pageNumber: initialPageNumber,
    showPlaceLookupModal: false,
    selectedAED: aeds[0],
    googleMap: {},
  })

  const [aedImageInfo, setAEDImageInfo] = React.useState<{
    path: string
    name: string
  }>()

  const sortedAEDs = aeds.sort((a, b) => {
    if ((a[viewAEDTableState.sortBy]?.toString().trim() || '') ===
      (b[viewAEDTableState.sortBy]?.toString().trim() || '')) {
      return a.ID > b.ID
        ? viewAEDTableState.sortAsc
          ? 1
          : -1
        : viewAEDTableState.sortAsc
          ? -1
          : 1
    }

    return (a[viewAEDTableState.sortBy]?.toString().trim() || '') >
      (b[viewAEDTableState.sortBy]?.toString().trim() || '')
      ? viewAEDTableState.sortAsc
        ? 1
        : -1
      : viewAEDTableState.sortAsc
        ? -1
        : 1
  })
  if (
    viewAEDTableState.pageNumber * ITEMS_TO_SHOW_PER_PAGE >
    sortedAEDs.length
  ) {
    setViewAEDTableState({
      ...viewAEDTableState,
      pageNumber: Math.ceil(sortedAEDs.length / ITEMS_TO_SHOW_PER_PAGE) - 1,
    })
  }

  const pagedAEDs = sortedAEDs.slice(
    viewAEDTableState.pageNumber * ITEMS_TO_SHOW_PER_PAGE,
    (viewAEDTableState.pageNumber + 1) * ITEMS_TO_SHOW_PER_PAGE,
  )

  return (
    <>
      <StyledTable>
        <thead>
          <TableRow header={true}>
            {AED_COLUMNS.map((col, i) => {
              return (
                <AEDTableHeader
                  className={i > 1 ? 'optional' : ''}
                  onClick={() => {
                    if (col.sortKey === '') return null
                    setViewAEDTableState({
                      ...viewAEDTableState,
                      sortAsc:
                        col.sortKey === viewAEDTableState.sortBy &&
                        !viewAEDTableState.sortAsc,
                      sortBy: col.sortKey as AED_Attribute,
                    })
                  }}
                  key={i}
                >
                  {col.name}
                  {col.sortKey === viewAEDTableState.sortBy ? (
                    viewAEDTableState.sortAsc ? (
                      <SortIcon icon={sortDownIcon} />
                    ) : (
                      <SortIcon icon={sortUpIcon} />
                    )
                  ) : (
                    <></>
                  )}
                </AEDTableHeader>
              )
            })}
          </TableRow>
        </thead>
        <tbody>
          {pagedAEDs.map((aed, i) => {
            return (
              <TableRow
                key={i}
                onClick={() => {
                  if (window.innerWidth < 900) {
                    onSelectedAED(aed, viewAEDTableState.pageNumber)
                  }
                }}
              >
                <ImageCell key={`AED_Image_${i}`}>
                  {aed.hasImage ? (
                    <Image
                      onClick={() => {
                        setAEDImageInfo({ path: aed.imagePath, name: aed.name })
                      }}
                      imgPath={getAEDImageURL(user, aed.imagePath)}
                    />
                  ) : (
                    <NoImage />
                  )}
                </ImageCell>
                <LocationCell key={`AED_Name_${i}`}>
                  <div>
                    <AEDNameContainer>{aed.name}</AEDNameContainer>
                    <AEDDescriptionContainer>
                      {aed.description}
                    </AEDDescriptionContainer>
                    <TagTable>
                      {aed.status === 'Pending' && (
                        <Tag isPrimary={false}>Pending Approval</Tag>
                      )}
                      {aed.accessibility === 'Private' && (
                        <Tag isPrimary={false}>Restricted Access</Tag>
                      )}
                      {aed.hasImage && !aed.hasApprovedImage && (
                        <Tag isPrimary={true}>Image Pending</Tag>
                      )}
                    </TagTable>
                  </div>
                </LocationCell>

                <OptionalTableCell key={`AED_Address_${i}`}>
                  {aed.address}
                </OptionalTableCell>
                <OptionalTableCell key={`AED_City_${i}`}>
                  {aed.city}
                </OptionalTableCell>
                <OptionalTableCell key={`AED_County_${i}`}>
                  {aed.county}
                </OptionalTableCell>
                <OptionalTableCell key={`AED_SiteCoordinator_${i}`}>
                  {aed.siteCoordinatorName}
                </OptionalTableCell>
                {user.admin &&
                  user.admin.includePlaceSearch === true &&
                  aed.googlePlaceID === '' ? (
                  <OptionalTableCell key={`AED_PlaceSearch_${i}`} colSpan={2}>
                    <LookupNearbyPlaceButton
                      variant={ButtonVariant.Primary}
                      size={ButtonSize.Small}
                      onClick={() =>
                        setViewAEDTableState({
                          ...viewAEDTableState,
                          showPlaceLookupModal: true,
                          selectedAED: aed,
                        })
                      }
                    >
                      Add Place Details
                    </LookupNearbyPlaceButton>
                  </OptionalTableCell>
                ) : (
                  <>
                    <OptionalTableCell key={`AED_LastUpdate_${i}`}>
                      {aed.lastReviewed ? getDisplayDate(aed.lastReviewed) : ''}
                    </OptionalTableCell>
                    <OptionalTableCell key={`AED_Type_${i}`}>
                      {aed.type}
                    </OptionalTableCell>
                  </>
                )}
                <OptionalTableCell
                  style={{ minWidth: 'unset' }}
                  key={`AED_Edit_${i}`}
                >
                  <IconButton
                    aria-label="Edit"
                    icon={editIcon}
                    onClick={() =>
                      onSelectedAED(aed, viewAEDTableState.pageNumber)
                    }
                  />
                </OptionalTableCell>
              </TableRow>
            )
          })}
        </tbody>
      </StyledTable>
      <ResultsPager
        aedCount={sortedAEDs.length}
        selectedPage={viewAEDTableState.pageNumber}
        onPageSelected={(selectedPage) =>
          setViewAEDTableState({
            ...viewAEDTableState,
            pageNumber: selectedPage,
          })
        }
      />
      <Modal
        width={ModalWidth.m}
        heading={
          aedImageInfo !== undefined ? aedImageInfo.name : 'View AED Image'
        }
        open={aedImageInfo !== undefined}
        handleClose={() => setAEDImageInfo(undefined)}
      >
        <LargeImage
          imgPath={
            aedImageInfo !== undefined
              ? getAEDImageURL(user, aedImageInfo.path)
              : ''
          }
        ></LargeImage>
      </Modal>
      {viewAEDTableState.showPlaceLookupModal && (
        <AEDGooglePlaceLookupModal
          aed={viewAEDTableState.selectedAED}
          open={viewAEDTableState.showPlaceLookupModal}
          googleMap={viewAEDTableState.googleMap}
          onClose={() =>
            setViewAEDTableState({
              ...viewAEDTableState,
              showPlaceLookupModal: false,
            })
          }
          onReplace={(placeInfo) => {
            Object.keys(placeInfo).forEach((key) => {
              ; (viewAEDTableState.selectedAED as any)[key] = placeInfo[
                key as AED_Attribute
              ] as string
            })
            saveAED(user, viewAEDTableState.selectedAED, () => { })
            setViewAEDTableState({
              ...viewAEDTableState,
              showPlaceLookupModal: false,
            })
          }}
        />
      )}
      {user.admin && user.admin.includePlaceSearch && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCxDiJ-c0tzCGpCH29aVbVubkm6Y5OZeTQ' }}
          defaultCenter={{
            lat: 40.312109,
            lng: -100.122439,
          }}
          defaultZoom={11}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            setViewAEDTableState({
              ...viewAEDTableState,
              googleMap: { map, maps },
            })
          }
        />
      )}
    </>
  )
}

export default ResultsTable
