import { AED_ACCESS } from './aedAccess'
import { AED_STATUS } from './aedStatus'
import { PLACE_TYPE } from './placeType'

import { AED_EXPIRED } from 'app/browse/filters/aedExpiredFilter'
import { AED_COLOCATED } from 'app/browse/filters/customFilter'

export type AED = {
  name: string
  description: string
  address: string
  city: string
  county: string
  postal: string
  state: string
  country: string
  accessibility: AED_ACCESS
  hasImage: boolean
  imagePath: string
  hasApprovedImage: boolean
  isMobile: boolean
  isHidden: boolean
  status: AED_STATUS
  type: PLACE_TYPE
  hasNaloxone: boolean
  hasEpinephrine: boolean
  hasBleedingControlKit: boolean
  epinephrineExpDate: string
  naloxoneExpDate: string
  bleedingControlKitExpDate: string
  batteryExpDate: string
  electrodeExpDate: string
  pedElectrodeExpDate: string
  siteCoordinatorName: string
  lastUpdated: string
  agencyID: string
  hasReportedIssues: boolean
  lastReviewed: string
  manufacturer: string
  model: string
  userID: string
  ID: string
  siteCoordinatorEmail: string
  userEmail: string
  created: string
  installDate: string
  serialNumber: string
  coordinatorPhone: string
  coordinatorPhoneTextEnabled: boolean
  coordinatorAltPhone: string
  coordinatorAltPhoneTextEnabled: boolean
  lastInspectedDate: string
  medicalDirection: string
  businessPhone: string
  comment: string
  assetID: string
  googlePlaceID: string
  accessCode: string
  alwaysAvailable: boolean
  source: string
  activeSubscribers: string[]

  latitude: number
  longitude: number

  // Derived AED Attributes
  expired?: AED_EXPIRED
  colocated?: AED_COLOCATED
}

export const NEW_AED_TEMPLATE: AED = {
  name: '',
  description: '',
  address: '',
  city: '',
  county: '',
  postal: '',
  state: '',
  country: '',
  accessibility: 'Public',
  hasImage: false,
  imagePath: '',
  hasApprovedImage: false,
  isMobile: false,
  isHidden: false,
  status: 'Approved',
  type: 'UnKnown',
  hasNaloxone: false,
  hasEpinephrine: false,
  hasBleedingControlKit: false,
  epinephrineExpDate: '0000-00-00',
  naloxoneExpDate: '0000-00-00',
  bleedingControlKitExpDate: '0000-00-00',
  batteryExpDate: '0000-00-00',
  electrodeExpDate: '0000-00-00',
  pedElectrodeExpDate: '0000-00-00',
  siteCoordinatorName: '',
  lastUpdated: '0000-00-00',
  agencyID: '',
  hasReportedIssues: false,
  lastReviewed: '0000-00-00',
  manufacturer: '',
  model: '',
  userID: '',
  ID: '-1',
  siteCoordinatorEmail: '',
  userEmail: '',
  created: '0000-00-00',
  installDate: '0000-00-00',
  serialNumber: '',
  coordinatorPhone: '',
  coordinatorPhoneTextEnabled: false,
  coordinatorAltPhone: '',
  coordinatorAltPhoneTextEnabled: false,
  lastInspectedDate: '0000-00-00',
  medicalDirection: '',
  businessPhone: '',
  comment: '',
  assetID: '',
  googlePlaceID: '',
  accessCode: '',
  latitude: 0,
  longitude: 0,
  alwaysAvailable: false,
  source: 'AED Admin',
  activeSubscribers: [],
}

export type AED_EXPORT = {
  agencyID: string
  status: AED_STATUS
  accessibility: AED_ACCESS
  isMobile: string
  isHidden: string
  hasBleedingControlKit: string
  hasNaloxone: string
  hasEpinephrine: string
  name: string
  description: string
  address: string
  city: string
  county: string
  state: string
  postal: string
  country: string
  businessPhone: string
  latitude: number
  longitude: number
  type: PLACE_TYPE
  assetID: string
  manufacturer: string
  model: string
  serialNumber: string
  installDate: string
  batteryExpDate: string
  electrodeExpDate: string
  pedElectrodeExpDate: string
  naloxoneExpDate: string
  epinephrineExpDate: string
  bleedingControlKitExpDate: string
  siteCoordinatorName: string
  siteCoordinatorEmail: string
  coordinatorPhone: string
  coordinatorPhoneTextEnabled: string
  coordinatorAltPhone: string
  coordinatorAltPhoneTextEnabled: string
  medicalDirection: string
  comment: string
  hasImage: string
  hasApprovedImage: string
  userEmail: string
  lastInspectedDate: string
  lastReviewed: string
  lastUpdated: string
  created: string
  aedID: string
  accessCode: string
  alwaysAvailable: string
}

export const AED_EXPORT_HEADERS: {} = {
  agencyID: 'AgencyID',
  status: 'AEDType',
  accessibility: 'AEDAccessibility',
  isMobile: 'AEDIsMobile',
  isHidden: 'AEDIsHidden',
  hasBleedingControlKit: 'HasBleedingControlKit',
  hasNaloxone: 'HasNaloxone',
  hasEpinephrine: 'HasEphinephrine',
  name: 'AEDLocationName',
  description: 'AEDLocationDescription',
  address: 'AEDAddress',
  city: 'AEDCityOrLocality',
  county: 'AEDCounty',
  state: 'AEDStateOrProvince',
  postal: 'AEDPostalCode',
  country: 'AEDCountry',
  businessPhone: 'BusinessPhone',
  latitude: 'AEDLatitude',
  longitude: 'AEDLongitude',
  type: 'AEDPlaceType',
  assetID: 'AEDAssetID',
  manufacturer: 'AEDManufacturer',
  model: 'AEDModel',
  serialNumber: 'AEDSerialNumber',
  installDate: 'AEDInstallDate',
  batteryExpDate: 'AEDBatteryExpDate',
  electrodeExpDate: 'AEDElectrodeExpDate',
  pedElectrodeExpDate: 'AEDPedElectrodeExpDate',
  naloxoneExpDate: 'NaloxoneExpDate',
  epinephrineExpDate: 'EpinephrineExpDate',
  bleedingControlKitExpDate: 'BleedingControlKitExpDate',
  siteCoordinatorName: 'SiteCoordinator',
  siteCoordinatorEmail: 'CoordinatorEmail',
  coordinatorPhone: 'CoordinatorPhone',
  coordinatorPhoneTextEnabled: 'CoordinatorPhoneTextEnabled',
  coordinatorAltPhone: 'CoordinatorAltPhone',
  coordinatorAltPhoneTextEnabled: 'CoordinatorAltPhoneTextEnabled',
  medicalDirect: 'MedicalDirection',
  comment: 'AEDComment',
  hasImage: 'HasImage',
  hasApprovedImage: 'ImageApproved',
  userEmail: 'AEDRecordedBy',
  lastInspectedDate: 'LastInspectedDate',
  lastReviewed: 'LastReviewedDate',
  lastUpdated: 'AEDLastUpdate (UTC)',
  created: 'AEDCreated (UTC)',
  aedID: 'PulsePointAEDID',
  accessCode: 'AccessCode',
  alwaysAvailable: 'Available 24/7',
}

export type AED_Attribute = keyof AED

export const AED_ATTRIBUTE_LABELS: Map<AED_Attribute, string> = new Map([
  ['status', 'Status'],
  ['name', 'Location / Business Name'],
  ['description', 'AED Location Description'],
  ['address', 'Address'],
  ['city', 'City / Locality'],
  ['state', 'State / Province'],
  ['postal', 'ZIP / Postal Code'],
  ['county', 'County'],
  ['country', 'Country'],
  ['businessPhone', 'Location / Business Phone'],
  ['type', 'Place Type'],
  ['siteCoordinatorName', 'Name'],
  ['siteCoordinatorEmail', 'Responsible Party Email'],
  ['coordinatorPhone', 'Phone Number'],
  [
    'coordinatorPhoneTextEnabled',
    'Text this number if AED is in activation radius',
  ],
  ['coordinatorAltPhone', 'Alternative Phone Number'],
  [
    'coordinatorAltPhoneTextEnabled',
    'Text this number if AED is in activation radius',
  ],
  ['accessibility', 'Restricted Access'],
  ['isMobile', 'Mobile'],
  ['manufacturer', 'Manufacturer'],
  ['model', 'Model'],
  ['serialNumber', 'Serial Number'],
  ['installDate', 'Install Date'],
  ['medicalDirection', 'Medical Direction'],
  ['assetID', 'Asset ID'],
  ['batteryExpDate', 'Battery Expiration Date'],
  ['electrodeExpDate', 'Electrode Expiration Date'],
  ['pedElectrodeExpDate', 'Pediatric Electrode Expiration Date'],
  ['hasBleedingControlKit', 'Bleeding Control Kit'],
  ['hasEpinephrine', 'Epinephrine'],
  ['hasNaloxone', 'Naloxone'],
  ['bleedingControlKitExpDate', 'Bleeding Control Kit Expiration Date'],
  ['epinephrineExpDate', 'Epinephrine Expiration Date'],
  ['naloxoneExpDate', 'Naloxone Expiration Date'],
  ['agencyID', 'Agency'],
  ['businessPhone', 'Business Phone Number'],
  ['created', 'Record created on'],
  ['userEmail', 'AED added by'],
  ['isHidden', 'AED is Hidden'],
  ['comment', 'AED Comment'],
  ['lastReviewed', 'Last reviewed on'],
  ['lastInspectedDate', 'Last Inspected Date'],
  ['googlePlaceID', 'Google Place ID'],
  ['accessCode', 'Access Code'],
  ['alwaysAvailable', 'AED available 24/7'],
  ['source', 'AED Source'],
  ['activeSubscribers', 'Subscriptions'],
])

type Boolean_Attribute_Definition = {
  valueLabels: {
    label: string
    notLabel: string
  }
  filterType: 'Boolean'
}

type Other_Attribute_Definition = {
  filterType: 'Select' | 'Input' | 'MultiSelect' | 'Date' | 'Derived'
}

type Default_Attribute_Definition = {
  label: string
  key: AED_Attribute
}

export type AED_Attribute_Definition = Default_Attribute_Definition &
  (Other_Attribute_Definition | Boolean_Attribute_Definition)

export const AED_ATTRIBUTE_DEFINITIONS: AED_Attribute_Definition[] = [
  {
    label: `${AED_ATTRIBUTE_LABELS.get('name')}`,
    key: 'name',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('description')}`,
    key: 'description',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('address')}`,
    key: 'address',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('city')}`,
    key: 'city',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('county')}`,
    key: 'county',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('postal')}`,
    key: 'postal',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('state')}`,
    key: 'state',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('country')}`,
    key: 'country',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('businessPhone')}`,
    key: 'businessPhone',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('type')}`,
    key: 'type',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('siteCoordinatorName')}`,
    key: 'siteCoordinatorName',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('coordinatorPhone')}`,
    key: 'coordinatorPhone',
    filterType: 'Input',
  },
  {
    valueLabels: {
      label: 'SMS enabled',
      notLabel: 'SMS disabled',
    },
    label: `${AED_ATTRIBUTE_LABELS.get('coordinatorPhoneTextEnabled')}`,
    key: 'coordinatorPhoneTextEnabled',
    filterType: 'Boolean',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('siteCoordinatorEmail')}`,
    key: 'siteCoordinatorEmail',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('coordinatorAltPhone')}`,
    key: 'coordinatorAltPhone',
    filterType: 'Input',
  },
  {
    valueLabels: {
      label: 'SMS enabled (Alt Phone)',
      notLabel: 'SMS disabled (Alt Phone)',
    },
    label: `${AED_ATTRIBUTE_LABELS.get('coordinatorAltPhoneTextEnabled')}`,
    key: 'coordinatorAltPhoneTextEnabled',
    filterType: 'Boolean',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('accessibility')}`,
    key: 'accessibility',
    filterType: 'Select',
  },
  {
    valueLabels: {
      label: 'Is Mobile',
      notLabel: 'Is not Mobile',
    },
    label: `${AED_ATTRIBUTE_LABELS.get('isMobile')}`,
    key: 'isMobile',
    filterType: 'Boolean',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('manufacturer')}`,
    key: 'manufacturer',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('source')}`,
    key: 'source',
    filterType: 'Select',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('model')}`,
    key: 'model',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('assetID')}`,
    key: 'assetID',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('serialNumber')}`,
    key: 'serialNumber',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('installDate')}`,
    key: 'installDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('batteryExpDate')}`,
    key: 'batteryExpDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('electrodeExpDate')}`,
    key: 'electrodeExpDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('pedElectrodeExpDate')}`,
    key: 'pedElectrodeExpDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('medicalDirection')}`,
    key: 'medicalDirection',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('hasBleedingControlKit')}`,
    key: 'hasBleedingControlKit',
    filterType: 'Boolean',
    valueLabels: {
      label: 'Has Bleeding Control Kit',
      notLabel: "Doesn't have Bleeding Control Kit",
    },
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('bleedingControlKitExpDate')}`,
    key: 'bleedingControlKitExpDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('hasEpinephrine')}`,
    key: 'hasEpinephrine',
    filterType: 'Boolean',
    valueLabels: {
      label: 'Has Epinephrine',
      notLabel: "Doesn't have Epinephrine",
    },
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('epinephrineExpDate')}`,
    key: 'epinephrineExpDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('hasNaloxone')}`,
    key: 'hasNaloxone',
    filterType: 'Boolean',
    valueLabels: {
      label: 'Has Naloxone',
      notLabel: "Doesn't have Naloxone",
    },
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('naloxoneExpDate')}`,
    key: 'naloxoneExpDate',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('businessPhone')}`,
    key: 'businessPhone',
    filterType: 'Input',
  },
  {
    valueLabels: {
      label: 'Has an Image',
      notLabel: 'No Image',
    },
    label: 'Image',
    key: 'hasImage',
    filterType: 'Boolean',
  },
  {
    valueLabels: {
      label: 'Has an Approved Image',
      notLabel: 'Image needs approval',
    },
    label: 'Approved Image',
    key: 'hasApprovedImage',
    filterType: 'Boolean',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('status')}`,
    key: 'status',
    filterType: 'Select',
  },
  {
    valueLabels: {
      label: 'Is not Visible',
      notLabel: 'Is Visible',
    },
    label: 'Visibility',
    key: 'isHidden',
    filterType: 'Boolean',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('agencyID')}`,
    key: 'agencyID',
    filterType: 'Select',
  },
  {
    valueLabels: {
      label: 'Has Reported Issues',
      notLabel: "Doesn't have Reported Issues",
    },
    label: 'Reported Issues',
    key: 'hasReportedIssues',
    filterType: 'Boolean',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('lastReviewed')}`,
    key: 'lastReviewed',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('comment')}`,
    key: 'comment',
    filterType: 'Input',
  },
  {
    label: 'AED Added by Email',
    key: 'userEmail',
    filterType: 'Input',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('created')}`,
    key: 'created',
    filterType: 'Date',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('googlePlaceID')}`,
    key: 'googlePlaceID',
    filterType: 'Input',
  },
  {
    label: 'Expired',
    key: 'expired',
    filterType: 'Derived',
  },
  {
    label: 'Colocated Resource',
    key: 'colocated',
    filterType: 'Derived',
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('alwaysAvailable')}`,
    key: 'alwaysAvailable',
    filterType: 'Boolean',
    valueLabels: {
      label: 'Always Available 24/7',
      notLabel: 'Not Always Available',
    },
  },
  {
    label: `${AED_ATTRIBUTE_LABELS.get('activeSubscribers')}`,
    key: 'activeSubscribers',
    filterType: 'Boolean',
    valueLabels: {
      label: 'Has Subscribers',
      notLabel: 'Has No Subscribers',
    },
  },
]

export default AED
