import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import {
  tableIcon,
  xSmallIcon,
  locationIcon,
  downloadIcon,
} from '@workday/canvas-system-icons-web'
import { SystemIcon } from '@workday/canvas-kit-react-icon'
import { SegmentedControl } from '@workday/canvas-kit-react-segmented-control'
import { IconButton, IconButtonVariant } from '@workday/canvas-kit-react-button'
import colors from '@workday/canvas-colors-web'
import { Tooltip } from '@workday/canvas-kit-react-tooltip'

import AED, { AED_ATTRIBUTE_DEFINITIONS, AED_Attribute } from 'models/aed'
import USER from 'models/user'
import { StyledCard, CardHeading } from 'common/styles'
import {
  getDerivedFilterLabel,
  getDateFilterLabel,
  downloadCSV,
} from 'common/utils'

import EmptyResultsTable from './emptyResultsTable'
import ResultsTable from './resultsTable'
import ResultsMap from './resultsMap'

const ResultsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Heading = styled.div`
  flex: 1;
`
const ControlsList = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    align-items: flex-start;
  }
`
const DownloadButton = styled(IconButton)`
  margin-right: 10px;
`
const FilterList = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.frenchVanilla600};

  @media (max-width: 600px) {
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }
`
const Filter = styled.div`
  padding: 5px 5px 5px 10px;
  height: 20px;
  border-radius: 5px;
  margin-left: 5px;
  color: ${colors.blackPepper500};
  background: ${colors.sourLemon400};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${colors.sourLemon500};
  }

  @media (max-width: 600px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 165px;
    display: block;
    padding-right: 20px;
    position: relative;
    margin-bottom: 5px;

    span {
      position: absolute;
      right: 0;
      top: 3px;
    }
  }
`

export interface ResultsProps {
  user: USER
  aeds: AED[]
  agencies: Map<string, string>
  initialPageNumber: number
  onSelectedAED: (aed: AED, pageNumber?: number) => void
  filterQuery: Partial<AED>
  updateQuery: (updatedQuery: Partial<AED>) => void
}

const Results = ({
  user,
  aeds,
  agencies,
  initialPageNumber,
  filterQuery,
  updateQuery,
  onSelectedAED,
}: ResultsProps) => {
  const [showTable, setShowTable] = React.useState<boolean>(true)

  return (
    <StyledCard>
      <CardHeading>
        <ResultsHeader>
          {Object.keys(filterQuery).length > 0 && (
            <Heading>
              Agency AEDs <i>({aeds.length})</i>
              <FilterList>
                Filters:
                {Object.keys(filterQuery).length <= 0 ? (
                  <i>&nbsp;No Filters Selected</i>
                ) : (
                  Object.keys(filterQuery).map((key) => {
                    let label = filterQuery[key as AED_Attribute]

                    const filter = AED_ATTRIBUTE_DEFINITIONS.find(
                      (filter) => filter.key === key,
                    )

                    switch (filter?.filterType) {
                      case 'Select':
                        if (filter.label === 'Agency') {
                          label = agencies.get(label as string)
                        } else if (label === '') {
                          'aeiouAEIOU'.indexOf(label.charAt(0)) > -1
                            ? (label = "Doesn't have an " + filter.label)
                            : (label = "Doesn't have a " + filter.label)
                        }
                        break
                      case 'MultiSelect':
                        if (label) {
                          label = filter?.label
                        } else {
                          return null
                        }
                        break
                      case 'Boolean':
                        label = label
                          ? filter.valueLabels.label
                          : filter.valueLabels.notLabel
                        break
                      case 'Input':
                        label =
                          label === ''
                            ? 'aeiouAEIOU'.indexOf(filter.label.charAt(0)) > -1
                              ? "Doesn't have an " + filter.label
                              : "Doesn't have a " + filter.label
                            : filter.label + " contains '" + label + "'"
                        break
                      case 'Date':
                        label = getDateFilterLabel(
                          filter.label,
                          label as string,
                        )
                        break
                      case 'Derived':
                        label = getDerivedFilterLabel(filter.key, label)
                        break
                    }

                    return (
                      <Filter
                        key={`Filter-${key}-${
                          filterQuery[key as AED_Attribute]
                        }`}
                        onClick={() => {
                          delete filterQuery[key as AED_Attribute]
                          updateQuery({
                            ...filterQuery,
                          })
                        }}
                      >
                        {label}
                        <SystemIcon
                          icon={xSmallIcon}
                          fill={colors.blackPepper500}
                          css={css`
                            margin-left: 5px;
                          `}
                        />
                      </Filter>
                    )
                  })
                )}
              </FilterList>
            </Heading>
          )}
          <ControlsList>
            <Tooltip title="Download CSV">
              <DownloadButton
                aria-label="Download CSV"
                variant={IconButtonVariant.Square}
                icon={downloadIcon}
                onClick={() => downloadCSV(aeds)}
              />
            </Tooltip>
            <SegmentedControl
              value={showTable ? 'table-view' : 'map-view'}
              onChange={(value: string | number) => {
                setShowTable(value === 'table-view')
              }}
            >
              <IconButton
                icon={tableIcon}
                aria-label="Table View"
                size={IconButton.Size.Small}
                value="table-view"
              />
              <IconButton
                icon={locationIcon}
                size={IconButton.Size.Small}
                aria-label="Map view"
                value="map-view"
              />
            </SegmentedControl>
          </ControlsList>
        </ResultsHeader>
        {showTable ? (
          Object.keys(filterQuery).length <= 0 ? (
            <EmptyResultsTable aeds={aeds} updateQuery={updateQuery} />
          ) : (
            <ResultsTable
              initialPageNumber={initialPageNumber}
              onSelectedAED={onSelectedAED}
              user={user}
              aeds={aeds}
            />
          )
        ) : (
          <ResultsMap
            onSelectedAED={onSelectedAED}
            filteredAgency={filterQuery.agencyID}
            aeds={aeds}
            user={user}
          />
        )}
      </CardHeading>
    </StyledCard>
  )
}

export default Results
