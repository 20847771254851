const LOCAL_STORAGE_TOKEN_KEY = '__pulsepoint_aed_token__'
const LOCAL_STORAGE_USERID_KEY = '__pulsepoint_aed_user__'
const LOCAL_STORAGE_AGENCY_KEY = '__pulsepoint_aed_agency__'
const LOCAL_STORAGE_USERNAME_KEY = '__pulsepoint_aed_user_name__'

export type LocalStorageUserInfo = {
  token: string
  agencies: string
  userID: string
  username: string
}

export const removeLoginInfo = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
  window.localStorage.removeItem(LOCAL_STORAGE_AGENCY_KEY)
  window.localStorage.removeItem(LOCAL_STORAGE_USERID_KEY)
  window.localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY)
}

export const rememberLoginInfo = ({
  token,
  agencies,
  userID,
  username,
}: LocalStorageUserInfo) => {
  window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
  window.localStorage.setItem(LOCAL_STORAGE_AGENCY_KEY, agencies)
  window.localStorage.setItem(LOCAL_STORAGE_USERID_KEY, userID)
  window.localStorage.setItem(LOCAL_STORAGE_USERNAME_KEY, username)
}

export const getLocallyStoredUser = (): LocalStorageUserInfo => {
  return {
    token: window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '',
    agencies: window.localStorage.getItem(LOCAL_STORAGE_AGENCY_KEY) || '',
    userID: window.localStorage.getItem(LOCAL_STORAGE_USERID_KEY) || '',
    username: window.localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY) || '',
  }
}
