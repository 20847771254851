import React from 'react'
import styled from '@emotion/styled'

import colors from '@workday/canvas-colors-web'
import { SystemIcon } from '@workday/canvas-kit-react-icon'
import {
  chevronRightSmallIcon,
  chevronLeftSmallIcon,
} from '@workday/canvas-system-icons-web'

import { ITEMS_TO_SHOW_PER_PAGE } from './resultsTable'

const NUM_OF_PAGES_TO_SHOW = 20
const NUM_OF_PAGES_TO_PREVIEW = 3

const PagerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`
const PagesContainer = styled.div`
  flex: 1;
  text-align: center;
`
const PageLink = styled.span<{ selected: boolean }>`
  color: ${colors.blueberry500};
  cursor: ${(props) => (props.selected ? 'unset' : 'pointer')};
  padding: 0px 5px;
  font-weight: ${(props) => (props.selected ? '700' : 'normal')};

  :hover {
    font-weight: 700;
  }
`
const PrevNextLink = styled.span<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.disabled ? colors.blueberry200 : colors.blueberry500};
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  margin: 0px 5px;
  font-weight: normal;
`

export interface ResultsPagerProps {
  aedCount: number
  selectedPage: number
  onPageSelected: (selectedPage: number) => void
}

const ResultsPager = ({
  aedCount,
  selectedPage,
  onPageSelected,
}: ResultsPagerProps) => {
  const numberOfPages: number = Math.ceil(aedCount / ITEMS_TO_SHOW_PER_PAGE)

  return (
    <PagerContainer>
      {selectedPage > 0 ? (
        <PrevNextLink
          disabled={false}
          onClick={() => onPageSelected(selectedPage - 1)}
        >
          <SystemIcon
            size={20}
            icon={chevronLeftSmallIcon}
            color={colors.blueberry500}
          />
          Prev
        </PrevNextLink>
      ) : (
        <PrevNextLink disabled={true}>
          <SystemIcon
            size={20}
            icon={chevronLeftSmallIcon}
            color={colors.blueberry200}
          />
          Prev
        </PrevNextLink>
      )}
      <PagesContainer>
        {Array.from(Array(numberOfPages), (_, i) => {
          const isFirstPage = i === 0
          const isSecondPage = i === 1
          const isLastPage = numberOfPages - i === 1
          const isSecondToLastPage = numberOfPages - i === 2

          if (numberOfPages < NUM_OF_PAGES_TO_SHOW) {
            return (
              <PageLink
                key={`page-${i}`}
                selected={i === selectedPage}
                onClick={() => onPageSelected(i)}
              >
                {i + 1}
              </PageLink>
            )
          } else {
            if (
              isFirstPage ||
              isLastPage ||
              (selectedPage + NUM_OF_PAGES_TO_PREVIEW < NUM_OF_PAGES_TO_SHOW
                ? i < NUM_OF_PAGES_TO_SHOW
                : i >
                    selectedPage -
                      (NUM_OF_PAGES_TO_SHOW - NUM_OF_PAGES_TO_PREVIEW) &&
                  i < selectedPage + NUM_OF_PAGES_TO_PREVIEW)
            ) {
              return (
                <PageLink
                  key={`page-${i}`}
                  selected={i === selectedPage}
                  onClick={() => onPageSelected(i)}
                >
                  {i + 1}
                </PageLink>
              )
            } else if (isSecondPage || isSecondToLastPage) {
              return <React.Fragment key={`page-${i}`}>...</React.Fragment>
            } else {
              return <React.Fragment key={`page-${i}`} />
            }
          }
        })}
      </PagesContainer>
      {selectedPage + 1 < numberOfPages ? (
        <PrevNextLink
          disabled={false}
          onClick={() => onPageSelected(selectedPage + 1)}
        >
          Next
          <SystemIcon
            size={20}
            icon={chevronRightSmallIcon}
            color={colors.blueberry500}
            colorHover={colors.blueberry500}
          />
        </PrevNextLink>
      ) : (
        <PrevNextLink disabled={true}>
          Next
          <SystemIcon
            size={20}
            icon={chevronRightSmallIcon}
            color={colors.blueberry200}
            colorHover={colors.blueberry200}
          />
        </PrevNextLink>
      )}
    </PagerContainer>
  )
}

export default ResultsPager
