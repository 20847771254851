import React from 'react'
import styled from '@emotion/styled'

import { Modal, ModalWidth } from '@workday/canvas-kit-react-modal'

import { getAEDImageURL } from '../../../api/requests'
import AED from '../../../models/aed'
import USER from '../../../models/user'

const LargeImage = styled.div<{ imgPath: string }>`
  margin: 0px auto;
  width: 750px;
  height: 500px;
  background: no-repeat center center url('${(props) => props.imgPath}');
  background-size: contain;
`

export interface AEDLargeImageModalProps {
  aed: AED
  user: USER
  open: boolean
  onClose: () => void
}

const AEDLargeImageModal = ({
  aed,
  user,
  open,
  onClose,
}: AEDLargeImageModalProps) => {
  return (
    <Modal
      width={ModalWidth.m}
      heading={aed.name}
      open={open}
      handleClose={onClose}
    >
      <LargeImage imgPath={getAEDImageURL(user, aed.imagePath)} />
    </Modal>
  )
}

export default AEDLargeImageModal
