import React from 'react'

import Checkbox from '@workday/canvas-kit-react-checkbox'

import { FiltersProps } from '../filters'

import { StyledCard, CardHeading } from 'common/styles'
import { isExpiring } from 'common/utils'

const AED_EXPIRED_OPTIONS: string[] = [
  'Electrode',
  'Pediatric Electrode',
  'Battery',
  'Bleeding Control Kit',
  'Epinephrine',
  'Naloxone',
]
export type AED_EXPIRED = typeof AED_EXPIRED_OPTIONS[number]

const AEDExpiredFilter = ({ aeds, filterQuery, updateQuery }: FiltersProps) => {
  return (
    <StyledCard>
      <CardHeading>Expired (or expiring soon)</CardHeading>
      {AED_EXPIRED_OPTIONS.map((name: string, index: number) => {
        if (name === 'Epinephrine') {
          const count = `(${
            aeds.filter((aed) => isExpiring(aed.epinephrineExpDate)).length
          })`

          return (
            <Checkbox
              id={`AEDExpired-${index}`}
              key={`AEDExpired-${index}`}
              checked={
                filterQuery.expired !== undefined &&
                filterQuery.expired.indexOf('Epinephrine,') > -1
              }
              label={`${name} ${count}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (filterQuery.expired === undefined) {
                    updateQuery({
                      ...filterQuery,
                      expired: 'Epinephrine,',
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: `${filterQuery.expired}Epinephrine,`,
                    })
                  }
                } else {
                  if (filterQuery.expired === 'Epinephrine,') {
                    delete filterQuery.expired
                    updateQuery({
                      ...filterQuery,
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: filterQuery.expired!.replaceAll(
                        'Epinephrine,',
                        '',
                      ),
                    })
                  }
                }
              }}
            />
          )
        } else if (name === 'Bleeding Control Kit') {
          const count = `(${
            aeds.filter((aed) => isExpiring(aed.bleedingControlKitExpDate))
              .length
          })`

          return (
            <Checkbox
              id={`AEDExpired-${index}`}
              key={`AEDExpired-${index}`}
              checked={
                filterQuery.expired !== undefined &&
                filterQuery.expired.indexOf('Bleeding Control Kit,') > -1
              }
              label={`${name} ${count}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (filterQuery.expired === undefined) {
                    updateQuery({
                      ...filterQuery,
                      expired: 'Bleeding Control Kit,',
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: `${filterQuery.expired}Bleeding Control Kit,`,
                    })
                  }
                } else {
                  if (filterQuery.expired === 'Bleeding Control Kit,') {
                    delete filterQuery.expired
                    updateQuery({
                      ...filterQuery,
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: filterQuery.expired!.replaceAll(
                        'Bleeding Control Kit,',
                        '',
                      ),
                    })
                  }
                }
              }}
            />
          )
        } else if (name === 'Naloxone') {
          const count = `(${
            aeds.filter((aed) => isExpiring(aed.naloxoneExpDate)).length
          })`

          return (
            <Checkbox
              id={`AEDExpired-${index}`}
              key={`AEDExpired-${index}`}
              checked={
                filterQuery.expired !== undefined &&
                filterQuery.expired.indexOf('Naloxone,') > -1
              }
              label={`${name} ${count}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (filterQuery.expired === undefined) {
                    updateQuery({
                      ...filterQuery,
                      expired: 'Naloxone,',
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: `${filterQuery.expired}Naloxone,`,
                    })
                  }
                } else {
                  if (filterQuery.expired === 'Naloxone,') {
                    delete filterQuery.expired
                    updateQuery({
                      ...filterQuery,
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: filterQuery.expired!.replaceAll('Naloxone,', ''),
                    })
                  }
                }
              }}
            />
          )
        } else if (name === 'Battery') {
          const count = `(${
            aeds.filter((aed) => isExpiring(aed.batteryExpDate)).length
          })`

          return (
            <Checkbox
              id={`AEDExpired-${index}`}
              key={`AEDExpired-${index}`}
              checked={
                filterQuery.expired !== undefined &&
                filterQuery.expired.indexOf('Battery,') > -1
              }
              label={`${name} ${count}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (filterQuery.expired === undefined) {
                    updateQuery({
                      ...filterQuery,
                      expired: 'Battery,',
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: `${filterQuery.expired}Battery,`,
                    })
                  }
                } else {
                  if (filterQuery.expired === 'Battery,') {
                    delete filterQuery.expired
                    updateQuery({
                      ...filterQuery,
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: filterQuery.expired!.replaceAll('Battery,', ''),
                    })
                  }
                }
              }}
            />
          )
        } else if (name === 'Pediatric Electrode') {
          const count = `(${
            aeds.filter((aed) => isExpiring(aed.pedElectrodeExpDate)).length
          })`

          return (
            <Checkbox
              id={`AEDExpired-${index}`}
              key={`AEDExpired-${index}`}
              checked={
                filterQuery.expired !== undefined &&
                filterQuery.expired.indexOf('Electrode (Pediatric),') > -1
              }
              label={`${name} ${count}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (filterQuery.expired === undefined) {
                    updateQuery({
                      ...filterQuery,
                      expired: 'Electrode (Pediatric),',
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: `${filterQuery.expired}Electrode (Pediatric),`,
                    })
                  }
                } else {
                  if (filterQuery.expired === 'Electrode (Pediatric),') {
                    delete filterQuery.expired
                    updateQuery({
                      ...filterQuery,
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: filterQuery.expired!.replaceAll(
                        'Electrode (Pediatric),',
                        '',
                      ),
                    })
                  }
                }
              }}
            />
          )
        } else if (name === 'Electrode') {
          const count = `(${
            aeds.filter((aed) => isExpiring(aed.electrodeExpDate)).length
          })`

          return (
            <Checkbox
              id={`AEDExpired-${index}`}
              key={`AEDExpired-${index}`}
              checked={
                filterQuery.expired !== undefined &&
                filterQuery.expired.indexOf('Electrode,') > -1
              }
              label={`${name} ${count}`}
              onChange={(e) => {
                if (e.target.checked) {
                  if (filterQuery.expired === undefined) {
                    updateQuery({
                      ...filterQuery,
                      expired: 'Electrode,',
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: `${filterQuery.expired}Electrode,`,
                    })
                  }
                } else {
                  if (filterQuery.expired === 'Electrode,') {
                    delete filterQuery.expired
                    updateQuery({
                      ...filterQuery,
                    })
                  } else {
                    updateQuery({
                      ...filterQuery,
                      expired: filterQuery.expired!.replaceAll(
                        'Electrode,',
                        '',
                      ),
                    })
                  }
                }
              }}
            />
          )
        } else {
          return null
        }
      })}
    </StyledCard>
  )
}

export default AEDExpiredFilter
