import React from 'react'

import { Radio } from '@workday/canvas-kit-react-radio'

import { FiltersProps } from '../filters'

import {
  StyledCard,
  CardHeading,
  ClearButton,
  StyledRadioGroup,
} from 'common/styles'

const AEDImageFilter = ({ aeds, filterQuery, updateQuery }: FiltersProps) => {
  const noImageCount =
    (filterQuery.hasImage === undefined &&
      filterQuery.hasApprovedImage === undefined) ||
    filterQuery.hasImage === false
      ? `(${aeds.filter((aed) => aed.hasImage === false).length})`
      : ''
  const needsApprovalCount =
    (filterQuery.hasImage === undefined &&
      filterQuery.hasApprovedImage === undefined) ||
    filterQuery.hasApprovedImage === false
      ? `(${aeds.filter((aed) => aed.hasApprovedImage === false).length})`
      : ''
  const selectedValue =
    filterQuery.hasApprovedImage === false
      ? 'Image Needs Approval'
      : filterQuery.hasImage === false
      ? 'Has no Image'
      : ''

  return (
    <StyledCard>
      <CardHeading>Image</CardHeading>
      {(filterQuery.hasApprovedImage === false ||
        filterQuery.hasImage === false) && (
        <ClearButton
          onClick={() => {
            delete filterQuery.hasApprovedImage
            delete filterQuery.hasImage
            updateQuery({
              ...filterQuery,
            })
          }}
        >
          Clear
        </ClearButton>
      )}
      <StyledRadioGroup
        name="aedImage"
        value={selectedValue}
        onChange={(value) => {
          switch (value) {
            case 'Has no Image':
              delete filterQuery.hasApprovedImage
              updateQuery({
                ...filterQuery,
                hasImage: false,
              })
              break
            case 'Image Needs Approval':
              delete filterQuery.hasImage
              updateQuery({
                ...filterQuery,
                hasApprovedImage: false,
              })
              break
            default:
              break
          }
        }}
      >
        <Radio value={'Has no Image'} label={`No image ${noImageCount}`} />
        <Radio
          value={'Image Needs Approval'}
          label={`Image needs approval ${needsApprovalCount}`}
        />
      </StyledRadioGroup>
    </StyledCard>
  )
}

export default AEDImageFilter
