import styled from '@emotion/styled'

import { KeyboardDatePicker } from '@material-ui/pickers'

import Button from '@workday/canvas-kit-react-button'
import Card from '@workday/canvas-kit-react-card'
import colors from '@workday/canvas-colors-web'
import Layout from '@workday/canvas-kit-react-layout'
import { RadioGroup } from '@workday/canvas-kit-react-radio'

export const StyledCard = styled(Card)`
  margin: 0px 12px 20px 12px;
  padding: 15px;
  position: relative;
`

export const CardHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.blackPepper500};
  margin-bottom: 10px;
`

export const ClearButton = styled.a`
  position: absolute;
  top: 16px;
  right: 15px;
  font-size: 12px;
  color: ${colors.blueberry500};
  cursor: pointer;
`

export const StyledButton = styled(Button)`
  color: ${colors.blackPepper500};
  margin-right: 10px;

  &:focus {
    color: ${colors.blackPepper500} !important;
  }
  &:disabled {
    color: ${colors.blackPepper300} !important;
  }
`

export const DatePickerInput = styled(KeyboardDatePicker)`
  width: 100%;
  & fieldset {
    border-color: ${colors.licorice200};
  }

  & input {
    height: 40px;
    padding: 0px;
    padding-left: 8px;
  }
`

export const LayoutContainer = styled(Layout)`
  @media (max-width: 600px) {
    display: block;

    >div {
      padding: 0px;
    }
  }
`

export const StyledRadioGroup = styled(RadioGroup)`
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 190px;
  }
`