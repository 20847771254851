import React from 'react'
import styled from '@emotion/styled'

import colors from '@workday/canvas-colors-web'
import Button, { ButtonVariant } from '@workday/canvas-kit-react-button'

import AED from 'models/aed'

import { isExpiring, isGreaterThanDays } from 'common/utils'

const EmptyContainer = styled.div`
  color: ${colors.blackPepper100};
  min-height: 400px;
  text-align: center;
`

const BeginLogo = styled.div`
  height: 300px;
  background: center center url('/hello.jpg') no-repeat;
  background-size: contain;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    height: 200px;
    margin-bottom: 20px;
  }
`

const AllDoneLogo = styled.div`
  height: 400px;
  background: center center url('/all-done.png') no-repeat;
  background-size: contain;
  margin: 100px 0px;
`

const Heading = styled.div`
  color: ${colors.blackPepper300};
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`

const SubHeading = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 18px;
  }
`
const ButtonContainer = styled.div`
  max-width: 900px;
  margin: 50px auto;

  @media (max-width: 600px) {
    margin: 25px auto;
  }
`

const StyledButton = styled(Button)`
  margin: 10px;
  color: ${colors.blackPepper500};
  width: 260px;

  &:disabled {
    color: ${colors.blackPepper200};
  }

  &:focus {
    color: ${colors.blackPepper500} !important;
  }
`

export interface EmptyResultsTableProps {
  aeds: AED[]
  updateQuery: (updatedQuery: Partial<AED>) => void
}

const EmptyResultsTable = ({ aeds, updateQuery }: EmptyResultsTableProps) => {
  const pendingCount = aeds.filter((aed) => aed.status === 'Pending').length
  const expiringCount = aeds.filter(
    (aed) =>
      isExpiring(aed.bleedingControlKitExpDate) ||
      isExpiring(aed.epinephrineExpDate) ||
      isExpiring(aed.naloxoneExpDate) ||
      isExpiring(aed.electrodeExpDate) ||
      isExpiring(aed.batteryExpDate) ||
      isExpiring(aed.pedElectrodeExpDate),
  ).length
  const siteCoordinatorEmailCount = aeds.filter(
    (aed) => aed.siteCoordinatorEmail === '' && aed.status === 'Approved',
  ).length
  const reportedIssuesCount = aeds.filter((aed) => aed.hasReportedIssues).length
  const missingImageCount = aeds.filter(
    (aed) => !aed.hasImage && aed.status === 'Approved',
  ).length
  const pendingImageCount = aeds.filter(
    (aed) => aed.hasImage && !aed.hasApprovedImage && aed.status === 'Approved',
  ).length
  const needsReviewCount = aeds.filter((aed) =>
    isGreaterThanDays(aed.lastReviewed, 365),
  ).length
  const addressMissingCount = aeds.filter((aed) => aed.address === '').length
  const manufacturerMissingCount = aeds.filter((aed) => aed.manufacturer === '')
    .length
  const placetypeMissingCount = aeds.filter((aed) => aed.type === 'Unknown')
    .length
  const googlePlaceIDMissingCount = aeds.filter(
    (aed) => aed.googlePlaceID === '',
  ).length

  return (
    <EmptyContainer>
      {pendingCount > 0 ||
        expiringCount > 0 ||
        siteCoordinatorEmailCount > 0 ||
        reportedIssuesCount > 0 ||
        missingImageCount > 0 ||
        pendingImageCount > 0 ||
        needsReviewCount > 0 ||
        addressMissingCount > 0 ||
        manufacturerMissingCount > 0 ||
        placetypeMissingCount > 0 ? (
        <>
          <BeginLogo />
          <Heading>You are currently managing {aeds.length} AEDs</Heading>
          <SubHeading>
            {window.innerWidth <= 600
              ? 'Select an issue below or rotate your device for additional filters.'
              : 'Select an issue below or use the filters to create a custom registry view.'}
          </SubHeading>
        </>
      ) : (
        <AllDoneLogo />
      )}
      <ButtonContainer>
        {pendingCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                status: 'Pending',
              })
            }
          >
            Pending Approval ({pendingCount})
          </StyledButton>
        )}
        {expiringCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                expired:
                  'Bleeding Control Kit,Epinephrine,Naloxone,Electrode,Electrode (Pediatric),Battery,',
              })
            }
          >
            Expired/Expiring Soon ({expiringCount})
          </StyledButton>
        )}
        {siteCoordinatorEmailCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                siteCoordinatorEmail: '',
                status: 'Approved',
              })
            }
          >
            No Responsible Party ({siteCoordinatorEmailCount})
          </StyledButton>
        )}
        {reportedIssuesCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                hasReportedIssues: true,
              })
            }
          >
            Has Reported Issues ({reportedIssuesCount})
          </StyledButton>
        )}
        {missingImageCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                status: 'Approved',
                hasImage: false,
              })
            }
          >
            Missing an Image ({missingImageCount})
          </StyledButton>
        )}
        {pendingImageCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                status: 'Approved',
                hasImage: true,
                hasApprovedImage: false,
              })
            }
          >
            Pending Image Approval ({pendingImageCount})
          </StyledButton>
        )}
        {needsReviewCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                lastReviewed: '>365',
              })
            }
          >
            Needs Annual Review ({needsReviewCount})
          </StyledButton>
        )}
        {addressMissingCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                address: '',
              })
            }
          >
            Missing an Address ({addressMissingCount})
          </StyledButton>
        )}
        {manufacturerMissingCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                manufacturer: '',
              })
            }
          >
            Missing Device Info ({manufacturerMissingCount})
          </StyledButton>
        )}
        {placetypeMissingCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                type: 'Unknown',
              })
            }
          >
            Missing a Place Type ({placetypeMissingCount})
          </StyledButton>
        )}
        {googlePlaceIDMissingCount > 0 && (
          <StyledButton
            variant={ButtonVariant.Primary}
            onClick={() =>
              updateQuery({
                googlePlaceID: '',
              })
            }
          >
            Missing a Place ID ({googlePlaceIDMissingCount})
          </StyledButton>
        )}
      </ButtonContainer>
    </EmptyContainer>
  )
}

export default EmptyResultsTable
