import React from 'react'
import styled from '@emotion/styled'

import { passwordIcon, plusIcon } from '@workday/canvas-system-icons-web'
import colors from '@workday/canvas-colors-web'
import { TextButton } from '@workday/canvas-kit-react-button'
import {
  CanvasProvider,
  PartialEmotionCanvasTheme,
} from '@workday/canvas-kit-react-common'

import { removeLoginInfo } from 'api/localStorage'
import { logout } from 'api/requests'
import USER from 'models/user'

const PulsePointNavigationTheme: PartialEmotionCanvasTheme = {
  canvas: {
    palette: {
      primary: {
        main: colors.blackPepper500,
        dark: colors.sourLemon600,
      },
    },
  },
}

const StyledTextButton = styled(TextButton)`
  margin-left: 10px;
`

const NavigationHeader = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.frenchVanilla100};
`

const Navigation = styled.div`
  margin: 0px 25px;
  text-align: end;

  @media (max-width: 600px) {
    margin-right: 10px;
    margin-left: 0px;
  }
`

const Username = styled.div`
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;

  @media (max-width: 600px) {
    display: none;
  }
`

const Logo = styled.div`
  flex: 1;
  height: 80px;

  img {
    height: 60px;
    padding: 15px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    img {
      height: 35px;
      margin-top: 20px;
      padding: 5px 0px 0px 10px;
    }
  }
`

export interface HeaderProps {
  user?: USER
}

const Header = ({ user }: HeaderProps) => {
  return (
    <NavigationHeader>
      <Logo onClick={() => (window.location.href = '')}>
        <img src="/logo.png" alt="PulsePoint Logo" />
      </Logo>
      <Navigation>
        {user !== undefined && (
          <CanvasProvider theme={PulsePointNavigationTheme}>
            <Username>{user.username}</Username>
            <StyledTextButton
              size={TextButton.Size.Small}
              icon={plusIcon}
              onClick={() => {
                window.location.hash = 'add'
              }}
            >
              Add AED
            </StyledTextButton>
            <StyledTextButton
              size={TextButton.Size.Small}
              icon={passwordIcon}
              onClick={() => {
                logout(user, (success) => {
                  if (success) {
                    removeLoginInfo()
                    window.location.assign('/')
                  } else {
                    // toast
                  }
                })
              }}
            >
              Logout
            </StyledTextButton>
          </CanvasProvider>
        )}
      </Navigation>
    </NavigationHeader>
  )
}

export default Header
