import { imagePathIsLocal, removeLocalPath } from 'common/utils'
import AED from 'models/aed'
import { PLACE_TYPES_OPTIONS } from 'models/placeType'

export type AED_DTO = {
  AEDLocationName: string
  AEDLocationDescription: string
  AEDAddress: string
  AEDCityOrLocality: string
  AEDCounty: string
  AEDStateOrProvince: string
  AEDPostalCode: string
  AEDCountry: string
  AEDIsPrivate: boolean
  AEDImage: string
  AEDImageApproved: boolean
  AEDIsMobile: boolean
  AEDIsHidden: boolean
  AEDType: string
  AEDPlaceType: string
  HasBleedingControlKit: '1' | '0' | boolean
  HasEpinephrine: '1' | '0' | boolean
  HasNaloxone: '1' | '0' | boolean
  EpinephrineExpDate: string
  NaloxoneExpDate: string
  BleedingControlKitExpDate: string
  AEDPedElectrodeExpDate: string
  AEDBatteryExpDate: string
  AEDElectrodeExpDate: string
  LatitudeLongitude: string
  SiteCoordinator: string
  AEDLastUpdate: string
  AEDLastReviewed: string
  AgencyID?: string
  HasReportedIssues: boolean
  AEDManufacturer: string
  AEDModel: string
  UserID: string
  ID: string
  CoordinatorEmail: string
  UserEmail: string
  AEDCreated: string
  AEDInstallDate: string
  AEDSerialNumber: string
  CoordinatorPhone: string
  CoordinatorPhoneTextEnabled: '1' | '0' | boolean
  CoordinatorAltPhone: string
  CoordinatorAltPhoneTextEnabled: '1' | '0' | boolean
  LastInspectedDate: string
  MedicalDirection: string
  BusinessPhone: string
  AEDComment: string
  AEDAssetID: string
  GooglePlaceID: string
  AccessCode: string
  AEDAlwaysAvailable: boolean
  AEDSource: string
  ActiveSubscribers: string[]

  AEDLatitude?: number
  AEDLongitude?: number
  UpdateReviewed?: boolean
  AEDGooglePlaceID?: string
}

export const convertDTOToAED = (aed: AED_DTO): AED => {
  return {
    name: aed.AEDLocationName,
    description: aed.AEDLocationDescription,
    address: aed.AEDAddress,
    city: aed.AEDCityOrLocality.trim(),
    county: aed.AEDCounty.trim(),
    postal: aed.AEDPostalCode,
    state: aed.AEDStateOrProvince,
    country: aed.AEDCountry,
    accessibility: aed.AEDIsPrivate ? 'Private' : 'Public',
    hasImage: aed.AEDImage !== undefined && aed.AEDImage !== '',
    imagePath: aed.AEDImage,
    hasApprovedImage: aed.AEDImageApproved,
    isHidden: aed.AEDIsHidden,
    isMobile: aed.AEDIsMobile,
    status: aed.AEDType.trim() === 'Prod' ? 'Approved' : 'Pending',
    type:
      PLACE_TYPES_OPTIONS.find((place) => place === aed.AEDPlaceType) ||
      'Unknown',
    hasBleedingControlKit: aed.HasBleedingControlKit === true,
    hasEpinephrine: aed.HasEpinephrine === true,
    hasNaloxone: aed.HasNaloxone === true,
    epinephrineExpDate: aed.EpinephrineExpDate,
    bleedingControlKitExpDate: aed.BleedingControlKitExpDate,
    naloxoneExpDate: aed.NaloxoneExpDate,
    pedElectrodeExpDate: aed.AEDPedElectrodeExpDate,
    batteryExpDate: aed.AEDBatteryExpDate,
    electrodeExpDate: aed.AEDElectrodeExpDate,
    latitude:
      aed.LatitudeLongitude !== undefined
        ? parseFloat(aed.LatitudeLongitude.split(',')[0])
        : aed.AEDLatitude || 0,
    longitude:
      aed.LatitudeLongitude !== undefined
        ? parseFloat(aed.LatitudeLongitude.split(',')[1])
        : aed.AEDLongitude || 0,
    siteCoordinatorName: aed.SiteCoordinator,
    lastUpdated: aed.AEDLastUpdate,
    agencyID: aed.AgencyID || '',
    hasReportedIssues: aed.HasReportedIssues,
    lastReviewed: aed.AEDLastReviewed,
    model: aed.AEDModel,
    manufacturer: aed.AEDManufacturer,
    userID: aed.UserID,
    ID: aed.ID,
    userEmail: aed.UserEmail,
    siteCoordinatorEmail: aed.CoordinatorEmail,
    created: aed.AEDCreated,
    installDate: aed.AEDInstallDate,
    serialNumber: aed.AEDSerialNumber,
    coordinatorPhone: aed.CoordinatorPhone,
    coordinatorPhoneTextEnabled: aed.CoordinatorPhoneTextEnabled === '1',
    coordinatorAltPhone: aed.CoordinatorAltPhone,
    coordinatorAltPhoneTextEnabled: aed.CoordinatorAltPhoneTextEnabled === '1',
    lastInspectedDate: aed.LastInspectedDate,
    medicalDirection: aed.MedicalDirection,
    businessPhone: aed.BusinessPhone,
    comment: aed.AEDComment,
    assetID: aed.AEDAssetID,
    googlePlaceID: aed.GooglePlaceID || '',
    accessCode: aed.AccessCode || '',
    alwaysAvailable: aed.AEDAlwaysAvailable,
    source: aed.AEDSource,
    activeSubscribers: aed.ActiveSubscribers,
  }
}

// aed/aed_create_modify uses true/false instead of 1/0
export const convertCreateEditDTOToAED = (aed: AED_DTO): AED => {
  const temp_aed = convertDTOToAED(aed)
  temp_aed.hasBleedingControlKit = aed.HasBleedingControlKit as boolean
  temp_aed.hasEpinephrine = aed.HasEpinephrine as boolean
  temp_aed.hasNaloxone = aed.HasNaloxone as boolean
  temp_aed.coordinatorPhoneTextEnabled = aed.CoordinatorPhoneTextEnabled as boolean
  temp_aed.coordinatorAltPhoneTextEnabled = aed.CoordinatorAltPhoneTextEnabled as boolean
  return temp_aed
}

export const convertAEDToDTO = (aed: AED): Partial<AED_DTO> => {
  const convertedAED: Partial<AED_DTO> = {
    AEDLocationName: aed.name,
    AEDLocationDescription: aed.description,
    AEDAddress: aed.address,
    AEDCityOrLocality: aed.city,
    AEDCounty: aed.county,
    AEDStateOrProvince: aed.state,
    AEDPostalCode: aed.postal,
    AEDCountry: aed.country,
    AEDIsPrivate: aed.accessibility === 'Private' ? true : false,
    AEDImageApproved: aed.hasApprovedImage,
    AEDIsMobile: aed.isMobile,
    AEDIsHidden: aed.isHidden,
    AEDType: aed.status === 'Approved' ? 'Prod' : 'Crowd',
    AEDPlaceType: aed.type === 'Unknown' ? '' : aed.type,
    HasBleedingControlKit: aed.hasBleedingControlKit ? '1' : '0',
    HasEpinephrine: aed.hasEpinephrine ? '1' : '0',
    HasNaloxone: aed.hasNaloxone ? '1' : '0',
    EpinephrineExpDate: aed.epinephrineExpDate,
    NaloxoneExpDate: aed.naloxoneExpDate,
    BleedingControlKitExpDate: aed.bleedingControlKitExpDate,
    AEDPedElectrodeExpDate: aed.pedElectrodeExpDate,
    AEDBatteryExpDate: aed.batteryExpDate,
    AEDElectrodeExpDate: aed.electrodeExpDate,
    LatitudeLongitude: aed.latitude + ',' + aed.longitude,
    AEDLatitude: aed.latitude,
    AEDLongitude: aed.longitude,
    SiteCoordinator: aed.siteCoordinatorName,
    AEDLastReviewed: aed.lastReviewed,
    AgencyID: aed.agencyID,
    HasReportedIssues: aed.hasReportedIssues,
    AEDManufacturer: aed.manufacturer,
    AEDModel: aed.model,
    CoordinatorEmail: aed.siteCoordinatorEmail,
    AEDInstallDate: aed.installDate,
    AEDSerialNumber: aed.serialNumber,
    CoordinatorPhone: aed.coordinatorPhone,
    CoordinatorPhoneTextEnabled: aed.coordinatorPhoneTextEnabled ? '1' : '0',
    CoordinatorAltPhone: aed.coordinatorAltPhone,
    CoordinatorAltPhoneTextEnabled: aed.coordinatorAltPhoneTextEnabled
      ? '1'
      : '0',
    LastInspectedDate: aed.lastInspectedDate,
    MedicalDirection: aed.medicalDirection,
    BusinessPhone: aed.businessPhone,
    AEDComment: aed.comment,
    AEDAssetID: aed.assetID,
    ID: aed.ID,
    AEDGooglePlaceID: aed.googlePlaceID,
    AccessCode: aed.accessCode,
    AEDAlwaysAvailable: aed.alwaysAvailable,
    AEDSource: aed.source,
    ActiveSubscribers: aed.activeSubscribers,
  }

  // if we altered agencyID since it was a dup of multiple agencies, dont save it.
  if (aed.agencyID === undefined || aed.agencyID === '' || aed.agencyID.indexOf(",") > -1) {
    delete convertedAED.AgencyID
  }

  return aed.imagePath !== undefined &&
    (imagePathIsLocal(aed.imagePath) || aed.imagePath === '')
    ? { ...convertedAED, AEDImage: removeLocalPath(aed.imagePath) }
    : convertedAED
}
