import AED, { AED_EXPORT, AED_EXPORT_HEADERS, AED_Attribute } from 'models/aed'

import { AED_EXPIRED } from 'app/browse/filters/aedExpiredFilter'

export const formatToDTODateString = (strDate: string): string => {
  if (strDate === '' || strDate === '0000-00-00') return '0000-00-00'
  if (/^\d{4}-\d{2}-\d{2}$/im.test(strDate)) return strDate

  return new Date(strDate).toISOString().slice(0, 10)
}

export const convertToDateInputValue = (date: string): Date | null => {
  if (date === '' || date === '0000-00-00') return null
  return new Date(new Date(date).toLocaleString('en-US', { timeZone: 'UTC' }))
}

export const isExpiring = (expDate: string) => {
  if (expDate === undefined || expDate === '') return false

  const expiring = new Date(expDate)
  expiring.setDate(expiring.getDate() - 90)

  return expiring <= new Date()
}

export const isGreaterThanDays = (
  dateAttribute: string,
  numberOfDays: number,
) => {
  if (dateAttribute === '') return true

  const date = new Date(dateAttribute)
  date.setDate(date.getDate() + numberOfDays)

  return date <= new Date()
}

export const getDisplayDate = (strDate: string): string => {
  const d = new Date(strDate)
  return (
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + d.getDate()).slice(-2) +
    '/' +
    d.getFullYear()
  )
}

export const getDerivedFilteredResult = (
  aed: AED,
  filterAttribute: AED_Attribute,
  filterValue?: string | boolean | number | any[],
) => {
  switch (filterAttribute) {
    case 'expired':
      if (filterValue === undefined) return false

      const expiredValues = (filterValue as string).split(',')
      return expiredValues.some((value) => {
        switch (value as AED_EXPIRED) {
          case 'Epinephrine':
            return isExpiring(aed.epinephrineExpDate)
          case 'Bleeding Control Kit':
            return isExpiring(aed.bleedingControlKitExpDate)
          case 'Naloxone':
            return isExpiring(aed.naloxoneExpDate)
          case 'Battery':
            return isExpiring(aed.batteryExpDate)
          case 'Electrode (Pediatric)':
            return isExpiring(aed.pedElectrodeExpDate)
          case 'Electrode':
            return isExpiring(aed.electrodeExpDate)
          default:
            return false
        }
      })
  }
}

export const getDateFilteredResult = (
  aed: AED,
  filterAttribute: AED_Attribute,
  expression: string,
) => {
  switch (expression.charAt(0) as '>' | '<') {
    case '>':
      return isGreaterThanDays(
        aed[filterAttribute] as string,
        parseInt(expression.substr(1)),
      )
  }
  return false
}

export const getDerivedFilterLabel = (
  key: AED_Attribute,
  value?: string | boolean | number | any[],
): string => {
  switch (key) {
    case 'expired':
      const values = (value as string).substring(
        0,
        (value as string).length - 1,
      )
      return values.indexOf(',') > -1
        ? 'Has expired consumables (or expiring soon)'
        : `Has expired ${values} (or expiring soon)`
    default:
      return ''
  }
}

export const getDateFilterLabel = (label: string, value: string) => {
  return value.charAt(0) === '>'
    ? label + ' more than ' + value.substr(1) + ' days ago'
    : label + ' less than ' + value.substr(1) + ' days ago'
}

export const getAEDIconWidth = (aed: AED): string => {
  return aed.hasReportedIssues
    ? '32px'
    : aed.hasBleedingControlKit || aed.hasEpinephrine || aed.hasNaloxone
      ? '39px'
      : '34px'
}

const buildIconName = (
  baseName: string,
  ext: string,
  forMap: boolean | undefined,
): string => {
  return `${baseName}${forMap ? '_map.' : '.'}${ext}`
}

export const getAEDIcon = (
  isProd: boolean,
  isPrivate: boolean,
  isColocated: boolean,
  hasReportedIssues: boolean,
  forMap?: boolean,
): string => {
  if (isProd) {
    if (isPrivate) {
      if (hasReportedIssues) {
        return buildIconName('private_issues', 'png', forMap)
      } else if (isColocated) {
        return buildIconName('private_plus', 'png', forMap)
      } else {
        return buildIconName('private_aed', 'png', forMap)
      }
    } else {
      if (hasReportedIssues) {
        return buildIconName('public_issues', 'png', forMap)
      } else if (isColocated) {
        return buildIconName('public_plus', 'png', forMap)
      } else {
        return buildIconName('public_aed', 'png', forMap)
      }
    }
  } else {
    if (isPrivate) {
      if (hasReportedIssues) {
        return buildIconName('private_issues_gray', 'png', forMap)
      } else if (isColocated) {
        return buildIconName('private_plus_gray', 'png', forMap)
      } else {
        return buildIconName('private_gray', 'png', forMap)
      }
    } else {
      if (hasReportedIssues) {
        return buildIconName('public_issues_gray', 'png', forMap)
      } else if (isColocated) {
        return buildIconName('public_plus_gray', 'png', forMap)
      } else {
        return buildIconName('public_gray', 'png', forMap)
      }
    }
  }
}

const convertToCSV = (objArray: string): string => {
  var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  var str = ''

  for (var i = 0; i < array.length; i++) {
    var line = ''
    for (var index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index]
    }

    str += line + '\r\n'
  }

  return str
}

const exportCSVFile = (headers: AED_EXPORT, aeds: AED_EXPORT[]) => {
  if (headers) {
    aeds.unshift(headers)
  }

  // Convert Object to JSON
  const jsonObject: string = JSON.stringify(aeds)

  var csv = convertToCSV(jsonObject)

  var exportedFilename = 'PulsePoint_AEDs.csv'

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  var link = document.createElement('a')
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', exportedFilename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const downloadCSV = (aeds: AED[]) => {
  const aed_exports: AED_EXPORT[] = []

  aeds.forEach((aed) => {
    aed_exports.push({
      agencyID: `"${aed.agencyID}"`,
      status: `"${aed.status}"`,
      accessibility: `"${aed.accessibility}"`,
      isMobile: aed.isMobile ? 'Yes' : 'No',
      isHidden: aed.isHidden ? 'Yes' : 'No',
      hasBleedingControlKit: aed.hasBleedingControlKit ? 'Yes' : 'No',
      hasNaloxone: aed.hasNaloxone ? 'Yes' : 'No',
      hasEpinephrine: aed.hasEpinephrine ? 'Yes' : 'No',
      name: `"${aed.name.replace(/[\r\n\u0085\u2028\u2029,]+/g, '')}"`,
      description: `"${aed.description.replace(
        /[\r\n\u0085\u2028\u2029,]+/g,
        '',
      )}"`,
      address: `"${aed.address}"`,
      city: `"${aed.city}"`,
      county: `"${aed.county}"`,
      state: `"${aed.state}"`,
      postal: `"${aed.postal}"`,
      country: `"${aed.country}"`,
      businessPhone: `"${aed.businessPhone}"`,
      latitude: aed.latitude,
      longitude: aed.longitude,
      type: `"${aed.type}"`,
      assetID: `"${aed.assetID}"`,
      manufacturer: `"${aed.manufacturer}"`,
      model: `"${aed.model}"`,
      serialNumber: `"${aed.serialNumber}"`,
      installDate: aed.installDate === '0000-00-00' ? '' : aed.installDate,
      batteryExpDate:
        aed.batteryExpDate === '0000-00-00' ? '' : aed.batteryExpDate,
      electrodeExpDate:
        aed.electrodeExpDate === '0000-00-00' ? '' : aed.electrodeExpDate,
      pedElectrodeExpDate:
        aed.pedElectrodeExpDate === '0000-00-00' ? '' : aed.pedElectrodeExpDate,
      naloxoneExpDate:
        aed.naloxoneExpDate === '0000-00-00' ? '' : aed.naloxoneExpDate,
      epinephrineExpDate:
        aed.epinephrineExpDate === '0000-00-00' ? '' : aed.epinephrineExpDate,
      bleedingControlKitExpDate:
        aed.bleedingControlKitExpDate === '0000-00-00'
          ? ''
          : aed.bleedingControlKitExpDate,
      siteCoordinatorName: `"${aed.siteCoordinatorName}"`,
      siteCoordinatorEmail: `"${aed.siteCoordinatorEmail}"`,
      coordinatorPhone: `"${aed.coordinatorPhone}"`,
      coordinatorPhoneTextEnabled: aed.coordinatorPhoneTextEnabled
        ? 'Yes'
        : 'No',
      coordinatorAltPhone: `"${aed.coordinatorAltPhone}"`,
      coordinatorAltPhoneTextEnabled: aed.coordinatorAltPhoneTextEnabled
        ? 'Yes'
        : 'No',
      medicalDirection: `"${aed.medicalDirection}"`,
      comment: `"${aed.comment.replace(/[\r\n\u0085\u2028\u2029,]+/g, '')}"`,
      hasImage: aed.hasImage ? 'Yes' : 'No',
      hasApprovedImage: aed.hasApprovedImage ? 'Yes' : 'No',
      userEmail: `"${aed.userEmail}"`,
      lastInspectedDate:
        aed.lastInspectedDate === '0000-00-00' ? '' : aed.lastInspectedDate,
      lastReviewed: aed.lastReviewed === '0000-00-00' ? '' : aed.lastReviewed,
      lastUpdated: aed.lastUpdated === '000-00-00' ? '' : aed.lastUpdated,
      created: aed.created === '000-00-00' ? '' : aed.created,
      aedID: aed.ID,
      accessCode: aed.accessCode,
      alwaysAvailable: aed.alwaysAvailable ? 'Yes' : 'No'
    })
  })

  exportCSVFile(AED_EXPORT_HEADERS as AED_EXPORT, aed_exports)
}

const IMAGE_TYPE_REGEX = 'data:image/(png|jpg|jpeg|bmp|webp|svg)+;base64'

export const imagePathIsLocal = (imagePath: string): boolean => {
  return new RegExp(IMAGE_TYPE_REGEX, 'im').test(imagePath)
}

export const removeLocalPath = (imagePath: string): string => {
  return imagePath.replaceAll(new RegExp(IMAGE_TYPE_REGEX, 'gim'), '')
}
