import React from 'react'

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { Layout } from '@workday/canvas-kit-react-layout'
import FormField from '@workday/canvas-kit-react-form-field'
import Checkbox from '@workday/canvas-kit-react-checkbox'

import AED, { AED_Attribute, AED_ATTRIBUTE_LABELS } from 'models/aed'
import {
  CardHeading,
  StyledCard,
  DatePickerInput,
  LayoutContainer,
} from 'common/styles'
import { convertToDateInputValue, isExpiring } from 'common/utils'

import { TempAEDDatesState } from './editAED'

export interface AEDColocatedItemsInformationProps {
  aed: AED
  aedDatesState: TempAEDDatesState
  onDateChange: (key: AED_Attribute, value: string) => void
  onFieldChange: (
    key: AED_Attribute,
    value: string | boolean,
    refreshState?: boolean,
  ) => void
}

const AEDColocatedItemsInformation = ({
  aed,
  aedDatesState,
  onDateChange,
  onFieldChange,
}: AEDColocatedItemsInformationProps) => {
  const [aedValues, setAEDValues] = React.useState<{
    hasBleedingControlKit: boolean
    hasEpinephrine: boolean
    hasNaloxone: boolean
  }>({
    hasBleedingControlKit: aed.hasBleedingControlKit,
    hasEpinephrine: aed.hasEpinephrine,
    hasNaloxone: aed.hasNaloxone,
  })

  return (
    <StyledCard>
      <CardHeading>Colocated Items</CardHeading>
      <LayoutContainer>
        <Layout.Column>
          <FormField className={`EditInput-hasBleedingControlKit`}>
            <Checkbox
              label={AED_ATTRIBUTE_LABELS.get('hasBleedingControlKit')}
              checked={aedValues.hasBleedingControlKit}
              onChange={(e) => {
                onFieldChange('hasBleedingControlKit', e.target.checked)
                setAEDValues({
                  ...aedValues,
                  hasBleedingControlKit: e.target.checked,
                })
                aedDatesState.bleedingControlKitExpDate = '0000-00-00'
              }}
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('bleedingControlKitExpDate')}
            className={`EditInput-bleedingControlKitExpDate${
              isExpiring(aedDatesState.bleedingControlKitExpDate)
                ? ' warning'
                : ''
            }`}
            error={
              isExpiring(aedDatesState.bleedingControlKitExpDate)
                ? FormField.ErrorType.Alert
                : undefined
            }
            hintText={
              isExpiring(aedDatesState.bleedingControlKitExpDate)
                ? 'Expired (or Expiring Soon)'
                : undefined
            }
          >
            <DatePickerInput
              autoOk
              disabled={!aed.hasBleedingControlKit}
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(
                aedDatesState.bleedingControlKitExpDate,
              )}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'bleedingControlKitExpDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField className={`EditInput-hasEpinephrine`}>
            <Checkbox
              label={AED_ATTRIBUTE_LABELS.get('hasEpinephrine')}
              checked={aedValues.hasEpinephrine}
              onChange={(e) => {
                onFieldChange('hasEpinephrine', e.target.checked)
                setAEDValues({
                  ...aedValues,
                  hasEpinephrine: e.target.checked,
                })
                aedDatesState.epinephrineExpDate = '0000-00-00'
              }}
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('epinephrineExpDate')}
            className={`EditInput-epinephrineExpDate${
              isExpiring(aedDatesState.epinephrineExpDate) ? ' warning' : ''
            }`}
            error={
              isExpiring(aedDatesState.epinephrineExpDate)
                ? FormField.ErrorType.Alert
                : undefined
            }
            hintText={
              isExpiring(aedDatesState.epinephrineExpDate)
                ? 'Expired (or Expiring Soon)'
                : undefined
            }
          >
            <DatePickerInput
              autoOk
              disabled={!aed.hasEpinephrine}
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.epinephrineExpDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'epinephrineExpDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
        </Layout.Column>
        <Layout.Column>
          <FormField className={`EditInput-hasNaloxone`}>
            <Checkbox
              label={AED_ATTRIBUTE_LABELS.get('hasNaloxone')}
              checked={aedValues.hasNaloxone}
              onChange={(e) => {
                onFieldChange('hasNaloxone', e.target.checked)
                setAEDValues({
                  ...aedValues,
                  hasNaloxone: e.target.checked,
                })
                aedDatesState.naloxoneExpDate = '0000-00-00'
              }}
            />
          </FormField>
          <FormField
            label={AED_ATTRIBUTE_LABELS.get('naloxoneExpDate')}
            className={`EditInput-naloxoneExpDate${
              isExpiring(aedDatesState.naloxoneExpDate) ? ' warning' : ''
            }`}
            error={
              isExpiring(aedDatesState.naloxoneExpDate)
                ? FormField.ErrorType.Alert
                : undefined
            }
            hintText={
              isExpiring(aedDatesState.naloxoneExpDate)
                ? 'Expired (or Expiring Soon)'
                : undefined
            }
          >
            <DatePickerInput
              autoOk
              disabled={!aed.hasNaloxone}
              margin="none"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={convertToDateInputValue(aedDatesState.naloxoneExpDate)}
              onChange={(e: MaterialUiPickersDate) =>
                onDateChange(
                  'naloxoneExpDate',
                  e === null ? '0000-00-00' : e.toString(),
                )
              }
            />
          </FormField>
        </Layout.Column>
      </LayoutContainer>
    </StyledCard>
  )
}

export default AEDColocatedItemsInformation
