import React from 'react'
import styled from '@emotion/styled'

import { Layout } from '@workday/canvas-kit-react-layout'

import AED, { AED_ATTRIBUTE_DEFINITIONS, AED_Attribute } from 'models/aed'
import USER from 'models/user'

import { getDerivedFilteredResult, getDateFilteredResult } from 'common/utils'

import Filters from './filters'
import Results from './results/results'

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`
const FilterColumn = styled(Layout.Column)`
  @media (max-width: 600px) {
    display: none;
  }
`
const Spacer = styled.div`
  margin-top: 50px;

  @media (max-width: 600px) {
    margin-top: 25px;
  }
`

export interface BrowseAllProps {
  user: USER
  aeds?: AED[]
  agencyMap?: Map<string, string>
  initialFilterQuery: Partial<AED>
  initialPageNumber: number
  onSelectedAED: (
    aed: AED,
    currentFilterQuery: Partial<AED>,
    pageNumber?: number,
  ) => void
  onPPTAdminAgencyAdd: (
    agencies: string[],
    includeNoAgency: boolean,
    includePlaceSearch: boolean,
  ) => void
}

const BrowseAll = ({
  aeds,
  user,
  agencyMap = new Map(),
  onSelectedAED,
  initialFilterQuery,
  initialPageNumber,
  onPPTAdminAgencyAdd,
}: BrowseAllProps) => {
  const [filterQuery, setFilterQuery] = React.useState<Partial<AED>>(
    initialFilterQuery,
  )

  const filteredAEDs =
    aeds === undefined
      ? []
      : aeds.filter((aed) => {
        return Object.keys(filterQuery).every((key) => {
          const filterAttribute = key as AED_Attribute

          if (
            AED_ATTRIBUTE_DEFINITIONS.find(
              (filter) => filter.key === filterAttribute,
            )?.filterType === 'Derived'
          ) {
            return getDerivedFilteredResult(
              aed,
              filterAttribute,
              filterQuery[filterAttribute],
            )
          } else if (filterQuery[filterAttribute] === '') {
            return aed[filterAttribute]?.toString() === ''
          } else if (
            AED_ATTRIBUTE_DEFINITIONS.find(
              (filter) => filter.key === filterAttribute,
            )?.filterType === 'Date'
          ) {
            return getDateFilteredResult(
              aed,
              filterAttribute,
              filterQuery[filterAttribute] as string,
            )
          } else if (Array.isArray(aed[filterAttribute])) {
            if (typeof filterQuery[filterAttribute] === "boolean") {
              return filterQuery[filterAttribute]
                ? (aed[filterAttribute] as any[]).length > 0
                : (aed[filterAttribute] as any[]).length <= 0
            } else {
              return (aed[filterAttribute] as any[])
                .includes(
                  filterQuery[filterAttribute]
                    ?.toString()
                    .toLowerCase() as string,
                )
            }
          } else {
            return aed[filterAttribute]
              ?.toString()
              .toLowerCase()
              .includes(
                filterQuery[filterAttribute]
                  ?.toString()
                  .toLowerCase() as string,
              )
          }
        })
      })

  return (
    <ContentContainer>
      <Spacer />
      <Layout gutter={0}>
        <FilterColumn width={'325px'}>
          <Filters
            user={user}
            aeds={filteredAEDs}
            agencies={agencyMap || new Map()}
            filterQuery={filterQuery}
            updateQuery={setFilterQuery}
            onPPTAdminAgencyAdd={onPPTAdminAgencyAdd}
          />
        </FilterColumn>
        <Layout.Column columns={12} spacing={0}>
          <Results
            user={user}
            agencies={agencyMap || new Map()}
            onSelectedAED={(aed, pageNumber?: number) =>
              onSelectedAED(aed, filterQuery, pageNumber)
            }
            aeds={filteredAEDs}
            filterQuery={filterQuery}
            initialPageNumber={initialPageNumber}
            updateQuery={setFilterQuery}
          />
        </Layout.Column>
      </Layout>
    </ContentContainer>
  )
}

export default BrowseAll
