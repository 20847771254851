import React from 'react'
import styled from '@emotion/styled'

import {
  CanvasProvider,
  PartialEmotionCanvasTheme,
} from '@workday/canvas-kit-react-common'

import colors from '@workday/canvas-colors-web'

import AED, { AED_Attribute, NEW_AED_TEMPLATE } from 'models/aed'
import USER from 'models/user'
import AGENCY from 'models/agency'

import {
  checkValidToken,
  getAEDasPPTAdmin,
  getAgencyByIds,
  getAgencyIdList,
  loadAllAEDs,
} from 'api/requests'
import { LOGIN_RESPONSE } from 'api/models/loginResponse'
import {
  getLocallyStoredUser,
  removeLoginInfo,
  rememberLoginInfo,
} from 'api/localStorage'

import Header from './header/header'
import BrowseAll from './browse/browseAll'
import Loading from './browse/loading'
import EditAED from './edit/editAED'
import AddAED from './add/addAED'
import Login from './login/login'

const PulsePointTheme: PartialEmotionCanvasTheme = {
  canvas: {
    palette: {
      primary: {
        lightest: colors.sourLemon200,
        light: colors.sourLemon300,
        main: colors.sourLemon400,
        dark: colors.sourLemon500,
        darkest: colors.sourLemon600,
        contrast: colors.frenchVanilla100,
      },
    },
  },
}

const AppStripe = styled.div`
  position: absolute;
  z-index: 1;
  background: ${colors.sourLemon400};
  height: 150px;
  width: 100%;
`

const Background = styled.div<{ hideAEDsInBackground?: boolean }>`
  background: ${(props) =>
    props.hideAEDsInBackground
      ? 'url(/background-noaeds.jpg)'
      : 'url(/background.jpg)'};
  background-size: contain;
  min-height: 100%;
`

const removePPTAdminFromList = (agencies: string): string => {
  return agencies
    .replace('999999,', '')
    .replace(',999999', '')
    .replace('999999', '')
}
const isPPTAdmin = (agencies: string): boolean => {
  return agencies.indexOf('999999') > -1
}
const isPPTAdminWithNoAgenciesSelected = (user: USER): boolean => {
  return (
    user.agencyIDs === '' &&
    user.isPulsePointAdmin &&
    user.admin.addedAgencies.length === 0 &&
    !user.admin.includeNoAgency
  )
}
const getDefaultUser = (): USER => {
  const locallyStoredUser = getLocallyStoredUser()
  return {
    userID: locallyStoredUser.userID,
    token: locallyStoredUser.token,
    agencyIDs: removePPTAdminFromList(locallyStoredUser.agencies),
    isPulsePointAdmin: isPPTAdmin(locallyStoredUser.agencies),
    admin: {
      includeNoAgency: false,
      includePlaceSearch: false,
      addedAgencies: [],
    },
    username: locallyStoredUser.username,
  }
}
const shouldGrabAgencyNamesForUser = (user: USER): boolean => {
  return (
    user.agencyIDs !== '' ||
    (user.isPulsePointAdmin && user.admin.addedAgencies.length > 0)
  )
}
const isNewAED = (aed: AED): boolean => {
  return aed.ID === NEW_AED_TEMPLATE.ID
}

const App = () => {
  const [auth, setAuth] = React.useState<{
    user: USER
    hasBeenAuthenticated?: boolean
  }>({ user: getDefaultUser() })
  const [aeds, setAEDs] = React.useState<AED[]>()
  const [agenciesLoaded, setLoadedAgency] = React.useState<number>(0)
  const [selectedAED, setSelectedAED] = React.useState<AED>()
  const [savedFilterQuery, setSavedFilterQuery] = React.useState<{
    filterQuery: Partial<AED>
    pageNumber: number
  }>({ filterQuery: {}, pageNumber: 0 })
  const [agencyMap, setAgencyMap] = React.useState<Map<string, string>>()

  const getAEDsForUser = async (user: USER): Promise<void> => {
    if (isPPTAdminWithNoAgenciesSelected(user)) {
      setAEDs([])
    } else {
      const allAEDs: Map<String, AED> = new Map()
      let loadedCount: number = 0
      loadAllAEDs(user, ({ agencyAEDs, loadedAllAgencies }) => {
        agencyAEDs.forEach((aed) => {
          const dupAED: AED | undefined = allAEDs.get(aed.ID)

          if (dupAED) {
            dupAED.agencyID = `${dupAED.agencyID},${aed.agencyID}`
          } else {
            allAEDs.set(aed.ID, aed)
          }
        });

        if (loadedAllAgencies) {
          const aedList: AED[] = []
          allAEDs.forEach((aed) => aedList.push(aed))

          setAEDs(aedList)
        } else {
          loadedCount++
          setLoadedAgency(loadedCount)
        }
      })
    }
  }

  const getAgenciesByUser = (user: USER) => {
    getAgencyByIds(user, (agencies: AGENCY[]) => {
      const tempAgencyMap: Map<string, string> = new Map()
      agencies.forEach((agency) =>
        tempAgencyMap.set(
          agency.agencyid,
          agency.short_agencyname !== undefined
            ? agency.short_agencyname
            : agency.agencyname,
        ),
      )
      if (user.isPulsePointAdmin && user.admin.includeNoAgency) {
        tempAgencyMap.set('', 'No Agency')
      }
      setAgencyMap(tempAgencyMap)
    })
  }

  if (auth.hasBeenAuthenticated === undefined) {
    // User has no token stored locally, so set to false and show login screen
    if (auth.user.token === '') {
      setAuth({ ...auth, hasBeenAuthenticated: false })
    } else {
      // User has token stored locally, check to see if still valid
      checkValidToken(auth.user, (success) => {
        if (!success) {
          removeLoginInfo()
        }
        setAuth({ ...auth, hasBeenAuthenticated: success })
      })
    }
  }

  React.useEffect(() => {
    if (auth.hasBeenAuthenticated) {
      getAEDsForUser(auth.user)

      if (shouldGrabAgencyNamesForUser(auth.user)) {
        getAgenciesByUser(auth.user)
        // TODO: should I return <></> to short circuit until agencies is loaded?
      }
    }
  }, [auth])

  React.useEffect(() => {
    if (window.location.hash.indexOf('add') > -1) {
      setSelectedAED(NEW_AED_TEMPLATE)
    } else if (window.location.hash.indexOf('edit') > -1) {
      const aedID = window.location.hash.substr(1).replace('edit/', '')
      const editAED = aeds?.find((aed) => aed.ID === aedID)
      if (editAED !== undefined) {
        setSelectedAED(editAED)
      } else if (auth.user.isPulsePointAdmin) {
        getAEDasPPTAdmin(auth.user, aedID, (aed: AED) => {
          setSelectedAED(aed)
        })
      }
    }
  }, [aeds])

  window.onhashchange = () => {
    if (window.location.hash.indexOf('add') > -1) {
      setSelectedAED(NEW_AED_TEMPLATE)
    } else if (window.location.hash.indexOf('edit') < 0) {
      setSelectedAED(undefined)
    } else if (window.location.hash.indexOf('edit') > -1) {
      const aedID = window.location.hash.substr(1).replace('edit/', '')
      const editAED = aeds?.find((aed) => aed.ID === aedID)
      if (editAED !== undefined) {
        setSelectedAED(editAED)
      } else if (auth.user.isPulsePointAdmin) {
        getAEDasPPTAdmin(auth.user, aedID, (aed: AED) => {
          setSelectedAED(aed)
        })
      }
    }
  }

  return auth.hasBeenAuthenticated === undefined ? (
    // User has auth stored locally, but it has not been authenticated yet.
    <Background>
      <Header />
      <AppStripe />
    </Background>
  ) : auth.hasBeenAuthenticated ? (
    // User has been authenticated, proceed to app.
    <CanvasProvider theme={PulsePointTheme}>
      <Background hideAEDsInBackground={selectedAED !== undefined}>
        <Header user={auth.user} />
        <AppStripe />
        {selectedAED !== undefined ? (
          isNewAED(selectedAED) ? (
            <AddAED
              aeds={aeds}
              user={auth.user}
              onCancel={() => (window.location.hash = '')}
              onNext={(aed) => setSelectedAED({ ...aed, ID: '' })}
            ></AddAED>
          ) : (
            <EditAED
              aedToEdit={selectedAED}
              user={auth.user}
              agencyMap={agencyMap}
              onBack={() => setSelectedAED(NEW_AED_TEMPLATE)}
              onClose={(cancelledAED: AED) => {
                const aed = aeds?.find((aed) => aed.ID === cancelledAED.ID)
                if (aed !== undefined) {
                  aed.lastReviewed = cancelledAED.lastReviewed
                }
                window.location.hash = ''
              }}
              onDelete={(aedID: string) => {
                // remove deleted aed from set
                setAEDs(aeds?.filter((aed) => aed.ID !== aedID))
                // Go back to BrowseAll
                window.location.hash = ''
              }}
              onSave={(updatedAED: AED) => {
                // update local AED
                const aed = aeds?.find((aed) => aed.ID === updatedAED.ID)
                if (aed !== undefined) {
                  Object.keys(aed).forEach((key) => {
                    if (key === 'created' || key === 'userEmail') {
                      // don't update readonly values
                    } else {
                      ; (aed[key as AED_Attribute] as string) = updatedAED[
                        key as AED_Attribute
                      ] as string
                    }
                  })
                } else {
                  // add new aed to set
                  aeds?.push(updatedAED)
                }
                // Go back to BrowseAll
                window.location.hash = ''
              }}
            />
          )
        ) : aeds !== undefined ? (
          <BrowseAll
            aeds={aeds}
            user={auth.user}
            agencyMap={agencyMap}
            initialFilterQuery={savedFilterQuery.filterQuery}
            initialPageNumber={savedFilterQuery.pageNumber}
            onSelectedAED={(aed, filterQuery, pageNumber) => {
              window.location.hash = `#edit/${aed.ID}`
              setSavedFilterQuery({
                filterQuery: filterQuery,
                pageNumber: pageNumber || 0,
              })
            }}
            onPPTAdminAgencyAdd={(
              agencies: string[],
              includeNoAgency: boolean,
              includePlaceSearch: boolean,
            ) => {
              const user: USER = {
                ...auth.user,
                admin: {
                  includeNoAgency: includeNoAgency,
                  includePlaceSearch: includePlaceSearch,
                  addedAgencies: agencies,
                },
              }

              setAuth({ ...auth, user: user })
            }}
          />
        ) : (
          // User has been authenticated, but AED lookup has not returned from server.
          <Loading
            totalAgencies={getAgencyIdList(auth.user).split(',').length}
            loadedAgencies={agenciesLoaded}
          />
        )}
      </Background>
    </CanvasProvider>
  ) : (
    // User has been authenticated as false.
    <CanvasProvider theme={PulsePointTheme}>
      <Login
        onSuccess={(data: LOGIN_RESPONSE) => {
          rememberLoginInfo({
            token: data.AuthToken,
            agencies: data.AgencyAdmin,
            userID: data.UserID,
            username: data.UserName,
          })
          setAuth({
            user: {
              userID: data.UserID,
              token: data.AuthToken,
              agencyIDs: removePPTAdminFromList(data.AgencyAdmin),
              isPulsePointAdmin: isPPTAdmin(data.AgencyAdmin),
              admin: {
                includeNoAgency: false,
                includePlaceSearch: false,
                addedAgencies: [],
              },
              username: data.UserName,
            },
            hasBeenAuthenticated: true,
          })
        }}
      />
    </CanvasProvider>
  )
}

export default App
