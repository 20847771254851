import React from 'react'

import { Radio } from '@workday/canvas-kit-react-radio'

import { FiltersProps } from '../filters'

import { AED_ACCESS } from '../../../models/aedAccess'
import {
  StyledCard,
  CardHeading,
  ClearButton,
  StyledRadioGroup,
} from '../../../common/styles'

const AEDAccessFilter = ({ aeds, filterQuery, updateQuery }: FiltersProps) => {
  const privateCount =
    filterQuery.accessibility === undefined ||
    filterQuery.accessibility === 'Private'
      ? `(${aeds.filter((aed) => aed.accessibility === 'Private').length})`
      : ''
  const publicCount =
    filterQuery.accessibility === undefined ||
    filterQuery.accessibility === 'Public'
      ? `(${aeds.filter((aed) => aed.accessibility === 'Public').length})`
      : ''
  return (
    <StyledCard>
      <CardHeading>Accessibility</CardHeading>
      {filterQuery.accessibility && (
        <ClearButton
          onClick={() => {
            delete filterQuery.accessibility
            updateQuery({
              ...filterQuery,
            })
          }}
        >
          Clear
        </ClearButton>
      )}
      <StyledRadioGroup
        name="aedAccess"
        value={filterQuery.accessibility || ''}
        onChange={(value) =>
          updateQuery({
            ...filterQuery,
            accessibility: value as AED_ACCESS,
          })
        }
      >
        <Radio
          id={`AEDAccess-Private`}
          value={'Private'}
          label={`Private ${privateCount}`}
        />
        <Radio
          id={`AEDAccess-Public`}
          value={'Public'}
          label={`Public ${publicCount}`}
        />
      </StyledRadioGroup>
    </StyledCard>
  )
}

export default AEDAccessFilter
