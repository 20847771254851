import React from 'react'
import sortedUniqBy from 'lodash/sortedUniqBy'
import { sortBy } from 'lodash'

import styled from '@emotion/styled'

import Button, {
  ButtonSize,
  ButtonVariant,
} from '@workday/canvas-kit-react-button'
import colors from '@workday/canvas-colors-web'
import { Radio, RadioGroup } from '@workday/canvas-kit-react-radio'
import Select, { SelectOption } from '@workday/canvas-kit-react-select'
import TextInput from '@workday/canvas-kit-react-text-input'

import AED, { AED_ATTRIBUTE_DEFINITIONS, AED_Attribute } from 'models/aed'
import { StyledCard, CardHeading } from 'common/styles'

import { FiltersProps } from '../filters'

const AED_COLOCATED_OPTIONS: string[] = [
  'Bleeding Control Kit',
  'Epinephrine',
  'Naloxone',
]
export type AED_COLOCATED = typeof AED_COLOCATED_OPTIONS[number]

export const CUSTOM_FILTERS_KEYS: AED_Attribute[] = [
  'name',
  'description',
  'address',
  'city',
  'county',
  'postal',
  'isHidden',
  'isMobile',
  'manufacturer',
  'colocated',
  'userEmail',
  'serialNumber',
  'siteCoordinatorEmail',
  'assetID',
  'source',
  'activeSubscribers',
]

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
  min-width: 205px;
`
const StyledRadioGroup = styled(RadioGroup)`
  margin-bottom: 10px;
  min-width: 205px;
`
const StyledTextInput = styled(TextInput)`
  margin-bottom: 10px;
  min-width: 205px;
`

const StyledButton = styled(Button)`
  color: ${colors.blackPepper500};

  &:disabled {
    color: ${colors.blackPepper200};
  }

  &:focus {
    color: ${colors.blackPepper500} !important;
  }
`

interface CustomFilterValueProps {
  aeds: AED[]
  filterAttribute: AED_Attribute
  onSelect: (value: string | boolean, triggerSubmit?: boolean) => void
  label?: string
  notLabel?: string
}

const getFilterType = (filterAttribute: AED_Attribute) => {
  return AED_ATTRIBUTE_DEFINITIONS.find(
    (filter) => filter.key === filterAttribute,
  )
}

const getCustomFilterValue = (
  aeds: AED[],
  filterAttribute: AED_Attribute,
  onSelect: (value: string | boolean, triggerSubmit?: boolean) => void,
) => {
  const filter = getFilterType(filterAttribute)

  if (!filter) return <></>

  switch (filter.filterType) {
    case 'Select':
      return (
        <CustomFilterSelectValue
          aeds={aeds}
          filterAttribute={filterAttribute}
          onSelect={onSelect}
        />
      )
    case 'Boolean':
      return (
        <CustomFilterBooleanValue
          aeds={aeds}
          filterAttribute={filterAttribute}
          label={filter.valueLabels.label}
          notLabel={filter.valueLabels.notLabel}
          onSelect={onSelect}
        />
      )
    case 'Input':
      return (
        <CustomFilterInputValue
          aeds={aeds}
          filterAttribute={filterAttribute}
          onSelect={onSelect}
        />
      )
    case 'Derived':
      switch (filterAttribute) {
        case 'colocated':
          return (
            <StyledSelect
              name="colocatedValue"
              onChange={(e) => onSelect(e.currentTarget.value)}
            >
              <SelectOption value="" label="Select a Value" />
              <SelectOption value={'Epinephrine'} label={'Epinephrine'} />
              <SelectOption value={'Naloxone'} label={'Naloxone'} />
              <SelectOption
                value={'Bleeding Control Kit'}
                label={'Bleeding Control Kit'}
              />
            </StyledSelect>
          )
        default:
          return <></>
      }
  }
}

const CustomFilterInputValue = ({ onSelect }: CustomFilterValueProps) => {
  return (
    <StyledTextInput
      placeholder="Search..."
      onChange={(e) => onSelect(e.currentTarget.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onSelect(e.currentTarget.value, true)
        }
      }}
    />
  )
}

const CustomFilterBooleanValue = ({
  onSelect,
  label,
  notLabel,
}: CustomFilterValueProps) => {
  const [value, setValue] = React.useState('')

  return (
    <StyledRadioGroup
      name="customValueBoolean"
      value={value}
      onChange={(value) => {
        setValue(value as string)
        onSelect(value === 'true')
      }}
    >
      <Radio id="true" value="true" label={label} />
      <Radio id="false" value="false" label={notLabel} />
    </StyledRadioGroup>
  )
}

const CustomFilterSelectValue = ({
  aeds,
  filterAttribute,
  onSelect,
}: CustomFilterValueProps) => {
  const sortedAEDs = sortBy(aeds, (aed) => aed[filterAttribute])
  return (
    <StyledSelect
      name="customValue"
      onChange={(e) => onSelect(e.currentTarget.value)}
    >
      <SelectOption value="" label="Select a Value" />
      <>
        {sortedUniqBy(sortedAEDs, filterAttribute).map(
          (aed: AED, index: number) => {
            if (aed[filterAttribute] === '') {
              return (
                <SelectOption
                  key={`custom-value-select-${index}`}
                  value=""
                  label="(Empty)"
                />
              )
            }

            return (
              <SelectOption
                key={`custom-value-select-${index}`}
                value={aed[filterAttribute] as string}
                label={aed[filterAttribute] as string}
              />
            )
          },
        )}
      </>
    </StyledSelect>
  )
}

const CustomFilter = ({ aeds, filterQuery, updateQuery }: FiltersProps) => {
  const [filterAttribute, setFilterAttribute] = React.useState<AED_Attribute>()
  const [filterValue, setFilterValue] = React.useState<string | boolean>()

  return (
    <StyledCard>
      <CardHeading>Filters</CardHeading>
      <StyledSelect
        name="custom"
        value={filterAttribute === undefined ? '' : filterAttribute}
        onChange={(e) =>
          setFilterAttribute(e.currentTarget.value as AED_Attribute)
        }
      >
        <SelectOption key={`custom-col-`} value="" label="Filter by..." />
        <>
          {CUSTOM_FILTERS_KEYS.map((filterKey: string, index: number) => {
            const filter = AED_ATTRIBUTE_DEFINITIONS.find(
              (filter) => filter.key === filterKey,
            )

            return (
              <SelectOption
                key={`custom-col-${index}`}
                value={filter?.key}
                label={filter?.label}
              />
            )
          })}
        </>
      </StyledSelect>
      {filterAttribute &&
        getCustomFilterValue(
          aeds,
          filterAttribute,
          (filterValue, triggerSubmit) => {
            if (triggerSubmit) {
              updateQuery({
                ...filterQuery,
                [filterAttribute as AED_Attribute]: filterValue,
              })
              setFilterAttribute(undefined)
              setFilterValue(undefined)
            } else {
              setFilterValue(filterValue)
            }
          },
        )}
      <StyledButton
        disabled={
          filterAttribute === undefined ||
          (getFilterType(filterAttribute)?.filterType !== 'Input' &&
            filterValue === undefined)
        }
        variant={ButtonVariant.Primary}
        size={ButtonSize.Small}
        onClick={() => {
          if (filterAttribute === 'colocated') {
            switch (filterValue as AED_COLOCATED) {
              case 'Bleeding Control Kit':
                updateQuery({
                  ...filterQuery,
                  hasBleedingControlKit: true,
                })
                break
              case 'Epinephrine':
                updateQuery({
                  ...filterQuery,
                  hasEpinephrine: true,
                })
                break
              case 'Naloxone':
                updateQuery({
                  ...filterQuery,
                  hasNaloxone: true,
                })
                break
            }
          } else {
            updateQuery({
              ...filterQuery,
              [filterAttribute as AED_Attribute]: filterValue,
            })
          }

          setFilterAttribute(undefined)
          setFilterValue(undefined)
        }}
      >
        Add Filter
      </StyledButton>
    </StyledCard>
  )
}

export default CustomFilter
